<div class="municipality-details-container" [ngClass]="options.case">
    <mat-card class="menu-container" [ngClass]="options.case">
        <div class="menu-title">Secciones</div>
        <mat-divider></mat-divider>
        <ul>
            <li [ngClass]="{'active-option': activeOption == 'municipality'}" (click)="setActiveOption('municipality')">
                <a>
                    <div class="menu-option-container">
                        <div class="icon-container">
                            <mat-icon>info</mat-icon>
                        </div>
                        <div class="menu-option">
                            Municipio
                        </div>
                    </div>
                </a>
            </li>
            <li *ngIf="(adminData || municipalityData != undefined || activeOption == 'admin')" [ngClass]="{'active-option': activeOption == 'admin'}" (click)="setActiveOption('admin')">
                <a>
                    <div class="menu-option-container">
                        <div class="icon-container">
                            <mat-icon>settings</mat-icon>
                        </div>
                        <div class="menu-option">
                            Administrador
                        </div>
                    </div>
                </a>
            </li>
        </ul>
    </mat-card>    
    <app-municipality-profile *ngIf="(activeOption == 'municipality' && checkedFlag) || (userData.new && activeOption == 'municipality')" [municipalityData]="municipalityData" (setUser)="updateData($event)"></app-municipality-profile>
    <app-admin-profile *ngIf="activeOption == 'admin'" [userData]="adminData" [municipality_id]="municipality_id"></app-admin-profile>
</div>
