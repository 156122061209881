<div class="container">
    <div [style.width]="options.bckW" class="container-child" [ngStyle]="{ 'align-items': options.bckWHide ? 'start' : 'center' }">
        <div class="container-child-left" [style.height]="options.containerLeftH">
            <h4 class="title">Gobierno Digital</h4>
            <p *ngIf="!options.bckWHide">La información, documentos y textos son derechos reservados del Colegio de Notarios del Estado de México.</p>
            <div class="ribbon" [style.right]="options.ribbonRight" [style.bottom]="options.ribbonBottom">
                <img src="/assets/backgrounds/logos/colegio-full-logo.png" alt="" srcset="">
            </div>
        </div>
    </div>
    <div [style.width]="options.frmW" class="container-child">
        <router-outlet></router-outlet>
    </div>
</div>