import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApplicationElement } from 'src/app/models/application/element';
import { MenuService } from 'src/app/services/menu.service';
import { ButtonActions, ChangeStatus } from 'src/app/shared/statics/constants';
import { QuestionData } from '../../../dialogs/question/question.component';
import { ProcedureService } from 'src/app/services/api/procedure.service';
import { LoadingComponent } from '../../../dialogs/loading/loading.component';
import { DialogService } from 'src/app/services/dialog.service';
import { Documentos } from '../../../dialogs/upload-documents/upload-documents.component';
import * as moment from 'moment';
moment.locale('es-mx');

@Component({
  selector: 'app-buttons',
  templateUrl: './buttons.component.html',
  styleUrls: ['./buttons.component.sass']
})
export class ButtonsComponent {

  @Input() applicationElement?: ApplicationElement;

  public review: ButtonActions = ButtonActions.REVISAR;
  public adeudos: ButtonActions = ButtonActions.ADEUDOS;
  public orden: ButtonActions = ButtonActions.ORDEN;
  public update_orden: ButtonActions = ButtonActions.UPDATE_ORDEN;
  public to_sign_tramite: ButtonActions = ButtonActions.TO_SIGN_TRAMITE;
  public precertificate: ButtonActions = ButtonActions.PRECERTIFICATE;
  public sign_tramite: ButtonActions = ButtonActions.SIGN_TRAMITE;
  public cargar_datos: ButtonActions = ButtonActions.CARGAR_DATOS;

  private isPrecertificate: boolean = true;

  private adeudosQuestionData: QuestionData = {
    title: "Revision de Adeudos",
    body: "<p>¿El predio correspondiente a la solicitud cuenta con algún adeudo?</p><p>En caso de contar con algún adeudo el trámite será cancelado y deberás adjuntar la información correspondiente.</p>",
    actions: [
      {
        title: "Aprobar trámite",
        style: this.dialogService.primaryButton,
        onTap: (ref) => {
          this.dialogService.openConfirmationDialog((dialogRef) => {
            dialogRef.close();
            this.changeStatus(ChangeStatus.APPROVED);
          });
          ref.close();
        }
      },
      {
        title: "Cancelar trámite",
        style: this.dialogService.secondaryButton,
        onTap: (ref) => {
          this.dialogService.openConfirmationDialog((dialogRef) => {
            dialogRef.close();
            this.dialogService.openDocumentDialog([Documentos.MOTIVO_CANCELACION]).afterClosed().subscribe((res: boolean) => {
              if (res) this.changeStatus(ChangeStatus.CANCELED);
            });
          });
          ref.close();
        }
      },
    ]
  };

  private ordenQuestionData: QuestionData = {
    title: "Cargar orden de pago",
    body: "<p>A continuación deberás cargar la orden de pago en formato .pdf</p>",
    actions: [
      {
        title: "Continuar",
        style: this.dialogService.primaryButton,
        onTap: (ref) => {
          this.dialogService.openConfirmationDialog((dialogRef) => {
            dialogRef.close();
            this.dialogService.openDocumentDialog([Documentos.ORDEN_PAGO]).afterClosed().subscribe((res: boolean) => {
              if (this.menuService.selectedApplicationElement) {
                if (res && this.menuService.selectedApplicationElement.Cat_application_status.id !== 7) this.changeStatus(ChangeStatus.APPROVED);
                else location.reload();
              }
            });
          });
          ref.close();
        }
      },
      {
        title: "Cancelar",
        style: this.dialogService.secondaryButton,
        onTap: this.dialogService.closeAction
      },
    ]
  };

  private toSignQuestionData: QuestionData = {
    title: "Conciliar pago",
    body: "<p>A continuación el trámite se enviará  a firma</p>",
    actions: [
      {
        title: "Continuar",
        style: this.dialogService.primaryButton,
        onTap: (ref) => {
          this.dialogService.openConfirmationDialog((dialogRef) => {
            dialogRef.close();
            this.changeStatus(ChangeStatus.APPROVED);
          });
          ref.close();
        }
      },
      {
        title: "Cancelar",
        style: this.dialogService.secondaryButton,
        onTap: this.dialogService.closeAction
      },
    ]
  };

  private signQuestionData: QuestionData = {
    title: "Firmar trámite",
    body: "<p>A continuación se firmará el trámite</p>",
    actions: [
      {
        title: "Continuar",
        style: this.dialogService.primaryButton,
        onTap: (ref) => {
          this.dialogService.openConfirmationDialog((dialogRef) => {
            dialogRef.close();
            this.generateCertificate((res: any) => {
              this.openInNewTab(res.data);
              this.changeStatus(ChangeStatus.APPROVED);
            });
          });
          ref.close();
        }
      },
      {
        title: "Cancelar",
        style: this.dialogService.secondaryButton,
        onTap: this.dialogService.closeAction
      },
    ]
  };

  private updateDataQuestionData: QuestionData = {
    title: "Actualizar información",
    body: "<p>A continuación tendrás que cargar la información que se plasmará en el trámite firmado</p>",
    actions: [
      {
        title: "Continuar",
        style: this.dialogService.primaryButton,
        onTap: (ref) => {
          this.dialogService.openConfirmationDialog((dialogRef) => {
            dialogRef.close();
            this.dialogService.openDataDialog().afterClosed().subscribe((res: boolean) => {
              if (res) {
                let loading = this.dialog.open(LoadingComponent, { disableClose: true });
                this.generateCertificate((res: any) => {
                  this.openInNewTab(res.data);
                  loading.close();
                  location.reload();
                });
              }
            });
          });
          ref.close();
        }
      },
      {
        title: "Cancelar",
        style: this.dialogService.secondaryButton,
        onTap: this.dialogService.closeAction
      },
    ]
  };

  constructor(
    public menuService: MenuService,
    private procedureService: ProcedureService,
    private dialog: MatDialog,
    private dialogService: DialogService,
    private _router: Router
  ) { }

  action(type: ButtonActions) {
    if (this.applicationElement) this.menuService.selectedApplicationElement = this.applicationElement;
    switch (type) {
      case ButtonActions.REVISAR:
        this._router.navigateByUrl('/private/servidor-publico/archivos-folio');
        break;
      case ButtonActions.ADEUDOS:
        this.dialogService.openDialog(this.adeudosQuestionData);
        break;
      case ButtonActions.ORDEN:
      case ButtonActions.UPDATE_ORDEN:
        this.dialogService.openDialog(this.ordenQuestionData);
        break;
      case ButtonActions.TO_SIGN_TRAMITE:
        this.dialogService.openDialog(this.toSignQuestionData);
        break;
      case ButtonActions.PRECERTIFICATE:
        this.dialogService.openConfirmationDialog((dialogRef) => {
          dialogRef.close();
          this.isPrecertificate = true;
          this.generateCertificate((res: any) => {
            this.openInNewTab(res.data);
          });
        });
        break;
      case ButtonActions.SIGN_TRAMITE:
        this.isPrecertificate = false;
        this.dialogService.openDialog(this.signQuestionData);
        break;
      case ButtonActions.CARGAR_DATOS:
        this.dialogService.openDialog(this.updateDataQuestionData);
        break;
    }
  }

  changeStatus(status: ChangeStatus) {
    let loading = this.dialog.open(LoadingComponent, { disableClose: true });
    this.procedureService.change_status(status).subscribe({
      next: () => {
        loading.close();
        location.reload();
      },
      error: (err) => {
        loading.close();
        this.dialogService.error();
      }
    });
  }

  generateCertificate(callback: any) {
    let loading = this.dialog.open(LoadingComponent, { disableClose: true });
    this.procedureService.generate_certificate(this.isPrecertificate).subscribe({
      next: (res: any) => {
        loading.close();
        if (callback) callback(res);
      },
      error: (err) => {
        loading.close();
        this.dialogService.error();
      },
    });
  }

  checkExpiration(date: Date) {
    const now = moment(new Date);
    const exp = moment(date);
    const minutes = exp.diff(now, 'm');
    return minutes <= 0;
  }

  openInNewTab(url: string) {
    let a = document.createElement('a');
    a.target = "_blank";
    a.href = url;
    a.click();
  }

}