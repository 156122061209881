import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileViewerComponent } from './components/file-viewer/file-viewer.component';
import { BaseAuthComponent } from './layouts/auth/base-auth/base-auth.component';
import { LoginComponent } from './layouts/auth/login/login.component';
import { RegisterComponent } from './layouts/auth/register/register.component';
import { BaseComponent } from './layouts/base/base.component';
import { FoliosTableComponent } from './components/tables/folios-table/folios-table.component';
import { ForgotPassComponent } from './components/dialogs/forgot-pass/forgot-pass.component';
import { LoadingComponent } from './components/dialogs/loading/loading.component';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { AsideComponent } from './layouts/base/components/aside/aside.component';
import { FileComponent } from './components/file-viewer/components/file/file.component';
import { FileListComponent } from './components/file-viewer/components/file-list/file-list.component';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { FormProfileComponent } from './components/user-profile/form-profile/form-profile.component';
import { BannerProfileComponent } from './components/user-profile/banner-profile/banner-profile.component';
import { HistoricalRecordsTableComponent } from './components/tables/historical-records-table/historical-records-table.component';
import { MunicipalityTableComponent } from './components/tables/municipality-table/municipality-table.component';
import { MunicipalityDetailsComponent } from './components/municipality-details/municipality-details.component';
import { PublicServantsComponent } from './components/tables/public-servants/public-servants.component';
import { PublicServantsDetailsComponent } from './components/public-servants-details/public-servants-details.component';
import { PublicServantsPermissionsComponent } from './components/public-servants-details/components/public-servants-permissions/public-servants-permissions.component';
import { PublicServantsMenuComponent } from './components/public-servants-details/components/public-servants-menu/public-servants-menu.component';
import { DepartmentsTableComponent } from './components/tables/departments-table/departments-table.component';
import { DepartmentsDetailsComponent } from './components/departments-details/departments-details.component';
import { DepartmentsMenuComponent } from './components/departments-details/components/departments-menu/departments-menu.component';
import { DepartmentsRequestComponent } from './components/departments-details/components/departments-request/departments-request.component';
import { DepartmentsComponent } from './components/dialogs/departments/departments.component';
import { MunicipalityUserComponent } from './components/forms/municipality-user/municipality-user.component';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatPaginatorIntlSpanish } from './utils/MatPaginatorIntlSpanish';
import { MatChipsModule } from '@angular/material/chips';
import { MatMenuModule } from '@angular/material/menu';
import { ButtonsComponent } from './components/tables/folios-table/buttons/buttons.component';
import { QuestionComponent } from './components/dialogs/question/question.component';
import { UploadDocumentsComponent } from './components/dialogs/upload-documents/upload-documents.component';
import { LetterheadComponent } from './components/dialogs/letterhead/letterhead.component';
import { UpdateDataComponent } from './components/dialogs/update-data/update-data.component';

import { MunicipalityProfileComponent } from './components/municipality-details/components/municipality-profile/municipality-profile.component';
import { AdminProfileComponent } from './components/municipality-details/components/admin-profile-form/admin-profile.component';
import { TokenExpiredDialogComponent } from './components/dialogs/token-expired-dialog/token-expired-dialog.component';
import { PlatformAdministratorComponent } from './components/dashboards/platform-administrator/platform-administrator.component';
import { MunicipalityAdministratorComponent } from './components/dashboards/municipality-administrator/municipality-administrator.component';
import { PublicServerComponent } from './components/dashboards/public-server/public-server.component';
import { BarComponent } from './components/charts/bar/bar.component';
import { PieComponent } from './components/charts/pie/pie.component';
import { InfoCardComponent } from './components/info-card/info-card.component';
import { MAT_DATE_LOCALE } from '@angular/material/core';



@NgModule({
  declarations: [
    FileViewerComponent,
    BaseAuthComponent,
    LoginComponent,
    RegisterComponent,
    BaseComponent,
    FoliosTableComponent,
    ForgotPassComponent,
    LoadingComponent,
    AsideComponent,
    FileComponent,
    FileListComponent,
    UserProfileComponent,
    FormProfileComponent,
    BannerProfileComponent,
    HistoricalRecordsTableComponent,
    MunicipalityTableComponent,
    MunicipalityDetailsComponent,    
    MunicipalityProfileComponent,
    PublicServantsComponent,
    PublicServantsDetailsComponent,
    PublicServantsPermissionsComponent,
    PublicServantsMenuComponent,
    DepartmentsTableComponent,
    DepartmentsDetailsComponent,
    DepartmentsMenuComponent,
    DepartmentsRequestComponent,
    DepartmentsComponent,
    MunicipalityUserComponent,    
    ButtonsComponent,
    QuestionComponent,
    UploadDocumentsComponent,
    LetterheadComponent,
    UpdateDataComponent,
    AdminProfileComponent,
    TokenExpiredDialogComponent,
    PlatformAdministratorComponent,
    MunicipalityAdministratorComponent,
    PublicServerComponent,
    BarComponent,
    PieComponent,
    InfoCardComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    MatChipsModule,
    MatMenuModule
  ],
  providers: [
    { provide: MatPaginatorIntl, useClass: MatPaginatorIntlSpanish },
    { provide: MAT_DATE_LOCALE, useValue: 'es-MX'},

  ]
})
export class CoreModule { }
