<div class="departments-table-container" [ngClass]="options.case">
    <mat-card>
      <div class="departments-table-search-container">      
        <label class="search-title">Departamentos</label>
        <div class="action-container">
            <mat-form-field appearance="outline">
                <input matInput placeholder="Búsqueda" [formControl]="searchControl">
                <mat-icon matSuffix>search</mat-icon>
            </mat-form-field> 
            <button matTooltip="Registrar un nuevo departamento" (click)="openDialog('Nuevo Departamento','Crear departamento',true, null)"> Nuevo Departamento</button>
        </div>
      </div>
      <div class="body-table-container">
        <table mat-table [dataSource]="dataSource">        
            <ng-container matColumnDef="nombre">
              <th mat-header-cell *matHeaderCellDef>Nombre</th>
              <td mat-cell *matCellDef="let element">
                <div class="departments-container">                   
                    <span> {{ element.name | titlecase }} </span>
                </div>
              </td>
            </ng-container>           
            <ng-container matColumnDef="creacion">
              <th mat-header-cell *matHeaderCellDef>Creación</th>
              <td mat-cell *matCellDef="let element">{{element.createdAt | date: 'dd/MM/yyyy'}}</td>
            </ng-container>
            <ng-container matColumnDef="acciones">
              <th mat-header-cell *matHeaderCellDef>Acciones</th>
              <td mat-cell *matCellDef="let element" class="accions-container">                
                <a class="departments-button" color="neutral-50" matTooltip="Editar departamento" (click)="openDialog('Actualizar Departamento','Actualizar',false, element)">
                    <mat-icon >edit</mat-icon>
                </a>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>            
        </table>
      </div>      
      <div class="mat-paginator-container">
        <mat-paginator (page)="change_page($event)" [pageSize]="cant"  
          [length]="count" [pageSizeOptions]="[5, 10, 15, 20, 50]"  
          [showFirstLastButtons]="true">
        </mat-paginator>
      </div>  
    </mat-card>
  </div>