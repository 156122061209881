import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { QuestionData } from '../question/question.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MenuService } from 'src/app/services/menu.service';
import { ProcedureService } from 'src/app/services/api/procedure.service';
import { FormBuilder, Validators } from '@angular/forms';
import { LoadingComponent } from '../loading/loading.component';
import { ApplicationDetails } from 'src/app/models/application/details';

@Component({
  selector: 'app-update-data',
  templateUrl: './update-data.component.html',
  styleUrls: ['./update-data.component.sass']
})
export class UpdateDataComponent implements OnInit {

  public form = this.fb.group({
    clave_catastral: [{ value: null, disabled: true }, Validators.required],
    clave_catastral_prev: [{ value: null, disabled: false }],
    predio_calle: [{ value: null, disabled: true }, Validators.required],
    predio_lote: [{ value: null, disabled: true }, Validators.required],
    predio_manzana: [{ value: null, disabled: true }, Validators.required],
    predio_num_ext: [{ value: null, disabled: true }, Validators.required],
    predio_num_int: [{ value: null, disabled: true }, Validators.required],
    predio_colonia: [{ value: null, disabled: true }, Validators.required],
    predio_cp: [{ value: null, disabled: true }, Validators.required],
    predio_municipio: [{ value: null, disabled: true }, Validators.required],
    predio_localidad: [{ value: null, disabled: true }, Validators.required],
    owner_nombre: [{ value: null, disabled: true }, Validators.required],
    owner_apellido_paterno: [{ value: null, disabled: true }, Validators.required],
    owner_apellido_materno: [{ value: null, disabled: true }, Validators.required],
    official_receipt: this.fb.group({
      official_receipt_number: [null, Validators.required],
      official_receipt_date: [null, Validators.required],
    }),
    extras: this.fb.group({
      ip_expiration_date: [null],
      land_surface_privativa: [null],
      land_surface_comun: [null],
      construction_surface_privativa: [null],
      construction_surface_comun: [null],
      land_value_privativa: [null],
      land_value_comun: [null],
      construction_value_privativa: [null],
      construction_value_comun: [null],
      amount: [null]
    }),
  });

  public details?: ApplicationDetails;
  private invoice_data: any = {};
  private pre_data: any = {};

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: QuestionData,
    public dialogRef: MatDialogRef<UpdateDataComponent>,
    private snack: MatSnackBar,
    public menuService: MenuService,
    private dialog: MatDialog,
    private procedureService: ProcedureService,
    private fb: FormBuilder,
  ) { }

  ngOnInit(): void {
    let loading = this.dialog.open(LoadingComponent, { disableClose: true });
    this.procedureService.get_folio_detail().subscribe({
      next: (res) => {
        if (res.ok) {
          this.details = res;
          this.form.patchValue(res.data.application_data.form_data);
          this.form.patchValue(res.data.invoice_data ? JSON.parse(res.data.invoice_data.payment_data) : {});
          this.invoice_data.number = res.data.invoice_data.number;
          this.invoice_data.expiration_date = res.data.invoice_data.expiration_date;
          this.invoice_data.extra_data = {
            firmante: res.data.application_data.Department_applicationType.credentials,
            depto: res.data.application_data.Department_applicationType.Cat_department.name,
            municipality: res.data.application_data.Department_applicationType.Cat_department.Cat_municipality.name
          };
          this.pre_data.file_id = res.data.files_data.find(x => x.Cat_file.id == 12)!.id;
          this.pre_data.cat_file_id = 12;
          loading.close();
        }
      },
      error: (err) => {
        loading.close();
      }
    });
  }

  upload() {
    if (this.form.valid) {
      this.form.enable();
      this.invoice_data.payment_data = { ...this.form.value, ...this.invoice_data.extra_data };
      this.pre_data.file_meta_data = JSON.stringify(this.invoice_data);
      const params = this.objectToFormData(this.pre_data);
      let loading = this.dialog.open(LoadingComponent, { disableClose: true });
      this.procedureService.upload_document(params).subscribe({
        next: (res) => {
          loading.close();
          this.dialogRef.close(true);
        },
        error: (err) => {
          loading.close();
          this.snack.open("Ocurrió un error al actualizar los datos, verifica que estos sean correctos.", 'Cerrar', { duration: 1000 });
        }
      });
      this.disable();
    } else {
      this.snack.open("Validad que todos los campos requeridos esten llenos.", 'Cerrar', { duration: 1000 });
    }
  }

  disable() {
    this.form.disable();
    this.form.get('extras')?.enable();
    this.form.get('official_receipt')?.enable();
    this.form.get('clave_catastral_prev')?.enable();
  }

  objectToFormData(obj: Object) {
    const formData = new FormData();
    Object.entries(obj).forEach(([key, value]) => {
      formData.append(key, value);
    });
    return formData;
  }
}
