import { Breakpoints } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { ApplicationElement } from 'src/app/models/application/element';
import { ServFilters } from 'src/app/models/services/serv-filters';
import { ProcedureService } from 'src/app/services/api/procedure.service';
import { LayoutService } from 'src/app/services/layout.service';
import { MenuService } from 'src/app/services/menu.service';
import * as moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { LoadingComponent } from '../../dialogs/loading/loading.component';
moment.locale('es-mx');

const INPUT_CASES = {
  [Breakpoints.XSmall]: 'smartphone',
  [Breakpoints.Small]: 'tablet',
  [Breakpoints.Medium]: 'desktop',
  [Breakpoints.Large]: 'desktop',
  [Breakpoints.XLarge]: 'desktop',
};

export interface PeriodicElement {
  catastro: string;
  notario: number;
  certificado: string;
  status: string;
  creacion: string;
}

@Component({
  selector: 'app-folios-table',
  templateUrl: './folios-table.component.html',
  styleUrls: ['./folios-table.component.sass'],
})

export class FoliosTableComponent implements OnInit, OnDestroy {
  public options: any = { case: 'desktop' };
  public displayedColumns: string[] = ['catastro', 'certificado', 'notario', 'status', 'annotations', 'creacion', 'acciones'];
  public dataSource!: MatTableDataSource<ApplicationElement>;
  private _count: number = 0;
  private _subscription?: Subscription;
  private loading!: any;

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  constructor(
    private layoutService: LayoutService,
    private procedureService: ProcedureService,
    public menuService: MenuService,
    private dialog: MatDialog,
  ) {
  }

  ngOnInit(): void {
    this.setLayout();
    this._subscription = this.menuService.servFiltersObserv.subscribe((params: ServFilters) => {
      this.loading = this.dialog.open(LoadingComponent, { maxWidth: "600px", disableClose: true });
      this.syncTable(params);
      this.loading.close();
    });
  }

  setLayout() {
    this.layoutService.breakPoint.subscribe((breakPoint: string) => {
      this.options.case = INPUT_CASES[breakPoint];
    });
  }

  checkExpiration(date: Date) {
    const now = moment(new Date);
    const exp = moment(date);
    const minutes = exp.diff(now, 'm');
    return minutes <= 0;
  }

  showDate(date: Date) {
    return moment(date).format('LL');
  }

  ngOnDestroy(): void {
    this._subscription?.unsubscribe();
    this.layoutService.destroyed.next();
    this.layoutService.destroyed.complete();
  }

  change(ev: PageEvent): void {
    this.menuService.limit = ev.pageSize;
    this.menuService.page = ev.pageIndex + 1;
  }

  syncTable(params: ServFilters) {
    this.procedureService.get_folios(params).subscribe({
      next: (response: any) => {
        const data: ApplicationElement[] = response.data;
        this._count = response.count;
        this.dataSource = new MatTableDataSource(data);        
        this.menuService.size = this._count;
      },
      complete: () => { },
      error: (err) => { }
    });
  }
}
