import { Breakpoints } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MunicipalityService } from 'src/app/services/api/municipality.service';
import { LayoutService } from 'src/app/services/layout.service';
import { format, subMonths } from 'date-fns';
import { DashboardService } from 'src/app/services/api/dashboard.service';
import { LoadingComponent } from '../../dialogs/loading/loading.component';
import { MatDialog } from '@angular/material/dialog';
import { adminOpts } from 'src/app/shared/statics/menus';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription, debounceTime } from 'rxjs';


const INPUT_CASES = {
  [Breakpoints.XSmall]: 'smartphone',
  [Breakpoints.Small]: 'tablet',
  [Breakpoints.Medium]: 'desktop',
  [Breakpoints.Large]: 'desktop',
  [Breakpoints.XLarge]: 'desktop',
};

@Component({
  selector: 'app-platform-administrator',
  templateUrl: './platform-administrator.component.html',
  styleUrls: ['./platform-administrator.component.sass']
})
export class PlatformAdministratorComponent implements OnInit, OnDestroy{

  public options: any = { case: 'desktop' };
  public option: any;
  public municipalities: any;
  public municipalitiesSelected: any;
  public rangeForm!: FormGroup;
  public range: any;
  public range_date:any = { start:'', end:'' };
  private loading: any;
  public menu: any;

  public chartData: any;
  searchControl = new FormControl();
  private subscription!: Subscription;

  cardData: any[] = [
    {
      count: 0,
      title: 'Usuarios',
      icon: 'person',
      url: ''
    },
    {
      count: 0,
      title: 'Municipios',
      icon: 'maps_home_work',
      url: '../private/administrador/municipios',
      menu: adminOpts[1]
    }
  ]
  
  displayedColumns: string[] = ['notary_number', 'notary_name', 'cam', 'cip', 'cvc', 'ctd', 'ct'];
  dataSource!: MatTableDataSource<any>;
  public application_per_type_label: any;

  public count: number = 5;
  public page: number = 1;
  public cant: number = 5;
  public filter: string = '';
  public search: string = '';
  public sort_api: string = '';
  public direction: string = 'DESC';

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  constructor(
    private layoutService: LayoutService,
    private municipalityService: MunicipalityService,
    private dialog: MatDialog,
    private dashboardService: DashboardService,
    private snack: MatSnackBar,
  ) { 
    this.rangeForm = new FormGroup({
      start: new FormControl<Date | null>(null, Validators.required),
      end: new FormControl<Date | null>(null, Validators.required),
    });
  }
  
  async ngOnInit(){
    this.setLayout();
    try {      
      await this.getMenu();      
      await this.getRangeDate();    
      await this.getMunicipalities();
      this.loading = this.dialog.open(LoadingComponent, { maxWidth: "600px", disableClose: true });
      await this.getMetrics();
      await this.syncTable();
      this.loading.close();      
    } catch (error) {
      this.loading.close();      
      this.snack.open('Se ha producido un error al recuperar la información del dashboard. Por favor, inténtelo de nuevo más tarde.', 'Cerrar', { duration: 4500 });
    }
    this.subscription = this.searchControl.valueChanges
    .pipe(debounceTime(500))
    .subscribe((value: string) => {
      this.applyFilter(value);      
    }); 
  }  

  async getMenu(): Promise<void>{    
    return new Promise<void>((resolve) => {
      this.menu = adminOpts[1]; 
      resolve();
    }); 
  }
  
  async getRangeDate(): Promise<void>{
    return new Promise<void>((resolve) => {
      const currentDate = new Date();
      const startDate = subMonths(currentDate, 1);
      
      this.rangeForm.setValue({
        start: startDate,
        end: currentDate,
      });
  
      this.range_date = {
        start: format(startDate, 'yyyy-MM-dd'),
        end: format(currentDate, 'yyyy-MM-dd'),
      };
      resolve();
    }); 
   
  }

  async getMunicipalities(): Promise<void>{
    return new Promise<void>((resolve, reject) => {
      let req_params = {
        catalog_name: 'cat_municipality',
        search: '',
        paginator: {
          page: 1,
          limit: 30,
        },
        order: ["name", "DESC"]
      };
  
      this.municipalityService.get_municipality(req_params).subscribe({
        next: (response: any) => {
          if(response.ok){
            this.municipalities = response.data.data;
            this.option = this.municipalities.map((minicipality: any) => minicipality.id);
            resolve();
          }else{
            reject(new Error('Ocurrió un error al intentar recuperar los municipios. Por favor, intete de nuevo más tarde.'));
          }        
        },
        error: () => {
          this.loading.close();
          reject(new Error('Ocurrió un error al intentar recuperar los municipios. Por favor, intete de nuevo más tarde.'));
        },        
      });      
    });
  }

  async updateData(){
    this.loading = this.dialog.open(LoadingComponent, { maxWidth: "600px", disableClose: true });
    await this.getMetrics();
    await this.syncTable();
    this.loading.close();
  }

  async getMetrics(): Promise<void>{
    return new Promise<void>((resolve, reject) => {
      this.chartData = undefined;
      let req_params ={
        municipalities: this.option,
        range_date: {
          start: format(this.rangeForm.get('start')?.value, 'yyyy-MM-dd'),
          end: format(this.rangeForm.get('end')?.value, 'yyyy-MM-dd')
        },
      }
            
      this.dashboardService.get_metrics_admin_dashboard(req_params).subscribe({
        next: (response:any) => {
          if(response.ok){
            this.cardData[0].count = response.data.number_of_users;
            this.cardData[1].count = response.data.number_of_municipalities;
            this.chartData = response.data.number_of_applications;            
            resolve();
          }else{
            reject(new Error('Ocurrió un error al intentar recuperar las metricas del dashboard. Por favor, intete de nuevo más tarde.'));
          }          
        }, 
        error: ()=> {
          reject(new Error('Ocurrió un error al intentar recuperar las metricas del dashboard. Por favor, intete de nuevo más tarde.'));
        }
      });    
    }); 
  } 

  ngOnDestroy(): void {
    this.layoutService.destroyed.next();
    this.layoutService.destroyed.complete();
  }

  setLayout() {
    this.layoutService.breakPoint.subscribe((breakPoint: string) => {
      this.options.case = INPUT_CASES[breakPoint];      
    });
  }

  formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = this.padZeroes(date.getMonth() + 1);
    const day = this.padZeroes(date.getDate());
    return `${year}-${month}-${day}`;
  }
  
  padZeroes(value: number): string {
    return value < 10 ? `0${value}` : `${value}`;
  }

  async syncTable(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      let req_params = {
        filters: [
          { municipalities: this.option },
          { 
            range_date: {
              start: format(this.rangeForm.get('start')?.value, 'yyyy-MM-dd'),
              end: format(this.rangeForm.get('end')?.value, 'yyyy-MM-dd')
            } 
          }
        ],
        search: this.search,
        order: ["cat_notary_id", "DESC"]
      }   
      
      this.dashboardService.get_applications_by_notary(req_params).subscribe({
        next: (response: any) => {
          if(response.ok){
            let dataTable = response.data;
            this.dataSource = new MatTableDataSource(dataTable);            
            resolve();
          }else{           
            reject(new Error('Ocurrió un error al intentar recuperar la información de la tabla. Por favor, intete de nuevo más tarde.'));
          }         
        },       
        error: (err) => {          
          reject(new Error('Ocurrió un error al intentar recuperar la información de la tabla. Por favor, intete de nuevo más tarde.'));          
        }
      });
    });    
  }

  // change_page(evt: PageEvent) {
  //   this.count = evt.length;
  //   this.page = evt.pageIndex + 1;
  //   this.cant = evt.pageSize;
  //   this.syncTable();
  // }

  async applyFilter(search: string) {
    this.loading = this.dialog.open(LoadingComponent, { maxWidth: "600px", disableClose: true });
    this.search = search;       
    await this.syncTable();
    this.loading.close();
  }
}
