import { Breakpoints } from '@angular/cdk/layout';
import { Component, ViewChild } from '@angular/core';
import { LayoutService } from 'src/app/services/layout.service';

const INPUT_CASES = {
  [Breakpoints.XSmall]: 'smartphone',
  [Breakpoints.Small]: 'tablet',
  [Breakpoints.Medium]: 'desktop',
  [Breakpoints.Large]: 'desktop',
  [Breakpoints.XLarge]: 'desktop',
};

@Component({
  selector: 'app-departments-details',
  templateUrl: './departments-details.component.html',
  styleUrls: ['./departments-details.component.sass']
})

export class DepartmentsDetailsComponent {
  public options: any = { case: 'desktop' }
  public encode: any;
  public depData: any;
  public dataFromMenu: any;
  constructor(
    private layoutService: LayoutService,
  ) {

  }

  ngOnInit(): void {
    this.setLayout();
  }

  ngOnDestroy(): void {
    this.layoutService.destroyed.next();
    this.layoutService.destroyed.complete();
  }

  setLayout() {
    this.layoutService.breakPoint.subscribe((breakPoint: string) => {
      this.options.case = INPUT_CASES[breakPoint];      
    });
  }

  recibirDatos(data: any) {
    this.dataFromMenu = data;
  }
}
