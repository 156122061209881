<h1 mat-dialog-title>{{ data.title }}</h1>
<div mat-dialog-content>
    <div [innerHTML]="data.body"></div>
    <form [formGroup]="form" *ngIf="details">
        <div>
            <h5>Pago de derechos</h5>
            <hr>
            <div formGroupName="official_receipt" class="data-row">
                <mat-form-field class="input">
                    <mat-label>N° de recibo oficial</mat-label>
                    <input matInput formControlName="official_receipt_number">
                </mat-form-field>
                <mat-form-field class="input">
                    <mat-label>Fecha de pago</mat-label>
                    <input matInput type="datetime-local" formControlName="official_receipt_date">
                </mat-form-field>
            </div>
        </div>
        <div formGroupName="extras">
            <div *ngIf="[1, 5].includes(details.data.application_data.Department_applicationType.Cat_application_type.id)">
                <h5>Impuesto predial</h5>
                <hr>
                <div class="data-row">
                    <mat-form-field class="input">
                        <mat-label>Vigencia hasta...</mat-label>
                        <input matInput formControlName="ip_expiration_date">
                    </mat-form-field>
                </div>
            </div>
            <div *ngIf="[2, 5].includes(details.data.application_data.Department_applicationType.Cat_application_type.id)">
                <h5>Superficies</h5>
                <hr>
                <div class="data-row">
                    <mat-form-field class="input">
                        <mat-label>m2 del terreno (privativa)</mat-label>
                        <input matInput type="number" formControlName="land_surface_privativa">
                    </mat-form-field>
                    <mat-form-field class="input">
                        <mat-label>m2 del terreno (común)</mat-label>
                        <input matInput type="number" formControlName="land_surface_comun">
                    </mat-form-field>
                    <mat-form-field class="input">
                        <mat-label>m2 de construcción (privativa)</mat-label>
                        <input matInput type="number" formControlName="construction_surface_privativa">
                    </mat-form-field>
                    <mat-form-field class="input">
                        <mat-label>m2 de construcción (común)</mat-label>
                        <input matInput type="number" formControlName="construction_surface_comun">
                    </mat-form-field>
                </div>
            </div>
            <div *ngIf="[2, 5].includes(details.data.application_data.Department_applicationType.Cat_application_type.id)">
                <h5>Valores</h5>
                <hr>
                <div class="data-row">
                    <mat-form-field class="input">
                        <mat-label>$ del terreno (privativa)</mat-label>
                        <input matInput type="number" formControlName="land_value_privativa">
                    </mat-form-field>
                    <mat-form-field class="input">
                        <mat-label>$ del terreno (común)</mat-label>
                        <input matInput type="number" formControlName="land_value_comun">
                    </mat-form-field>
                    <mat-form-field class="input">
                        <mat-label>$ de construcción (privativa)</mat-label>
                        <input matInput type="number" formControlName="construction_value_privativa">
                    </mat-form-field>
                    <mat-form-field class="input">
                        <mat-label>$ de construcción (común)</mat-label>
                        <input matInput type="number" formControlName="construction_value_comun">
                    </mat-form-field>
                    <mat-form-field class="input">
                        <mat-label>$ Total</mat-label>
                        <input matInput type="number" formControlName="amount">
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div>
            <h5>Datos del predio</h5>
            <hr>
            <div class="data-row">
                <mat-form-field class="input">
                    <mat-label>Clave catastral</mat-label>
                    <input matInput formControlName="clave_catastral">
                </mat-form-field>
                <mat-form-field class="input">
                    <mat-label>Clave catastral anterior</mat-label>
                    <input matInput formControlName="clave_catastral_prev">
                </mat-form-field>
                <mat-form-field class="input">
                    <mat-label>Calle</mat-label>
                    <input matInput formControlName="predio_calle">
                </mat-form-field>
                <mat-form-field class="input">
                    <mat-label>Lote</mat-label>
                    <input matInput formControlName="predio_lote">
                </mat-form-field>
                <mat-form-field class="input">
                    <mat-label>Manzana</mat-label>
                    <input matInput formControlName="predio_manzana">
                </mat-form-field>
                <mat-form-field class="input">
                    <mat-label>N° exterior</mat-label>
                    <input matInput formControlName="predio_num_ext">
                </mat-form-field>
                <mat-form-field class="input">
                    <mat-label>N° interior</mat-label>
                    <input matInput formControlName="predio_num_int">
                </mat-form-field>
                <mat-form-field class="input">
                    <mat-label>Colonia</mat-label>
                    <input matInput formControlName="predio_colonia">
                </mat-form-field>
                <mat-form-field class="input">
                    <mat-label>Código postal</mat-label>
                    <input matInput formControlName="predio_cp">
                </mat-form-field>
                <mat-form-field class="input">
                    <mat-label>Municipio</mat-label>
                    <input matInput formControlName="predio_municipio">
                </mat-form-field>
                <mat-form-field class="input">
                    <mat-label>Localidad</mat-label>
                    <input matInput formControlName="predio_localidad">
                </mat-form-field>
                <mat-form-field class="input">
                    <mat-label>Nombre del propietario</mat-label>
                    <input matInput formControlName="owner_nombre">
                </mat-form-field>
                <mat-form-field class="input">
                    <mat-label>Apellido paterno del propietario</mat-label>
                    <input matInput formControlName="owner_apellido_paterno">
                </mat-form-field>
                <mat-form-field class="input">
                    <mat-label>Apellido materno del propietario</mat-label>
                    <input matInput formControlName="owner_apellido_materno">
                </mat-form-field>
            </div>
        </div>
    </form>
</div>
<div mat-dialog-actions align="end">
    <button mat-button (click)="upload()" color="primary">Continuar</button>
    <button mat-button [mat-dialog-close]="false">Cancelar</button>
</div>