<mat-card class="menu-container" [ngClass]="options.case">
    <div class="menu-header-container">
        <span class="header-title">Tesorería</span>
        <button type="button" class="header-button" matTooltip="Agregar solicitud" (click)="addApplication()"> <mat-icon>add</mat-icon> </button>
    </div>
    <mat-divider></mat-divider>
    <ul>
        <li *ngFor="let element of depData">
            <a (click)="details(element.id)">
                <div class="menu-option-container">
                    <div class="icon-container">
                        <mat-icon>description</mat-icon>
                    </div>
                    <div class="menu-option">
                        {{element.Cat_application_type.name}}
                    </div>
                </div>
            </a>
        </li>
    </ul>
</mat-card>