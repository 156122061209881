import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { MenuService } from '../menu.service';
import { ApplicationDetails } from 'src/app/models/application/details';
import { Observable } from 'rxjs';
import { ChangeStatus } from 'src/app/shared/statics/constants';
import { DefaultResponse } from 'src/app/core/components/dialogs/upload-documents/upload-documents.component';

@Injectable({
  providedIn: 'root'
})
export class ProcedureService {

  private url: string = environment.urlApi;


  constructor(
    private http: HttpClient,
    private _menuService: MenuService
  ) { }

  get_folios(req_params: any) {
    const scope = `${this.url}application/list_all_by`;
    return this.http.post(`${scope}`, req_params);
  }

  get_folio_detail(): Observable<ApplicationDetails> {
    const req_params = { application_number: this._menuService.selectedApplicationElement?.number };
    const scope = `${this.url}application/get_details`;
    return this.http.post<ApplicationDetails>(`${scope}`, req_params);
  }

  get_type() {
    const req_params = {
      catalog_name: "cat_department_applicationType",
      search: "",
      paginator: {
        page: 1,
        limit: 100
      },
      order: ["name", "DESC"]
    };

    const scope = `${this.url}catalog/get`;
    return this.http.post(`${scope}`, req_params);
  }

  save_annotations() {
    const req_params = {
      file_id: this._menuService.selectedFile ? this._menuService.selectedFile.id : 0,
      data: { annotations: this._menuService.annotations }
    };

    const scope = `${this.url}file/update_status`;
    return this.http.put(`${scope}`, req_params);
  }

  validate_file(flag: boolean) {
    const req_params = {
      file_id: this._menuService.selectedFile ? this._menuService.selectedFile.id : 0,
      data: { validated: flag }
    };

    const scope = `${this.url}file/update_status`;
    return this.http.put(`${scope}`, req_params);
  }

  change_status(flag: ChangeStatus) {
    const req_params = {
      application_id: this._menuService.selectedApplicationElement ? this._menuService.selectedApplicationElement.id : 0,
      status: flag,
      commentary: ""
    };

    const scope = `${this.url}application/update_status`;
    return this.http.post(`${scope}`, req_params);
  }

  upload_document(req_params: FormData) {
    if (this._menuService.selectedApplicationElement) req_params.append("application_id", `${this._menuService.selectedApplicationElement.id}`);

    const scope = `${this.url}file/create_update`;
    return this.http.post<DefaultResponse>(`${scope}`, req_params);
  }

  generate_certificate(flag: boolean) {
    const req_params = {
      application_id: this._menuService.selectedApplicationElement ? this._menuService.selectedApplicationElement.id : 0,
      precertificate: flag
    };

    const scope = `${this.url}file/generate_certificate`;
    return this.http.post(`${scope}`, req_params);
  }

}
