import { Breakpoints } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FilesDatum } from 'src/app/models/application/details';
import { ProcedureService } from 'src/app/services/api/procedure.service';
import { LayoutService } from 'src/app/services/layout.service';

const INPUT_CASES = {
  [Breakpoints.XSmall]: 'smartphone',
  [Breakpoints.Small]: 'tablet',
  [Breakpoints.Medium]: 'desktop',
  [Breakpoints.Large]: 'desktop',
  [Breakpoints.XLarge]: 'desktop',
};

@Component({
  selector: 'app-file-viewer',
  templateUrl: './file-viewer.component.html',
  styleUrls: ['./file-viewer.component.sass']
})
export class FileViewerComponent implements OnInit, OnDestroy {
  public options: any = { case: 'desktop' }
  public files: FilesDatum[] = [];

  constructor(
    private layoutService: LayoutService,
    private procedureService: ProcedureService
  ) { }

  ngOnInit(): void {
    this.procedureService.get_folio_detail().subscribe({
      next: (res) => {
        if (res.ok) {
          this.files = res.data.files_data;
        }
      },
      error: (err) => { }
    });
    this.setLayout();
  }

  ngOnDestroy(): void {
    this.layoutService.destroyed.next();
    this.layoutService.destroyed.complete();
  }

  setLayout() {
    this.layoutService.breakPoint.subscribe((breakPoint: string) => {
      this.options.case = INPUT_CASES[breakPoint];
    });
  }
}
