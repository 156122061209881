import { Breakpoints } from '@angular/cdk/layout';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { passwordsMatch } from 'src/app/core/validators/passwordMatch.validator';
import { CryptoService } from 'src/app/services/api/crypto.service';
import { MunicipalityService } from 'src/app/services/api/municipality.service';
import { AuthenticationService } from 'src/app/services/firebase/authentication.service';
import { LayoutService } from 'src/app/services/layout.service';
import { LoadingComponent } from '../../../dialogs/loading/loading.component';

const INPUT_CASES = {
  [Breakpoints.XSmall]: 'smartphone',
  [Breakpoints.Small]: 'tablet',
  [Breakpoints.Medium]: 'desktop',
  [Breakpoints.Large]: 'desktop',
  [Breakpoints.XLarge]: 'desktop',
};

@Component({
  selector: 'app-admin-profile',
  templateUrl: './admin-profile.component.html',
  styleUrls: ['./admin-profile.component.sass']
})

export class AdminProfileComponent implements OnInit, OnDestroy{
  form_profile: FormGroup;
  telephones: FormControl[] = [];
  public options: any = { case: 'desktop' }
  
  admin_info: any;
  public new: any;
  public id_user: any;
  private loading!: any;
  public confirm_pass: any;
  public haveData: any;

  @Input() userData: any;
  @Input() municipality_id: any;

  constructor(
    private formBuilder: FormBuilder,
    private snack: MatSnackBar,
    private layoutService: LayoutService,
    private authService: AuthenticationService,
    private municipalityService: MunicipalityService,
    private router: Router,
    private dialog: MatDialog,
    private cService: CryptoService,
  ) {
    this.form_profile = this.formBuilder.group({
      name: [null, [Validators.required]],
      email: [null, [Validators.required, Validators.email]],
      password: ['', [Validators.required, , Validators.minLength(6)]],
      confirm_password: ['', [Validators.required, , Validators.minLength(6)]],
      phone_number: [null, [Validators.required, Validators.pattern(/^\+52\d{10}$/)]],
    },{validators: passwordsMatch});
  }

  ngOnInit(): void {
    if(this.userData){
      this.form_profile.patchValue(this.userData);
    }    
    this.setLayout();    
  }

  ngOnDestroy(): void {
    this.layoutService.destroyed.next();
    this.layoutService.destroyed.complete();    
  }

  setLayout() {
    this.layoutService.breakPoint.subscribe((breakPoint: string) => {
      this.options.case = INPUT_CASES[breakPoint];
    });
  }


  addPhone() {
    if (this.telephones.length < 3) {
      this.telephones.push(new FormControl('', Validators.required));
    } else {
      this.snack.open('El máximo de números telefónicos es de 3', 'Cerrar', { duration: 1000 });
    }
  }

  saveUser(add: boolean) {
    this.form_profile.value.notification_email = this.form_profile.value.email;
    this.form_profile.value.notification_phone_number = this.form_profile.value.phone_number;
    this.form_profile.value.cat_municipality_id = this.municipality_id;
    this.confirm_pass = this.form_profile.value.confirm_password;
    delete this.form_profile.value.confirm_password;
    if (add == true) {
        this.loading = this.dialog.open(LoadingComponent, { maxWidth: "600px", disableClose: true });
        this.municipalityService.add_admin(this.form_profile.value).subscribe({
          next: (response: any) => {
            this.loading.close();
            if(response.ok){
              this.snack.open('Se ha agregado correctamente el Administrador de Municipio', 'Cerrar', { duration: 1000 });
              this.router.navigateByUrl(`/private/administrador/municipios`);           
            }else{            
              this.snack.open('Ocurrió un error al intentar guardar el usuario. Por favor, intente de nuevo más tarde.', 'Cerrar', { duration: 5000 });
            }
            
          },          
          error: (err: any) => {
            this.loading.close();
            this.snack.open(err.error.error.description, 'Cerrar', { duration: 3000 });
          }
        });
    }else{
      this.loading = this.dialog.open(LoadingComponent, { maxWidth: "600px", disableClose: true });
      delete this.form_profile.value.password;
      delete this.form_profile.value.confirm_password;
      
      
      let req_params = {
        user_id: this.userData.id,
        user_data: this.form_profile.value
      }
      this.municipalityService.update_admin(req_params).subscribe({
        next: (response: any) => {
          this.snack.open('Se ha actualizado correctamente el Administrador de Municipio', 'Cerrar', { duration: 1000 });
          this.router.navigateByUrl(`/private/administrador/municipios`);
        },
        complete: () => {
          this.loading.close();
        },
        error: (err) => {
          this.loading.close();
          this.snack.open(err.error.message, 'Cerrar', { duration: 3000 });
        }
      });
    }
  }

  clickImage() {
    let click = document.getElementById('user-profile-image') as HTMLInputElement;
    click.click();
  }

  sendEmail(email: any) {
    let payload = {
      email: email
    }
    this.loading = this.dialog.open(LoadingComponent, { maxWidth: "600px", disableClose: true });
    this.authService.sendPasswordResetEmail(payload).then((response: any) => {
      this.snack.open('Se ha enviado un correo de recuperación', 'Cerrar', { duration: 1000 });
      this.loading.close();
    }).catch((err: any) => {
      this.snack.open('El correo no está registrado', 'Cerrar', { duration: 1000 });
    });
  }

  validatePasswords(){
    if(this.form_profile.hasError('passwordMismatch')){
      this.form_profile?.get('confirm_password')?.setErrors({ 'passwordMismatch': true });
    }else{
      this.form_profile?.get('confirm_password')?.setErrors(null);
    }           
  }  
}
