import { Injectable } from '@angular/core';
import { initializeApp } from "firebase/app";
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  private _firebaseConfig = environment.firebaseConfig;
  private _app = initializeApp(this._firebaseConfig);

  constructor() {}

  get app() {
    return this._app;
  }
}