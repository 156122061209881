import { Breakpoints } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { LayoutService } from 'src/app/services/layout.service';
import { UserService } from 'src/app/services/api/user.service';
import { Router } from '@angular/router';
import { CryptoService } from 'src/app/services/api/crypto.service';
import { FormControl } from '@angular/forms';
import { Subscription, debounceTime } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { LoadingComponent } from '../../dialogs/loading/loading.component';

const INPUT_CASES = {
  [Breakpoints.XSmall]: 'smartphone',
  [Breakpoints.Small]: 'tablet',
  [Breakpoints.Medium]: 'desktop',
  [Breakpoints.Large]: 'desktop',
  [Breakpoints.XLarge]: 'desktop',
};

@Component({
  selector: 'app-public-servants',
  templateUrl: './public-servants.component.html',
  styleUrls: ['./public-servants.component.sass']
})

export class PublicServantsComponent implements OnInit, OnDestroy{
  public options: any = { case: 'desktop' };
  displayedColumns: string[] = ['nombre', 'status', 'rol_name', 'creacion', 'acciones'];
  dataSource!: MatTableDataSource<any>;
  selectedButtonIndex: number | null = null;

  public count: number = 5;
  public page: number = 1;
  public cant: number = 5;
  public filter: string = '';
  public search: string = '';
  public sort_api: string = '';
  public direction: string = 'DESC';

  private loading!: any;
  
  searchControl = new FormControl();
  private subscription!: Subscription;

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  constructor(
    private snack: MatSnackBar,
    private dialog: MatDialog,
    private layoutService: LayoutService,
    private userService: UserService,
    private router: Router,    
    private cryptoService: CryptoService
  ){ } 

  ngOnInit(): void {
    this.setLayout();
    this.syncTable();
    this.subscription = this.searchControl.valueChanges
      .pipe(debounceTime(500))
      .subscribe((value: string) => {
        this.applyFilter(value);      
      });    
  }

  setLayout(){
    this.layoutService.breakPoint.subscribe((breakPoint: string) => {
      this.options.case = INPUT_CASES[breakPoint];      
    });
  } 
  
  ngOnDestroy(): void {
    this.layoutService.destroyed.next();
    this.layoutService.destroyed.complete();
    this.subscription.unsubscribe();
  }

  selectButton(index: number) {
    this.selectedButtonIndex = index;
  }

  syncTable() {
    let req_params = {
      filters: [
          { cat_municipality_id: [2] },
          { search: this.search }
      ],
      paginator: {
          page: this.page,
          limit: this.cant
      },
      order: ["createdAt", "DESC"]
    };
    this.loading = this.dialog.open(LoadingComponent, { maxWidth: "600px", disableClose: true });
    this.userService.list_all_by(req_params).subscribe({
      next: (response: any) => {
        this.loading.close();
        let dataTable = response.data;
        this.dataSource = new MatTableDataSource(dataTable);
        this.count = response.count;
      },
      complete: () => {
        this.loading.close();
      },
      error: (err) => {
        this.loading.close();
        this.snack.open('Ocurrió un error al intentar obtener la información de la tabla. Por favor, inténtelo de nuevo más tarde.', 'Cerrar', { duration: 3500 });        
      }
    });
  }

  change_page(evt: PageEvent) {
    this.count = evt.length;
    this.page = evt.pageIndex + 1;
    this.cant = evt.pageSize;
    this.syncTable();
  }

  navigate(status: boolean, id?:number){    
    const data = {
      new: status
    }
    const encryptedData = this.cryptoService.encrypt(JSON.stringify(data));
    this.router.navigate([`/private/municipio/detalle/servidor-publico`, encryptedData] );    
  }
  
  applyFilter(search: string) {
    this.page = 1;    
    this.search = search;       
    this.syncTable();   
  }

  newData() {
    const req = {
      idMun: '',
      new: true
    };
    let data = this.cryptoService.encrypt(JSON.stringify(req));
    this.router.navigate([`/private/municipio/detalle`, data]);
  }
  
  sendData(id_municipality: number) {
    let req = {
      idMun: id_municipality,
      new: false
    }    
    let data = this.cryptoService.encrypt(JSON.stringify(req));
    this.router.navigate([`/private/municipio/detalle`, data]);
  }

}
