import { Breakpoints } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { LayoutService } from 'src/app/services/layout.service';
import { localStorageService } from 'src/app/services/local-storage.service';

const INPUT_CASES = {
  [Breakpoints.XSmall]: 'smartphone',
  [Breakpoints.Small]: 'tablet',
  [Breakpoints.Medium]: 'desktop',
  [Breakpoints.Large]: 'desktop',
  [Breakpoints.XLarge]: 'desktop',
}

@Component({
  selector: 'app-public-server',
  templateUrl: './public-server.component.html',
  styleUrls: ['./public-server.component.sass']
})

export class PublicServerComponent implements OnInit, OnDestroy {
  public options: any = { case: 'desktop' };
  public user: any;
  public cards: any[] = [
    {
      imageUrl: './../../../../../assets/servidorPublico/card1.png',
      title: 'Filtros',
      description: 'En la parte superior de la tabla, encontrarás las herramientas para filtrar solicitudes por estatus o departamento, o realizar una búsqueda directa',      
    },
    {
      imageUrl: './../../../../../assets/servidorPublico/card2.png',
      title: 'Perfil y cerrar sesión',
      description: 'En la parte inferior izquierda, al hacer clic en tu foto, se desplegará la opción de cerrar sesión y encontrarás tu perfil, donde podrás visualizar y editar tu información de contacto y de cuenta',      
    },
    {
      imageUrl: './../../../../../assets/servidorPublico/card3.png',
      title: 'Notificaciones',
      description: 'Para estar al tanto de las últimas novedades y recibir notificaciones, es crucial mantener tu información de contacto actualizada.',      
    },
    {
      imageUrl: './../../../../../assets/servidorPublico/card4.png',
      title: 'Acciones',
      description: 'En la parte superior derecha encontrarás las acciones para editar el documento: rechazo definitivo, corrección y validar trámite.',      
    }   
  ];

  constructor(
    private layoutService: LayoutService,
    public localService: localStorageService
  ) {
    this.user = localService.user;
   }

  ngOnInit(): void {
    this.setLayout();   

  }

  ngOnDestroy(): void {
    this.layoutService.destroyed.next();
    this.layoutService.destroyed.complete();
  }

  setLayout() {
    this.layoutService.breakPoint.subscribe((breakPoint: string) => {
      this.options.case = INPUT_CASES[breakPoint];      
    });
  }
}
