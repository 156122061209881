<div class="dialog-container">
  <div class="title-container">
    <span mat-dialog-title>
      Su sesión ha expirado
      <mat-icon matTooltip="Por razones de seguridad, debe volver a introducir su contraseña para continuar navegando en el sistema.">info</mat-icon>
    </span>
  </div>
  <mat-dialog-content>
    <p>Por favor, ingrese su contraseña para continuar</p> 
    <mat-form-field appearance="fill">
      <mat-label>Contraseña</mat-label>
      <input matInput type="password"  [(ngModel)]="password">
      <mat-icon matSuffix>password</mat-icon>      
    </mat-form-field>
    <span *ngIf="attempts < 3 && attempts > 1">Contraseña incorrecta. Restan {{attempts}} intentos</span>
    <span *ngIf="attempts == 1">Contraseña incorrecta. Resta {{attempts}} intento</span>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-button (click)="cancel()" matTooltip="En caso de cancelar, será redirigido a la ventana de inicio de sesión del sistema.">Cancelar</button>
    <button mat-button (click)="refresh()" [disabled]="password.length < 1">Aceptar</button>
  </mat-dialog-actions>
</div>