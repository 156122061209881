<div class="folios-table-container" [ngClass]="options.case">
  <mat-card>
    <div class="folios-table-search-container">      
      <div>
        <label class="search-title">{{ menuService.selectedSubmenu }}</label>
        <label class="search-subtitle">{{ menuService.selectedSubItem?.name }}</label>
      </div>
      <mat-form-field appearance="outline">
        <input matInput placeholder="Búsqueda" [(ngModel)]="menuService.search">
        <mat-icon matSuffix>search</mat-icon>
      </mat-form-field>      
    </div>
    <div class="body-table-container">
      <table mat-table [dataSource]="dataSource" *ngIf="dataSource && dataSource?.data?.length ?? 0 > 0; else noTableData">        
          <ng-container matColumnDef="catastro">
            <th mat-header-cell *matHeaderCellDef>Folio</th>
            <td mat-cell *matCellDef="let element">{{element.number}}</td>
          </ng-container>
          <ng-container matColumnDef="certificado">
            <th mat-header-cell *matHeaderCellDef>Departamento</th>
            <td mat-cell *matCellDef="let element">{{element.Department_applicationType.Cat_department.name}}</td>
          </ng-container>
          <ng-container matColumnDef="notario">
            <th mat-header-cell *matHeaderCellDef>Notario</th>
            <td mat-cell *matCellDef="let element">{{element.notary_name}}, Notaría N° {{element.notary_number}}</td>
          </ng-container>
          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef>Estatus</th>
            <td mat-cell *matCellDef="let element"><mat-chip>{{element.Cat_application_status.name}}</mat-chip></td>
          </ng-container>
          <ng-container matColumnDef="annotations">
            <th mat-header-cell *matHeaderCellDef>Anotaciones</th>
            <td mat-cell *matCellDef="let element">
              <div *ngIf="element.expiration_date && [7].includes(element.Cat_application_status.id)">
                <mat-chip style="background-color: green;" *ngIf="!checkExpiration(element.expiration_date)"><span style="color: white;">Liquidación activa</span></mat-chip>
                <mat-chip style="background-color: red;" *ngIf="checkExpiration(element.expiration_date)"><span style="color: white;">Liquidación expirada</span></mat-chip>
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="creacion">
            <th mat-header-cell *matHeaderCellDef>Creación</th>
            <td mat-cell *matCellDef="let element">{{ showDate(element.createdAt) }}</td>
          </ng-container>
          <ng-container matColumnDef="acciones">
            <th mat-header-cell *matHeaderCellDef>Acciones</th>
            <td mat-cell *matCellDef="let element">
              <app-buttons [applicationElement]="element"></app-buttons>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>            
      </table>
      <ng-template #noTableData>
        <div class="nodata-container">
          <span>No se encontró información</span>
        </div>        
      </ng-template>
    </div> 
    <mat-paginator
      (page)="change($event)"
      [pageIndex]="menuService.page - 1"
      [pageSizeOptions]="[10, 15, 20]"
      [pageSize]="menuService.limit"
      [length]="menuService.size"
      *ngIf="dataSource && dataSource?.data?.length ?? 0 > 0"
    >
    </mat-paginator>
  </mat-card>
</div>