<div class="layout-container" [ngClass]="options.case">
    <app-aside
        [open]="menuService.open"
        [menuobj]="menuOpts"
        [selected]="menuService.selectedRailItem"
        (homeClicked)="menuService.open = $event"
        (selectChange)="changeRail($event)">
    </app-aside>
    <mat-drawer-container (backdropClick)="menuService.open = !menuService.open">
        <mat-drawer [mode]="menuService.mode" [opened]="menuService.open" class="aside-scroll-custom">
            <h1 style="margin-top: 1.5rem;">Colegio de Notarios del Estado de México</h1>
            <ng-container *ngIf="menuService.selectedRailItem; else noSelected">
                <h2 style="margin-top: 1.5rem; margin-bottom: 1.5rem;">{{ menuService.selectedRailItem.name }}</h2>

                <mat-accordion *ngIf="menuService.selectedRailItem.id === 7">
                    <label class="label-select" for="selecttramite">Selecciona un trámite</label>
                    <select id="selecttramite" class="custom-select" [(ngModel)]="submenuName" (change)="changeSelection(submenuName)">
                        <option value="">-- Ningún elemento seleccionado --</option>
                        <option *ngFor="let sub of menuService.selectedRailItem.submenu;" [ngValue]="sub.name">{{ sub.name }}</option>
                    </select>
                    <div *ngIf="submenu">
                        <mat-list-item [ngClass]="{'menu-active': menuService.selectedSubItem ? item.id == menuService.selectedSubItem.id : false}" *ngFor="let item of submenu.items" (click)="select(item)">
                            <mat-icon matListItemIcon>{{ item.icon }}</mat-icon>
                            <div matListItemTitle>{{item.name}}</div>
                        </mat-list-item>
                    </div>
                </mat-accordion>

                <mat-accordion *ngIf="menuService.selectedRailItem.id !== 7">                    
                    <div *ngIf="regularMenu.length !== 0">
                        <div *ngFor="let sub of regularMenu">
                            <label class="label-select">{{ sub.name }}</label>
                            <mat-list-item *ngFor="let item of sub.items" [ngClass]="{'menu-active': menuService.selectedSubItem ? item.id == menuService.selectedSubItem.id : false}" (click)="select(item)">
                                <mat-icon matListItemIcon>{{ item.icon }}</mat-icon>
                                <div matListItemTitle>{{item.name}}</div>
                            </mat-list-item>
                        </div>
                    </div>
                </mat-accordion>

            </ng-container>
        </mat-drawer>
        <mat-drawer-content>
            <router-outlet></router-outlet>
        </mat-drawer-content>
    </mat-drawer-container>
</div>

<ng-template #noSelected>
    <h2>Bienvenido</h2>
</ng-template>