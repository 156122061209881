import { Breakpoints } from '@angular/cdk/layout';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MunicipalityService } from 'src/app/services/api/municipality.service';
import { LayoutService } from 'src/app/services/layout.service';
import { LoadingComponent } from '../../../dialogs/loading/loading.component';

const INPUT_CASES = {
  [Breakpoints.XSmall]: 'smartphone',
  [Breakpoints.Small]: 'tablet',
  [Breakpoints.Medium]: 'desktop',
  [Breakpoints.Large]: 'desktop',
  [Breakpoints.XLarge]: 'desktop',
};

@Component({
  selector: 'app-departments-request',
  templateUrl: './departments-request.component.html',
  styleUrls: ['./departments-request.component.sass']
})
export class DepartmentsRequestComponent implements OnChanges {
  @Input() data: any;
  @Input() dataApp: any;

  public options: any = { case: 'desktop' }
  form_request: FormGroup;
  form_request_cvc: FormGroup;
  form_request_cipcam: FormGroup;
  public cat_app: any;
  public cat_dep: any;
  public new: boolean = true;
  public loading: any;
  public idApp: any = '';
  mostrarSeccion = true; // Variable para controlar la visibilidad
  opcionSeleccionada!: number; // Valor por defecto
  



  constructor(
    private formBuilder: FormBuilder,
    private snack: MatSnackBar,
    private layoutService: LayoutService,
    private municipalityService: MunicipalityService,
    private dialog: MatDialog
  ) {
    this.form_request = this.formBuilder.group({
      cat_department_id: [null, [Validators.required]],
      cat_application_type_id: [null, [Validators.required]],
      name: [null, [Validators.required]],
      cuts: [null, [Validators.required]],
      department: [null, [Validators.required]],
    });

    this.form_request_cvc = this.formBuilder.group({
      name_cvc: [null, [Validators.required]],
      cuts_cvc: [null, [Validators.required]],
      department_cvc: [null, [Validators.required]],
    });

    this.form_request_cipcam = this.formBuilder.group({
      name_cipcam: [null, [Validators.required]],
      cuts_cipcam: [null, [Validators.required]],
      department_cipcam: [null, [Validators.required]],
    });

  }

  ngOnInit(): void {
    this.setLayout();
    this.getCatalogApp();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(this.dataApp?.new != undefined){
      this.new = this.dataApp?.new;
    }
    if(this.dataApp?.idDep){
      this.idApp = this.dataApp?.idDep;
    }
    
    this.getCatalogApp();
    if (this.new == false) {
      this.setLayout();
      this.getInfoApp(this.idApp);
    }
    else {
      this.mostrarSeccion = false;
      this.form_request.reset();
      this.setLayout();
    }
  }

  ngOnDestroy(): void {
    this.layoutService.destroyed.next();
    this.layoutService.destroyed.complete();
  }

  setLayout() {
    this.layoutService.breakPoint.subscribe((breakPoint: string) => {
      this.options.case = INPUT_CASES[breakPoint];
    });
  }

  saveApp() {
    if (this.form_request.value.cat_application_type_id == 5) {
      let payload = {
        cat_application_type_id: this.form_request.value.cat_application_type_id,
        cat_department_id: this.form_request.value.cat_department_id,
        credentials: {
          certificate_signing:
          {
            cuts: this.form_request.value.cuts,
            name: this.form_request.value.name,
            department: this.form_request.value.department
          },
          cvc_signing:
          {
            cuts: this.form_request.value.cuts_cvc,
            name: this.form_request.value.name_cvc,
            department: this.form_request.value.department_cvc
          },
          cip_cam_signing:
          {
            cuts: this.form_request.value.cuts_cipcam,
            name: this.form_request.value.name_cipcam,
            department: this.form_request.value.department_cipcam
          }
        },
      };

      this.loading = this.dialog.open(LoadingComponent, { maxWidth: "600px", disableClose: true });
      this.municipalityService.add_application(payload).subscribe({
        next: (response: any) => {
          this.loading.close();
          this.snack.open('Se ha agregado correctamente el tipo de solicitud', 'Cerrar', { duration: 3000 });
        },
        complete: () => {
          window.location.reload();
        },
        error: (err: any) => {
          this.loading.close();
          this.snack.open(err.error.message, 'Cerrar', { duration: 3000 });
        }
      });

    } else {
      let payload = {
        cat_application_type_id: this.form_request.value.cat_application_type_id,
        cat_department_id: this.form_request.value.cat_department_id,
        credentials: {
          certificate_signing:
          {
            cuts: this.form_request.value.cuts,
            name: this.form_request.value.name,
            department: this.form_request.value.department
          }
        },
      };

      this.loading = this.dialog.open(LoadingComponent, { maxWidth: "600px", disableClose: true });
      this.municipalityService.add_application(payload).subscribe({
        next: (response: any) => {
          this.loading.close();
          this.snack.open('Se ha agregado correctamente el tipo de solicitud', 'Cerrar', { duration: 3000 });
        },
        complete: () => {
          window.location.reload();
        },
        error: (err: any) => {
          this.loading.close();
          this.snack.open(err.error.message, 'Cerrar', { duration: 3000 });
        }
      });
    }
  }

  updateApp() {
    if (this.form_request.value.cat_application_type_id == 5) {
      let payload = {
        id: this.idApp,
        data: {
          cat_application_type_id: this.form_request.value.cat_application_type_id,
          cat_department_id: this.form_request.value.cat_department_id,
          credentials: {
            certificate_signing:
            {
              cuts: this.form_request.value.cuts,
              name: this.form_request.value.name,
              department: this.form_request.value.department
            },
            cvc_signing:
            {
              cuts: this.form_request_cvc.value.cuts_cvc,
              name: this.form_request_cvc.value.name_cvc,
              department: this.form_request_cvc.value.department_cvc
            },
            cip_cam_signing:
            {
              cuts: this.form_request_cipcam.value.cuts_cipcam,
              name: this.form_request_cipcam.value.name_cipcam,
              department: this.form_request_cipcam.value.department_cipcam
            }
          },
        }
      };
      this.loading = this.dialog.open(LoadingComponent, { maxWidth: "600px", disableClose: true });
      this.municipalityService.update_application(payload).subscribe({
        next: (response: any) => {
          this.loading.close();
          this.snack.open('Se ha actualizado la solicitud', 'Cerrar', { duration: 1000 });
        },
        complete: () => {
          window.location.reload();
        },
        error: (err) => {
          this.loading.close();
          this.snack.open(err.error.message, 'Cerrar', { duration: 3000 });
        }
      });
    } else {
      let payload = {
        id: this.idApp,
        data: {
          cat_application_type_id: this.form_request.value.cat_application_type_id,
          cat_department_id: this.form_request.value.cat_department_id,
          credentials: {
            certificate_signing: {
              cuts: this.form_request.value.cuts,
              name: this.form_request.value.name,
              department: this.form_request.value.department
            }
          },
        }
      };
      this.loading = this.dialog.open(LoadingComponent, { maxWidth: "600px", disableClose: true });
      this.municipalityService.update_application(payload).subscribe({
        next: (response: any) => {
          this.loading.close();
          this.snack.open('Se ha actualizado la solicitud', 'Cerrar', { duration: 1000 });
        },
        complete: () => {
          window.location.reload();
        },
        error: (err) => {
          this.loading.close();
          this.snack.open(err.error.message, 'Cerrar', { duration: 3000 });
        }
      });
    }
  }

 

  getCatalogApp() {
    let req_params = {
      catalog_name: 'cat_application_type',
      paginator: {
        page: 1,
        limit: 10
      },
      order: ["name", "DESC"]
    };
    this.municipalityService.get_cat_app(req_params).subscribe({
      next: (response: any) => {
        this.cat_app = response.data.data;
        let req_paramsD = {
          catalog_name: 'cat_department',
          search: '',
          paginator: {
            page: 1,
            limit: 10
          },
          order: ["name", "DESC"]
        };
        this.municipalityService.get_cat_department(req_paramsD).subscribe({
          next: (response: any) => {
            this.cat_dep = response.data.data;

          },          
          error: (err) => {
            
          }
        });
      },
      
      error: (err) => {
        
      }
    });
  }

  getInfoApp(id_application: any) {
    let req_params = {
      department_application_type_id: id_application
    }
    this.loading = this.dialog.open(LoadingComponent, { maxWidth: "600px", disableClose: true });
    this.municipalityService.get_info_application(req_params).subscribe({
      next: (response: any) => {
        this.loading.close();
        this.form_request.get('cat_department_id')?.setValue(response.data.Cat_department.id);
        this.form_request.get('cat_application_type_id')?.setValue(response.data.Cat_application_type.id);
        this.form_request.get('name')?.setValue(response.data.credentials.certificate_signing.name);
        this.form_request.get('cuts')?.setValue(response.data.credentials.certificate_signing.cuts);
        this.form_request.get('department')?.setValue(response.data.credentials.certificate_signing.department);
        if (response.data.Cat_application_type.id == 5) {
          this.form_request_cvc.get('name_cvc')?.setValue(response.data.credentials.cvc_signing.name);
          this.form_request_cvc.get('cuts_cvc')?.setValue(response.data.credentials.cvc_signing.cuts);
          this.form_request_cvc.get('department_cvc')?.setValue(response.data.credentials.cvc_signing.department);
          this.form_request_cipcam.get('name_cipcam')?.setValue(response.data.credentials.cip_cam_signing.name);
          this.form_request_cipcam.get('cuts_cipcam')?.setValue(response.data.credentials.cip_cam_signing.cuts);
          this.form_request_cipcam.get('department_cipcam')?.setValue(response.data.credentials.cip_cam_signing.department);
        }
      },
      complete: () => {
      },
      error: (err: any) => {
        this.loading.close();
        this.snack.open(err.error.message, 'Cerrar', { duration: 3000 });
      }
    });
  }

  toggleSeccion() {
    this.mostrarSeccion = this.form_request.get('cat_application_type_id')?.value === 5;
  }
}

