<div class="platform-admin-container" [ngClass]="options.case">
    <div class="title-container">
        <span class="text-title">Dashboard</span>
        <div class="filters-container">
            <mat-form-field>
                <mat-label>Municipio</mat-label>
                <mat-select [(ngModel)]="option" name="food" multiple>
                  <mat-option *ngFor="let municipality of municipalities" [value]="municipality.id" class="municipality-opcion">
                    {{municipality.name}}
                  </mat-option>
                </mat-select>                
            </mat-form-field>
            <mat-form-field id="calendar">
                <mat-label>Introduce un rango de fechas</mat-label>
                <mat-date-range-input [formGroup]="rangeForm" [rangePicker]="picker"  class="calendar-range">
                  <input matStartDate formControlName="start" placeholder="Inicio">
                  <input matEndDate formControlName="end" placeholder="Fin">
                </mat-date-range-input>                 
                <mat-datepicker-toggle touchUi matIconSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>
            <button type="button" (click)="updateData()" [disabled]="rangeForm.invalid">Buscar</button>
        </div>         
    </div>
    <div class="data-container">      
        <div class="cards-container">
            <span class="card-title">Datos generales</span>
            <div class="cards-content">
              <ng-container *ngFor="let card of cardData, let index">
                <app-info-card [cardData]="card"></app-info-card>
              </ng-container>            
            </div>
            
            
        </div>
        <div class="chart-container">
          <span class="card-title">Certificados</span> 
          <ng-container *ngIf="chartData != undefined && (chartData.application_per_type_label.length > 0); else noData">
            <app-pie [chartData]="chartData"></app-pie>
          </ng-container>           
          <ng-template #noData>
            <div class="nodata-container">
              <span>No se encontró información</span>
            </div> 
          </ng-template>
        </div>        
    </div>
    <div class="table-container">
      <div class="table-search-container">
        <label class="search-title">Notarios</label>
        <div class="action-container" *ngIf="dataSource && dataSource.data.length > 0">
          <mat-form-field appearance="outline">
            <input matInput [formControl]="searchControl" placeholder="Búsqueda" >
            <mat-icon matSuffix>search</mat-icon>
          </mat-form-field>
        </div>
      </div>
      <ng-container *ngIf="dataSource && dataSource.data.length > 0; else noTableData">
        <table mat-table [dataSource]="dataSource" >        
          <ng-container matColumnDef="notary_number">
            <th mat-header-cell *matHeaderCellDef>No. Notario</th>
            <td mat-cell *matCellDef="let element">
              <div>                   
                  <span> {{ element.notary_number }} </span>
              </div>
            </td>
          </ng-container>           
          <ng-container matColumnDef="notary_name">
            <th mat-header-cell *matHeaderCellDef>Notario</th>
            <td mat-cell *matCellDef="let element">{{element.notary_name }}</td>
          </ng-container>
          <ng-container matColumnDef="cam">
            <th mat-header-cell *matHeaderCellDef>CAM</th>
            <td mat-cell *matCellDef="let element">{{element.cat_application_type.CAM != null ? element.cat_application_type.CAM : 0 }}</td>
          </ng-container>
          
          <ng-container matColumnDef="cip">
            <th mat-header-cell *matHeaderCellDef>CIP</th>
            <td mat-cell *matCellDef="let element">{{element.cat_application_type.CIP != null ? element.cat_application_type.CIP : 0 }}</td>
          </ng-container>
  
          <ng-container matColumnDef="cvc">
            <th mat-header-cell *matHeaderCellDef>CVC</th>
            <td mat-cell *matCellDef="let element">{{element.cat_application_type.CVC != null ? element.cat_application_type.CVC : 0 }}</td>
          </ng-container>
  
          <ng-container matColumnDef="ctd">
            <th mat-header-cell *matHeaderCellDef>CTD</th>
            <td mat-cell *matCellDef="let element">{{element.cat_application_type.CTD != null ? element.cat_application_type.CTD : 0 }}</td>
          </ng-container>
  
          <ng-container matColumnDef="ct">
            <th mat-header-cell *matHeaderCellDef>CT</th>
            <td mat-cell *matCellDef="let element">{{element.cat_application_type.CT != null ? element.cat_application_type.CT : 0 }}</td>
          </ng-container>
  
          <tr mat-header-row *matHeaderRowDef="displayedColumns sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>            
        </table>
      </ng-container>
      <ng-template #noTableData>
        <div class="nodata-container">
          <span>No se encontró información</span>
        </div>        
      </ng-template>
    </div>
</div>
