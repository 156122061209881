import { Breakpoints } from '@angular/cdk/layout';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MunicipalityService } from 'src/app/services/api/municipality.service';
import { LayoutService } from 'src/app/services/layout.service';
import { LoadingComponent } from '../../../dialogs/loading/loading.component';
import { MatDialog } from '@angular/material/dialog';

const INPUT_CASES = {
  [Breakpoints.XSmall]: 'smartphone',
  [Breakpoints.Small]: 'tablet',
  [Breakpoints.Medium]: 'desktop',
  [Breakpoints.Large]: 'desktop',
  [Breakpoints.XLarge]: 'desktop',
};

@Component({
  selector: 'app-public-servants-permissions',
  templateUrl: './public-servants-permissions.component.html',
  styleUrls: ['./public-servants-permissions.component.sass']
})

export class PublicServantsPermissionsComponent implements OnInit, OnDestroy{
  
  public options: any = { case: 'desktop' }  
  form!: FormGroup;  
  private loading!: any;
  @Input() userData: any;  
  
  public controls: string = '000000';
  public CIP: boolean[] = [false, false, false, false];
  public CAM: boolean[] = [false, false, false, false];
  public CVC: boolean[] = [false, false, false, false];
  public CTD: boolean[] = [false, false, false, false];
  public CT: boolean[] = [false, false, false, false, false];  

  constructor(
    private snack: MatSnackBar,
    private dialog: MatDialog,
    private layoutService: LayoutService,
    private municipalityService: MunicipalityService,
     
    ) { }
  
  ngOnInit(): void {    
    this.setLayout();    
    this.getInfoUser(this.userData.idMun);
    this.setPermissions();
  } 

  getInfoUser(id_municipality: any) {
    let req_params = {
      user_id: id_municipality
    }
    this.loading = this.dialog.open(LoadingComponent, { maxWidth: "600px", disableClose: true });
    this.municipalityService.get_info_public_server(req_params).subscribe({
      next: (response: any) => {
        this.loading.close();
        if (response.data != null) {
          this.controls = response.data.permissions;          
          this.setPermissions();          
        }
      },
      complete: () => {
        this.loading.close();
      },
      error: () => {
        this.loading.close();
        this.snack.open('Se ha producido un error al intentar recuperar la información del servidor público. Por favor, inténtelo de nuevo más tarde.', 'Cerrar', { duration: 3500 });
      }
    });
  } 

  ngOnDestroy(): void {
    this.layoutService.destroyed.next();
    this.layoutService.destroyed.complete();
  }

  setLayout(){
    this.layoutService.breakPoint.subscribe((breakPoint: string) => {
      this.options.case = INPUT_CASES[breakPoint];      
    });
  } 

  readInputData(start: number, end: number): string {
    const corte = this.controls.substring(start, end);    
    const binario = this.invertBinaryString((parseInt(corte, 16)).toString(2));   
    return binario;    
  }

  setPermissions() {  
    const mappings = [
      { target: this.CT, start: 0, end: 2 },
      { target: this.CTD, start: 2, end: 3 },
      { target: this.CVC, start: 3, end: 4 },
      { target: this.CAM, start: 4, end: 5 },
      { target: this.CIP, start: 5, end: 6 }
    ];  
    mappings.forEach(mapping => {
      const inputData = this.readInputData(mapping.start, mapping.end);  
      for (let i = 0; i < mapping.target.length && i < inputData.length; i++) {
        mapping.target[i] = inputData[i] === '1';
      }
    });
  }
  
  updatePermissions() {
    const ct = this.formatOutputData(this.CT).padStart(2, '0');
    const outputData = [
      ct,
      this.formatOutputData(this.CTD),
      this.formatOutputData(this.CVC),
      this.formatOutputData(this.CAM),
      this.formatOutputData(this.CIP)
    ].join('');  
    
    const data = {
      user_id: this.userData.idMun,
      user_data: {
        permissions: outputData
      }
    };

    this.loading = this.dialog.open(LoadingComponent, { maxWidth: "600px", disableClose: true });    
    this.municipalityService.update_admin(data).subscribe({
      next: (response: any) => {
        this.loading.close();
        if(response.ok){
          this.snack.open('Los permisos se han actualizado correctamente.', 'Cerrar', { duration: 1000 });
          this.getInfoUser(this.userData.idMun,);
        }else{
          this.snack.open('Ocurrió un error al intentar actualizar los permisos. Por favor, inténtelo de nuevo más tarde.', 'Cerrar', { duration: 3500 });  
        }       
      },
      complete: () => {
        this.loading.close();
      },
      error: () => {
        this.loading.close();
        this.snack.open('Ocurrió un error al intentar actualizar los permisos. Por favor, inténtelo de nuevo más tarde', 'Cerrar', { duration: 3500 });
      }
    });   
  }

  formatOutputData(data: any): string {
    return this.convertBinaryToHex(this.reverseArray(this.convertTo01(data)));
  }

  convertTo01(array: boolean[]): number[] {
    return array.map(value => value ? 1 : 0);
  }

  reverseArray(array: number[]): number[] {
    return array.reverse();
  }
  
  convertBinaryToHex(array: number[]): string {
    const binaryString = array.join('');
    const decimal = parseInt(binaryString, 2);
    return decimal.toString(16).toUpperCase();
  }

  invertBinaryString(input: string): string {
    const array = input.split('');
    const reversedArray = array.reverse();
    const invertedString = reversedArray.join('');
    return invertedString;
  }
}
