import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { getAuth, signInWithEmailAndPassword, sendPasswordResetEmail, sendEmailVerification, signOut, User, onAuthStateChanged, createUserWithEmailAndPassword } from "firebase/auth";
import { Router } from '@angular/router';
import { AuthService } from '../api/auth.service';
import { CryptoService } from '../api/crypto.service';
import { FirebaseStorage, getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { Observable } from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class AuthenticationService extends ConfigService {

    private _auth = getAuth(this.app);
    private _user!: User;
    private _token!: string;
    private storage: FirebaseStorage = getStorage(this.app);


    constructor(
        private router: Router,
        private cService: CryptoService
    ) {
        super();
        let encryptedString = localStorage.getItem('token');
        this._auth.languageCode = 'es';
        onAuthStateChanged(this._auth, (user: any) => {
            if (user) {
                this._user = user;
                user.getIdToken().then((token: any) => {
                    // TODO: encriptar el token
                    this._token = token;
                    localStorage.setItem('token', token);
                });
            }
        });
        if (encryptedString) {
            // TODO: desencriptar el token
            this._token = encryptedString;
        }
    }

    get auth() {
        return this._auth;
    }

    get token() {
        return this._token;
    }
    // TODO: añadir tipo(clase/interfaz) de payload
    signInWithEmailAndPassword(payload: any) {
        return signInWithEmailAndPassword(this._auth, payload.email, payload.password);
    }
    // TODO: añadir tipo(clase/interfaz) de payload
    sendPasswordResetEmail(payload: any) {
        return sendPasswordResetEmail(this._auth, payload.email);
    }
    // TODO: añadir tipo(clase/interfaz) de payload
    sendEmailVerification(payload: any) {
        return sendEmailVerification(payload.user);
    }

    createUserWithEmailAndPassword(payload: any) {
        return createUserWithEmailAndPassword(this._auth, payload.email, payload.password);
    }

    refreshToken() {
        this._user.getIdToken(true).then((token: any) => {
            this._token = token;
            location.reload();
        });
    }

    signOut() {
        localStorage.clear();
        signOut(this._auth);
        this.router.navigate(['/public/auth/login']);
        location.reload();
    }

    getAuthToken() {
        const token = localStorage.getItem('token_web');
        if (token) {
            return true;
        } else {
            this.router.navigateByUrl('/public/auth/login');
            return false;
        }
    }

    get_admin_info() {
        const user = JSON.parse(this.cService.decrypt(localStorage.getItem('user')!));
        switch (user.Cat_rol.id.toString()) {
            case '1':
                return true;
                break;
            case '2':
                this.router.navigateByUrl('/private/municipio');
                break;
            case '3':
                this.router.navigateByUrl('/private/servidor-publico');
                break;
            default:
                this.signOut();
                break;
        }
        return false;
    }

    get_municipality_info() {
        const user = JSON.parse(this.cService.decrypt(localStorage.getItem('user')!));
        switch (user.Cat_rol.id.toString()) {
            case '1':
                this.router.navigateByUrl('/private/administrador');
                break;
            case '2':
                return true;
                break;
            case '3':
                this.router.navigateByUrl('/private/servidor-publico');
                break;
            default:
                this.signOut();
                break;
        }
        return false;
    }

    get_sPublico_info() {
        const user = JSON.parse(this.cService.decrypt(localStorage.getItem('user')!));
        switch (user.Cat_rol.id.toString()) {
            case '1':
                this.router.navigateByUrl('/private/administrador');
                break;
            case '2':
                this.router.navigateByUrl('/private/municipio');
                break;
            case '3':
                return true;
                break;
            default:
                this.signOut();
                break;
        }
        return false;
    }

    upload_file(file: File, reference: string, id: number, name: string): Observable<{ finished: boolean, data: string, id: number, name: string }> {
        const file_reference = ref(this.storage, reference);
        const task = uploadBytesResumable(file_reference, file);
        return new Observable((subscriber) => {
            task.on(
                'state_changed',
                (snapshot) => {
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    // subscriber.next({ finished: false, data: `${progress}`, id, name });
                },
                (err) => { subscriber.error(err); },
                () => {
                    getDownloadURL(task.snapshot.ref).then((url) => {
                        subscriber.next({ finished: true, data: `${url}`, id, name });
                        subscriber.complete();
                    });
                }
            );
        });
    }
}
