import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { FilesDatum } from 'src/app/models/application/details';
import { MenuService } from 'src/app/services/menu.service';
import { environment } from 'src/environments/environment';
declare var AdobeDC: any;

@Component({
  selector: 'app-file',
  templateUrl: './file.component.html',
  styleUrls: ['./file.component.sass']
})
export class FileComponent implements AfterViewInit, OnDestroy {

  private adobeDCView: any;
  private _subscription?: Subscription;
  private adobeKey: string = environment.adobeKey;
  private _eventOptions = {
    listenOn: [
      "ANNOTATION_ADDED",
      "ANNOTATION_UPDATED",
      "ANNOTATION_DELETED"
    ]
  }

  constructor(
    private _menuService: MenuService
  ) { }

  ngAfterViewInit(): void {
    this._subscription = this._menuService.fileObserv.subscribe((file: FilesDatum) => this.getPdf());
  }

  ngOnDestroy(): void {
    delete this.adobeDCView;
    this._subscription?.unsubscribe();
    this._menuService.endFile();
  }

  getPdf() {
    if (this._menuService.selectedFile) {
      delete this.adobeDCView;
      this.adobeDCView = new AdobeDC.View({ clientId: this.adobeKey, divId: "adobe-dc-view", locale: "es-ES" });
      var previewfile = this.adobeDCView.previewFile({
        content: { location: { url: this._menuService.selectedFile.url } },
        metaData: { fileName: this._menuService.selectedFile.name, id: this._menuService.selectedFile.uid }
      }, {
        enableAnnotationAPIs: true,
        includePDFAnnotations: true,
        defaultViewMode: "FIT_WIDTH"
      });

      var annotations: any[] = JSON.parse(this._menuService.annotations ?? "[]");

      previewfile.then((adobeViewer: any) => {
        adobeViewer.getAnnotationManager().then((annotationManager: any) => {
          if (annotations.length != 0) annotationManager.addAnnotationsInPDF(annotations).then(() => { annotationManager.unselectAnnotation(); });
          annotationManager.registerEventListener((event: any) => {
            annotationManager.getAnnotations().then((data: any) => this._menuService.annotations = JSON.stringify(data));
          }, this._eventOptions);
        });
      });
    }
  }

}
