import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private url: string = environment.urlApi;


  constructor(
    private http: HttpClient
  ) { }

  list_all_by(req_params: any){
    const scope = `${this.url}user/list_all_by`;
    return this.http.post(`${scope}`, req_params)
  }

}
