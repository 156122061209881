import { Breakpoints } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Options } from 'src/app/models/services/layout/options';
import { LayoutService } from 'src/app/services/layout.service';

@Component({
  selector: 'app-base-auth',
  templateUrl: './base-auth.component.html',
  styleUrls: ['./base-auth.component.sass']
})
export class BaseAuthComponent implements OnDestroy, OnInit{
  public options: Options = {
    bckW: '100%',
    bckWHide: false,
    frmW: '100%',
    ribbonBottom: '80px',
    ribbonRight: '0px',
    containerLeftH: 'calc(100% - 60px)'
  }

  constructor(
    private layoutService: LayoutService
  ) { }

  ngOnInit(): void {
    this.setGridLayout();
  }

  setGridLayout(){
    this.layoutService.breakPoint.subscribe((breakPoint: string) => {
      switch (breakPoint) {
        case Breakpoints.XSmall:
        case Breakpoints.Small:
          this.options.bckWHide = true;
          this.options.bckW = '100%';
          this.options.frmW = '100%';
          this.options.ribbonRight = 'calc(50% - 120px)';
          this.options.ribbonBottom = '-52.5px';
          this.options.containerLeftH = '200px';
          break;
        case Breakpoints.Medium:
        case Breakpoints.Large:
        case Breakpoints.XLarge:
          this.options.bckWHide = false;
          this.options.bckW = '55%';
          this.options.frmW = '45%';
          this.options.ribbonBottom = '80px';
          this.options.ribbonRight = '-50px';
          this.options.containerLeftH = 'calc(100% - 60px)';
          break;
      }
    });
  }

  ngOnDestroy(): void {
    this.layoutService.destroyed.next();
    this.layoutService.destroyed.complete();
  }
}
