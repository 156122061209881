import { Breakpoints } from '@angular/cdk/layout';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoginOptions } from 'src/app/models/services/layout/login';
import { AuthenticationService } from 'src/app/services/firebase/authentication.service';
import { LayoutService } from 'src/app/services/layout.service';
import { LoadingComponent } from '../loading/loading.component';


@Component({
  selector: 'app-forgot-pass',
  templateUrl: './forgot-pass.component.html',
  styleUrls: ['./forgot-pass.component.sass']
})
export class ForgotPassComponent implements OnInit, OnDestroy{
  private loading!: any;

  form_pass: FormGroup = this.fb.group({
    email: [null, [Validators.required]]
  });

  public options: LoginOptions = {
    inputWidth: '500px'
  }

  constructor(
    private layoutService: LayoutService,
    private fb: FormBuilder,
    public dialog: MatDialogRef<ForgotPassComponent>,
    private dialogg: MatDialog,
    private authService: AuthenticationService,
    private snack: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any,

  ) { }

  ngOnInit(): void {
    this.layoutService.breakPoint.subscribe((breakPoint: string) => {
      switch (breakPoint) {
        case Breakpoints.XSmall:
        case Breakpoints.Small:
          this.options.inputWidth = '100%';
          break;
        case Breakpoints.Medium:
          this.options.inputWidth = '400px';
          break;
        case Breakpoints.Large:
        case Breakpoints.XLarge:
          this.options.inputWidth = '500px';
          break;
      }
    });
  }

  ngOnDestroy(): void {
    this.layoutService.destroyed.next();
    this.layoutService.destroyed.complete();
  }

  send() {
    this.loading = this.dialogg.open(LoadingComponent, { maxWidth: "600px", disableClose: true });
    this.authService.sendPasswordResetEmail(this.form_pass.value).then((response: any) => {
      this.snack.open('Se ha enviado un correo de recuperación', 'Cerrar', { duration: 1000 });
      this.loading.close();
      this.dialog.close(true);
    }).catch((err: any) => {
      this.snack.open('El correo no está registrado en GESTOCAR', 'Cerrar', { duration: 1000 });
    });
  }

  close(res: boolean){
    this.dialog.close(res);
  }


}
