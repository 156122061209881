import { Breakpoints } from '@angular/cdk/layout';
import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MunicipalityService } from 'src/app/services/api/municipality.service';
import { LayoutService } from 'src/app/services/layout.service';

const INPUT_CASES = {
  [Breakpoints.XSmall]: 'smartphone',
  [Breakpoints.Small]: 'tablet',
  [Breakpoints.Medium]: 'desktop',
  [Breakpoints.Large]: 'desktop',
  [Breakpoints.XLarge]: 'desktop',
};

@Component({
  selector: 'app-departments-menu',
  templateUrl: './departments-menu.component.html',
  styleUrls: ['./departments-menu.component.sass']
})
export class DepartmentsMenuComponent implements OnInit, OnDestroy, AfterViewInit {
  @Output() sendDataApp: EventEmitter<any> = new EventEmitter<any>();
  public options: any = { case: 'desktop' }
  public depData: any;
  public dataApp: any;
  public dataAppD: any;


  constructor(
    private layoutService: LayoutService,
    private municipalityService: MunicipalityService
  ) { }

  ngAfterViewInit(): void {
    // this.getMenu();
  }

  ngOnInit(): void {
    this.setLayout();
    this.getMenu();
  }

  ngOnDestroy(): void {
    this.layoutService.destroyed.next();
    this.layoutService.destroyed.complete();
  }

  setLayout() {
    this.layoutService.breakPoint.subscribe((breakPoint: string) => {
      this.options.case = INPUT_CASES[breakPoint];
    });
  }

  getMenu() {
    let req_params = {
      catalog_name: 'cat_department_applicationType',
      search: '',
      paginator: {
        page: 1,
        limit: 10
      },
      order: ["name", "DESC"]
    };

    this.municipalityService.get_cat_departmentAppType(req_params).subscribe({
      next: (response: any) => {
        this.depData = response.data.data;        
      },
      complete: () => {
      },
      error: (err: any) => {
        
      }
    });
  }

 

  addApplication() {
    this.dataApp = {
      idDep: '',
      new: true
    };
    this.sendDataApp.emit(this.dataApp);
  }

  details(idApp: any) {
    this.dataApp = {
      idDep: idApp,
      new: false
    };
    this.sendDataApp.emit(this.dataApp);
  }
}
