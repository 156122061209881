<div class="folios-table-container" [ngClass]="options.case">
    <mat-card>
      <div class="folios-table-search-container">      
        <label class="search-title">Consultar trámite</label>
        <mat-form-field appearance="outline">
          <input matInput placeholder="Búsqueda">
          <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>      
      </div>
  
      <div class="filters-table-container">
        <a mat-flat-button class="button-filter" *ngFor="let button of filterButtons; let i = index"
          [class.selected]="i === selectedButtonIndex" (click)="selectButton(i)">
          <div class="filter-status">
            <div class="status status-{{button.status}}"></div>
            <div class="text-icon">{{ button.label }} <span> {{ button.total }}</span></div>
          </div>
        </a>
      </div>
      <div class="body-table-container">
        <table mat-table [dataSource]="dataSource">        
            <ng-container matColumnDef="catastro">
              <th mat-header-cell *matHeaderCellDef>Catastro</th>
              <td mat-cell *matCellDef="let element">{{element.catastro}}</td>
            </ng-container>
            <ng-container matColumnDef="notario">
              <th mat-header-cell *matHeaderCellDef>No. Notario </th>
              <td mat-cell *matCellDef="let element">{{element.notario}}</td>
            </ng-container>
            <ng-container matColumnDef="certificado">
              <th mat-header-cell *matHeaderCellDef>Certificado</th>
              <td mat-cell *matCellDef="let element">{{element.certificado}}</td>
            </ng-container>
            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef>Status</th>
              <td mat-cell *matCellDef="let element">
                <div *ngIf="element.status == 'alta'" class="status-icon">
                  <div class="status status-red"></div>
                  <div class="text-icon">{{element.status}}</div>
                </div>
                <div *ngIf="element.status == 'media'" class="status-icon">
                  <div class="status status-yellow"></div>
                  <div class="text-icon">{{element.status}}</div>
                </div>
                <div *ngIf="element.status == 'baja'" class="status-icon">
                  <div class="status status-green"></div>
                  <div class="text-icon">{{element.status}}</div>
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="creacion">
              <th mat-header-cell *matHeaderCellDef>Creación</th>
              <td mat-cell *matCellDef="let element">{{element.creacion}}</td>
            </ng-container>
            <ng-container matColumnDef="acciones">
              <th mat-header-cell *matHeaderCellDef>Acciones</th>
              <td mat-cell *matCellDef="let element">
                <a mat-flat-button class="button-check" color="neutral-50">Consultar</a>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>            
        </table>
      </div> 
      <mat-paginator [pageSizeOptions]="[10, 15, 20]"
        showFirstLastButtons
        aria-label="Selecciona pagina">
      </mat-paginator>
    </mat-card>
  </div>