import { Breakpoints } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CryptoService } from 'src/app/services/api/crypto.service';
import { LayoutService } from 'src/app/services/layout.service';
import { LoadingComponent } from '../dialogs/loading/loading.component';
import { MatDialog } from '@angular/material/dialog';
import { MunicipalityService } from 'src/app/services/api/municipality.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ResponseGetMunicipality } from 'src/app/models/municipality/get_municipality_response.interface';

const INPUT_CASES = {
  [Breakpoints.XSmall]: 'smartphone',
  [Breakpoints.Small]: 'tablet',
  [Breakpoints.Medium]: 'desktop',
  [Breakpoints.Large]: 'desktop',
  [Breakpoints.XLarge]: 'desktop',
};

@Component({
  selector: 'app-municipality-details',
  templateUrl: './municipality-details.component.html',
  styleUrls: ['./municipality-details.component.sass']
})

export class MunicipalityDetailsComponent implements OnInit, OnDestroy {
  public options: any = { case: 'desktop' }
  public municipality_id: any;
  public encode: any;
  public userData: any;  
  public activeOption: string = 'municipality';
  private loading!: any;

  public municipalityData: any;
  public adminData: any;

  public checkedFlag: boolean = false;  

  constructor(
    private layoutService: LayoutService,
    private cService: CryptoService,
    private dialog: MatDialog,
    private municipalityService: MunicipalityService,
    private route: ActivatedRoute,
    private snack: MatSnackBar,
  ) {
    this.route.params.subscribe(params => {
      this.encode = params['data'];
      this.userData = JSON.parse(this.cService.decrypt(this.encode));     
    });
  }

  ngOnInit(): void {
    if(!this.userData.new){
      this.getMunicipalityInfo();
    }
    this.setLayout();
  }

  getMunicipalityInfo( idMun?: number) {
    let req_params = {
      municipality_id: idMun != undefined ? idMun : this.userData.idMun,
    }
    
    this.loading = this.dialog.open(LoadingComponent, { maxWidth: "600px", disableClose: true });
    this.municipalityService.get_info_admin(req_params).subscribe({
      next: (response: ResponseGetMunicipality) => {
        this.loading.close();
        if(response.ok){
          this.municipalityData = response.data.municipality_data;
          this.municipality_id = response.data.municipality_data.id
          this.adminData = response.data.user_data;          
        }else{
          this.snack.open('Se ha producido un error al intentar recuperar la información del municipio. Por favor, intente de nuevo más tarde.', 'Cerrar', { duration: 3000 });  
        }        
      },
      complete: () => {
        this.loading.close();
        this.checkedFlag = true;
      },
      error: (err:any) => {
        this.loading.close();        
        if(err.error.status == 400){          
          const msg  = err.error.msg.split('.');
          const shortMessage  = msg[0].trim();
          if(shortMessage  == 'Firebase ID token has expired'){
            this.snack.open('Su sesión ha caducado, por favor vuelva a iniciar sesión para continuar.', 'Cerrar', { duration: 3000 });
          }          
        }else{
          this.snack.open(err.error.message, 'Cerrar', { duration: 5000 });
        }        
      }
    });
  }

  ngOnDestroy(): void {
    this.layoutService.destroyed.next();
    this.layoutService.destroyed.complete();
  }

  setLayout() {
    this.layoutService.breakPoint.subscribe((breakPoint: string) => {
      this.options.case = INPUT_CASES[breakPoint];
    });
  }

  setActiveOption(option: string){
    this.activeOption = option;
  }
  
  updateData(data: any){
    this.activeOption = 'admin';
    const newData = JSON.parse(data);
    this.userData.idMun = newData.idMun;    
    this.getMunicipalityInfo(newData.idMun);
  }  
}
