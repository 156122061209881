import { Breakpoints } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { LayoutService } from 'src/app/services/layout.service';

const INPUT_CASES = {
  [Breakpoints.XSmall]: 'smartphone',
  [Breakpoints.Small]: 'tablet',
  [Breakpoints.Medium]: 'desktop',
  [Breakpoints.Large]: 'desktop',
  [Breakpoints.XLarge]: 'desktop',
};

@Component({
  selector: 'app-public-servants-menu',
  templateUrl: './public-servants-menu.component.html',
  styleUrls: ['./public-servants-menu.component.sass']
})
export class PublicServantsMenuComponent implements OnInit, OnDestroy{
  public options: any = { case: 'desktop' }
  
  constructor( private layoutService: LayoutService ){}  

  ngOnInit(): void {
    this.setLayout();
  }

  ngOnDestroy(): void {
    this.layoutService.destroyed.next();
    this.layoutService.destroyed.complete();
  }

  setLayout(){
    this.layoutService.breakPoint.subscribe((breakPoint: string) => {
      this.options.case = INPUT_CASES[breakPoint]; 
    });
  }  
}
