<div class="file-list-container" [ngClass]="options.case">
    <div class="action-container">
        <h1>Acciones</h1>
        <mat-divider></mat-divider>
        <div class="menu-container" *ngIf="isInReview() && menuService.selectedFile">
            <div class="menu-card menu-card-active" (click)="saveAnnotations()">
                <div class="menu-img-card">
                    <mat-icon>chat</mat-icon>
                </div>
                <div class="menu-description">
                    Guardar anotaciones
                </div>
            </div>
            <div class="menu-card" (click)="validate(true)">
                <div class="menu-img-card">
                    <mat-icon>check_circle</mat-icon>
                </div>
                <div class="menu-description">
                    Validar documento
                </div>
            </div>
            <div class="menu-card" (click)="validate(false)">
                <div class="menu-img-card">
                    <mat-icon>cancel</mat-icon>
                </div>
                <div class="menu-description">
                    rechazar documento
                </div>
            </div>
        </div>
        <div class="menu-container" *ngIf="isAllFilesValidated() && isInReview() && menuService.selectedFile">
            <div class="menu-card" *ngIf="isAllFilesSuccess()" (click)="approve()">
                <div class="menu-img-card">
                    <mat-icon>check_circle</mat-icon>
                </div>
                <div class="menu-description">
                    Validar trámite
                </div>
            </div>
            <div class="menu-card" *ngIf="isAnyFilesError()" (click)="fix()">
                <div class="menu-img-card">
                    <mat-icon>error</mat-icon>
                </div>
                <div class="menu-description">
                    Mandar a corrección
                </div>
            </div>
            <div class="menu-card" *ngIf="isAnyFilesError()"  (click)="cancel()">
                <div class="menu-img-card">
                    <mat-icon>cancel</mat-icon>
                </div>
                <div class="menu-description">
                    rechazar definitivamente
                </div>
            </div>
        </div>
        <div class="menu-container" *ngIf="isAllFilesValidated() && !isInReview()">
            No hay acciones disponibles
        </div>
    </div> 
    <div class="file-container">
        <h1>Documentos</h1>
        <mat-divider></mat-divider>
        <div class="file-list" *ngIf="files.length != 0">
            <div class="file-container" (click)="selectFile(file)" [ngClass]="{ 'file-container-active': menuService.selectedFile ? menuService.selectedFile.id == file.id : false }" *ngFor="let file of files">
                <div class="icon-container">
                    <div class="icon">
                        <mat-icon>folder</mat-icon>
                    </div>                    
                </div>
                <div class="description-container">
                    <div>
                        <span class="description-title">{{ file.name }}</span>
                        <span class="description-subtitle">{{ file.Cat_file.name }}</span>
                    </div>                    
                </div>
                <div class="status-container">
                    <div class="status" [ngClass]="{ 'green': file.validated == true, 'red': file.validated == false }"></div>
                </div>
            </div>
        </div>
    </div>
</div>
