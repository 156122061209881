import { Breakpoints } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoginOptions } from 'src/app/models/services/layout/login';
import { AuthenticationService } from 'src/app/services/firebase/authentication.service';
import { LayoutService } from 'src/app/services/layout.service';
import { ForgotPassComponent } from '../../../components/dialogs/forgot-pass/forgot-pass.component';
import { MatDialog } from '@angular/material/dialog';
import { LoadingComponent } from '../../../components/dialogs/loading/loading.component';
import { AuthService } from 'src/app/services/api/auth.service';
import { Router } from '@angular/router';
import { CryptoService } from 'src/app/services/api/crypto.service';
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.sass']
})
export class LoginComponent implements OnInit, OnDestroy {

  private loading!: any;
  

  form_login: FormGroup = this.fb.group({
    email: [null, [Validators.required, Validators.email]],
    password: [null, [Validators.required]]
  });

  public options: LoginOptions = {
    inputWidth: '500px'
  }

  constructor(
    private layoutService: LayoutService,
    private fb: FormBuilder,
    private authService: AuthenticationService,
    private dialog: MatDialog,
    private aService: AuthService,
    private router: Router,
    private cService: CryptoService,
    private snack: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.layoutService.breakPoint.subscribe((breakPoint: string) => {
      switch (breakPoint) {
        case Breakpoints.XSmall:
        case Breakpoints.Small:
          this.options.inputWidth = '100%';
          break;
        case Breakpoints.Medium:
          this.options.inputWidth = '400px';
          break;
        case Breakpoints.Large:
        case Breakpoints.XLarge:
          this.options.inputWidth = '500px';
          break;
      }
    });
  }

  ngOnDestroy(): void {
    this.layoutService.destroyed.next();
    this.layoutService.destroyed.complete();
  }

  login(){
    this.loading = this.dialog.open(LoadingComponent, { maxWidth: "600px", disableClose: true });
      this.authService.signInWithEmailAndPassword(this.form_login.value).then((response: any) => {
        let token = response.user.accessToken;
        let photoURL = response.user.photoURL;        
        localStorage.setItem('token_web', token);
        localStorage.setItem('photoURL', photoURL)
        if(token){
            this.aService.login().subscribe({
            next: (data: any) => {
              let enData = this.cService.encrypt(JSON.stringify(data.data));
              localStorage.setItem('user', enData);
              switch (data.data.cat_rol_id) {
                case 1:
                  this.router.navigateByUrl('/private/administrador');
                  break;
                case 2:
                  this.router.navigateByUrl('/private/municipio');
                  break;
                case 3:
                  this.router.navigateByUrl('/private/servidor-publico');
                  break;
                default:
                  this.router.navigateByUrl('/public/auth/login');
                  this.snack.open('El usuario no se encuentra registrado', 'Cerrar', { duration: 1000 });
                  break;
              }
            },
            complete: () => {
              this.loading.close();
            },
            error: (err) => {
              this.loading.close();
              this.snack.open('El usuario no se encuentra registrado', 'Cerrar', { duration: 1000 });
            }
          });
        }else{

        }

      }).catch((err) => {
        this.loading.close();
        this.snack.open('Accesos no válidos, usuario o contraseña incorrectos', 'Cerrar', { duration: 3000 });
      });
      
    
  }

  forgotPass(){
    
    const dialogRef = this.dialog.open(ForgotPassComponent, {
      width: '600px',
    });

    dialogRef.afterClosed().subscribe(result => {
      
    });
  }
}