import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  private url: string = environment.urlApi;


  constructor(
    private http: HttpClient
  ) { }


  get_metrics_admin_dashboard(req_params: any){
    const scope = `${this.url}statistics/get_metrics_admin_dashboard`;
    return this.http.post<any>(`${scope}`, req_params);
  }

  get_metrics_municipality_dashboard(req_params: any){
    const scope = `${this.url}statistics/get_metrics_municipality_dashboard`;
    return this.http.post<any>(`${scope}`, req_params);
  }

  get_applications_by_notary(req_params: any){
    const scope = `${this.url}statistics/get_applications_by_notary`;
    return this.http.post<any>(`${scope}`, req_params);
  }
}
