<mat-card [ngClass]="options.case">
    <mat-card-header>
      <mat-card-title-group>
        <div class="user-image-container">
          <label for="imageUpload">
            <input type='file' id="user-profile-image" accept=".png, .jpg, .jpeg" />
          </label>
          <img src="../../../../../assets/backgrounds/profile/profile_user.png" alt="">        
        </div>
        <div class="user-description">
          <mat-card-title *ngIf="userData">
            {{userData.name}}
          </mat-card-title>
          <mat-card-subtitle>Administrador</mat-card-subtitle>
        </div>
      </mat-card-title-group>
    </mat-card-header>
    <mat-card-content>
      <form [formGroup]="form_profile">
        <mat-divider></mat-divider>
        <div class="half-column">
          <div class="text-label">Nombre</div>
          <div>
            <mat-form-field appearance="outline">
              <input matInput #input formControlName="name">
              <mat-error *ngIf="form_profile.get('name')?.hasError('required')">
                El nombre es requerido
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="half-column">
          <div class="text-label">Correo</div>
          <div>
            <mat-form-field appearance="outline">
              <input matInput #input formControlName="email">
              <mat-error *ngIf="form_profile.get('email')?.hasError('required')">
                El correo es requerido
              </mat-error>
              <mat-error *ngIf="form_profile.get('email')?.hasError('email')">
                Introduzca un correo válido
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="half-column" *ngIf="!userData">
          <div class="text-label">Contraseña</div>
          <div>
            <mat-form-field appearance="outline">
              <input matInput #input formControlName="password" type="password" (keyup)="validatePasswords()">
              <mat-error *ngIf="form_profile.get('password')?.hasError('required')">
                La contraseña es requerido
              </mat-error>
              <mat-error *ngIf="form_profile.get('password')?.hasError('minlength')">
                La extensión minima son 6 caracteres
              </mat-error> 
            </mat-form-field>
          </div>
        </div>
        <div class="half-column" *ngIf="!userData">
          <div class="text-label">Confirmar contraseña</div>
          <div>
            <mat-form-field appearance="outline">
              <input matInput #input formControlName="confirm_password" type="password" (keyup)="validatePasswords()">
              <mat-error *ngIf="form_profile.get('confirm_password')?.hasError('required')">
                Confirmar contraseña es requerido
              </mat-error>
              <mat-error *ngIf=" form_profile.get('confirm_password')?.hasError('passwordMismatch')?.valueOf != null">
                Las contraseñas no coinciden.
              </mat-error>
            </mat-form-field>
          </div>
        </div>
  
        <div class="half-column" *ngIf="userData" style="margin-bottom: 20px;">
          <div class="text-label">Cambiar contraseña</div>
          <div class="button-content">
            <button (click)="sendEmail(userData.email)" *ngIf="userData"
              class="btn-restartPassword"
              matTooltip="Da clic para enviar un correo que permita restablecer tu contraseña">
              Enviar correo
            </button> 
          </div>
        </div>
        
        <div class="half-column">
          <div class="text-label">
            Teléfono
          </div>
          <div>
            <mat-form-field appearance="outline">
              <input matInput formControlName="phone_number" placeholder="+525511223344">
              <mat-error *ngIf="form_profile.get('phone_number')?.hasError('required')">
                El teléfono es requerido
              </mat-error>
              <mat-error *ngIf="form_profile.get('phone_number')?.hasError('pattern')">
                Ej. +525555555555.
              </mat-error> 
            </mat-form-field>
          </div>
        </div>
        <div class="button-content">
          <button (click)="saveUser(false)" *ngIf="this.userData != undefined">Guardar cambios</button>
          <button (click)="saveUser(true)" *ngIf="this.userData == undefined" [disabled]="form_profile.invalid">Guardar</button>
        </div>
      </form>
    </mat-card-content>
  </mat-card>