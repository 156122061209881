import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { MatDialog } from '@angular/material/dialog';
import { TokenExpiredDialogComponent } from '../core/components/dialogs/token-expired-dialog/token-expired-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class ErrorApiInterceptor implements HttpInterceptor {
  
  constructor(
    private dialog: MatDialog,
    private snack: MatSnackBar
  ) {}

  private flag: boolean = true;

  updateFlag(){
    this.flag = !this.flag;
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(      
      tap((event) => {
        if (event instanceof HttpResponse) {
          //Lógica adicional después de cada solicitud aquí          
        }
      }),
      catchError((error) => {
        if (error instanceof HttpErrorResponse && error.status === 400) {
          if(this.flag){
            this.updateFlag();
            let msg: string  = '';
            msg = error.error.msg.split('.');
            const shortMessage  = msg[0].trim();
            if(shortMessage  == 'Firebase ID token has expired'){
              const dialogRef = this.dialog.open(TokenExpiredDialogComponent, {
                width: '350px',
                disableClose: true,
              });  
              dialogRef.afterClosed().subscribe(result => {
                if(result){
                  this.updateFlag();
                  this.snack.open('La sesión ha sido reiniciada con exito', 'Cerrar', { duration: 4500 });
                  location.reload();
                }
                
              });
            }            
            if(error.error.msg.name.length > 1){
              this.snack.open('Estamos experimentando algunos problemas técnicos. Por favor, inténtelo de nuevo más tarde.', 'Cerrar', { duration: 5000 });  
              this.updateFlag();               
            }                    
          }          
        }
        return throwError(error);        
      })
    );
  }
}




