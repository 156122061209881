import { Component } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AuthenticationService } from 'src/app/services/firebase/authentication.service';
import { localStorageService } from 'src/app/services/local-storage.service';
import { LoadingComponent } from '../loading/loading.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-token-expired-dialog',
  templateUrl: './token-expired-dialog.component.html',
  styleUrls: ['./token-expired-dialog.component.sass']
})
export class TokenExpiredDialogComponent {
  constructor(
    private localStorage: localStorageService,
    private authService: AuthenticationService,
    private dialog: MatDialog,
    private snack: MatSnackBar,
    public dialogRef: MatDialogRef<TokenExpiredDialogComponent>
  ){

  }
  public password: string = '';
  private loading!: any;
  public attempts: number = 3;

  cancel(){
    this.authService.signOut();
  }

  refresh(){
    const user = this.localStorage.user;
    this.loading = this.dialog.open(LoadingComponent, { maxWidth: "600px", disableClose: true });
    let payload = {
      'email': user.email,
      'password': this.password,
    }
    
    this.authService.signInWithEmailAndPassword(payload).then((response: any) => {
      let token = response.user.accessToken;
      localStorage.setItem('token_web', token);
      this.loading.close();
      this.dialogRef.close(true);
     

    }).catch((err) => {
      this.loading.close(false);
      this.attempts = this.attempts -1;
      this.password = '';
      if(this.attempts == 0){
        this.authService.signOut();
        this.dialogRef.close(false);
      }       
    });
  }
}
