<div class="container">
    <h1 class="title" [style.width]="options.inputWidth">Recuperar contraseña</h1>
    <div class="subtitle" [style.width]="options.inputWidth">Ingresa el correo electrónico con el que te registraste</div>
    <form [formGroup]="form_pass" class="form" [style.width]="options.inputWidth">
        <p [style.width]="options.inputWidth">
            <mat-form-field appearance="fill" [style.width]="options.inputWidth">
                <mat-label>Correo</mat-label>
                <input matInput type="email" formControlName="email">
                <mat-icon matSuffix>alternate_email</mat-icon>
            </mat-form-field>
        </p>
    </form>
    <div class="footer" [style.width]="options.inputWidth">
        <a mat-flat-button color="neutral" (click)="close(true)">Cancelar</a>
        <a mat-flat-button color="accent" (click)="send()">Recuperar</a>
    </div>
</div>
