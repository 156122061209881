<div class="departments-container">
    <mat-card>
        <mat-card-header>
            <mat-card-title>
                <div>{{ data.title }}</div>
            </mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <form [formGroup]="form_department">
                <mat-form-field appearance="outline">
                    <input matInput formControlName="name">
                    <mat-error *ngIf="form_department.get('name')?.hasError('required') && 
                    form_department.get('name')!.touched" >El campo es obligatorio</mat-error>
                </mat-form-field>  
            </form>
        </mat-card-content>
        <mat-card-actions>
            <button type="button" color="accent" 
            (click)="createOrUpdateDepartment()" [disabled]="form_department.invalid">{{ data.button }}</button>
        </mat-card-actions>
    </mat-card>    
</div>
