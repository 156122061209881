import { BreakpointObserver, BreakpointState, Breakpoints } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, takeUntil } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {

  private _destroyed = new Subject<void>();
  private _breakPoints: string[] = [
    Breakpoints.XSmall,
    Breakpoints.Small,
    Breakpoints.Medium,
    Breakpoints.Large,
    Breakpoints.XLarge,
  ];
  private _observer: Observable<BreakpointState> = this.breakPointObserver.observe(this._breakPoints);
  private _breakPoint: BehaviorSubject<string> = new BehaviorSubject<string>('Unknown')

  constructor(
    private breakPointObserver: BreakpointObserver
  ) {
    this._observer.pipe(takeUntil(this._destroyed)).subscribe((result: BreakpointState) => {
      if (result.matches) {
        this._breakPoint.next(Object.keys(result.breakpoints).find((key: string) => result.breakpoints[key] === true) ?? 'Unknown');
      }
    });
  }

  get breakPoint() {
    return this._breakPoint.asObservable().pipe(takeUntil(this._destroyed));
  }

  get destroyed() {
    return this._destroyed;
  }
}
