import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MunicipalityService } from 'src/app/services/api/municipality.service';

@Component({
  selector: 'app-departments',
  templateUrl: './departments.component.html',
  styleUrls: ['./departments.component.sass']
})
export class DepartmentsComponent {
  form_department: FormGroup;
  constructor( 
    private municipalityService: MunicipalityService,
    private formBuilder: FormBuilder,
    public dialog: MatDialogRef<DepartmentsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any  ){
      let name = data?.element?.name != null ? data.element.name: '';
      this.form_department = this.formBuilder.group({
      name: [name , [Validators.required]]      
    });
  }

  ngOnInit(): void {
  }

  createOrUpdateDepartment(){
    if (this.data.statusF) {
      let req_params = { name:this.form_department.get('name')?.value };
      this.municipalityService.create_department(req_params).subscribe({
        next: (response: any) => {
          let data = response.data;          
          this.dialog.close(true);
        },
        complete: () => {
        },
        error: (err) => {
          
        }
      });
    } 
    else {
      let req_params = {
          department_id: this.data.element.id,
          data: {
              name: this.form_department.get('name')?.value,
              status: 1
          }
      }
      this.municipalityService.update_department(req_params).subscribe({
        next: (response: any) => {
          let data = response.data;          
          this.dialog.close(true);
        },
        complete: () => {
        },
        error: (err) => {
          
        }
      });

    }
  }
}
