<mat-card [ngClass]="options.case">
  <mat-card-header>
    <mat-card-title-group>
      <div class="municipality-image-container">
        <label for="imageUpload">
          <input (change)="changeInput($event)" type="file" id="municipality-profile-image" accept="image/*">
        </label>
        <!-- img por defecto -->
        <img *ngIf="(municipalityData == undefined || municipalityData?.logo == null) && logo == undefined" src="../../../../../assets/backgrounds/profile/municipality.png" alt="">
        <!-- img actualizada en registro nuevo municipio-->
        <img *ngIf="municipalityData && municipalityData?.logo != null" [src]="municipalityData.logo" alt="">
        <!-- img actualizada en un municipio ya existente -->
        <img *ngIf="logo && municipalityData == undefined" [src]="logo" alt="">
        
        <div class="municipality-image-icon" id="image-icon" matTooltip="Seleccionar logo del municipio"
          (click)="clickImage()">
          <mat-icon class="icon-pencil">edit</mat-icon>
        </div>
      </div>
      <div class="municipality-description">
        <mat-card-title *ngIf="municipalityData?.name">{{municipalityData.name}}</mat-card-title>
        <mat-card-subtitle>Municipio</mat-card-subtitle>
      </div>
    </mat-card-title-group>
  </mat-card-header>
  <mat-card-content>
    <form [formGroup]="form_municipality">
      <mat-divider></mat-divider>
      <div class="half-column">
        <div class="text-label">Nombre</div>
        <div>
          <mat-form-field appearance="outline">
            <input matInput #input formControlName="name">
            <mat-error *ngIf="form_municipality.get('name')?.hasError('required')">
              El campo Nombre es requerido
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="half-column">
        <div class="text-label">Prefijo</div>
        <div>
          <mat-form-field appearance="outline">
            <input matInput #input formControlName="prefix">
            <mat-error *ngIf="form_municipality.get('prefix')?.hasError('required')">
              El campo Prefijo es requerido
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="column">
        <div class="text-label">API Key</div>
        <div>
          <mat-form-field appearance="outline">
            <input matInput #input formControlName="api_key">
            <mat-error *ngIf="form_municipality.get('api_key')?.hasError('required')">
              El campo API Key es requerido
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <mat-divider></mat-divider>
      <h2>Gestión de cuenta</h2>
      <div class="half-column">
        <p>Activa o desactiva la cuenta en este apartado</p>
        <mat-slide-toggle formControlName="status" color="primary"> 
          <span>{{ form_municipality.get('status')?.value ? 'Desactivar' : 'Activar'}}</span>
        </mat-slide-toggle>
      </div>
      <div class="half-column">
        <div class="button-content" style="flex-direction: column;">
          <button type="button" (click)="chooseFile('input-file')" class="btn-sm">
            {{ municipalityData.letterhead == null ? 'Subir hoja membretada' : 'Actualizar hoja membretada' }}
          </button>
        </div>
        <p class="message"><span  #messageElement></span> </p>
        <input type="file" class="d-none" id="input-file" (change)="fileChange($event)" accept=".jpg, .png">
      </div>
      <div class="half-column" id="file" *ngIf="municipalityData && municipalityData.letterhead != null && !sheet_file">
        <div class="button-content" >
          <a [href]="municipalityData.letterhead" target="_blank">
            <button type="button" class="btn-sm">Ver hoja membretada</button>
          </a>          
        </div>
      </div>
      <div class="button-content">
        <button mat-flat-button (click)="save()" [disabled]="form_municipality.invalid">
          {{ municipalityData == undefined ? 'Guardar' : 'Guardar cambios' }}
        </button>
      </div>
    </form>
  </mat-card-content>
</mat-card>