<mat-card [ngClass]="options.case">
  <mat-card-header>
    <mat-card-title-group>
      <div class="permissions-description">
        <mat-card-title>Permisos de usuario</mat-card-title>
        <mat-card-subtitle>Para el administrador del municipio</mat-card-subtitle>
      </div>
    </mat-card-title-group>     
  </mat-card-header>    
  <mat-card-content>    
    <!-- table-form.component.html -->
    <div class="table-container">
      <table>
        <thead>
          <tr>
            <th></th>
            <th>
              <span class="request-title">CIP</span>
              <span class="request-description">Impuesto predial</span>
            </th>
            <th>
              <span class="request-title">CAM</span>
              <span class="request-description">Aportación de Mejoras</span>
            </th>
            <th>
              <span class="request-title">CVC</span>
              <span class="request-description">Clave y valor catastral</span>
            </th>
            <th>
              <span class="request-title">CTD</span>
              <span class="request-description">Traslado de dominio</span>
            </th>
            <th>
              <span class="request-title">CT</span>
              <span class="request-description">Triple</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <div class="permission-container">
                <mat-icon>add_box</mat-icon>
                <span>Revisión de trámite</span>
              </div>
            </td>
            <td><mat-checkbox color="primary" [(ngModel)]="CIP[0]"></mat-checkbox></td>
            <td><mat-checkbox color="primary" [(ngModel)]="CAM[0]"></mat-checkbox></td>
            <td><mat-checkbox color="primary" [(ngModel)]="CVC[0]"></mat-checkbox></td>
            <td><mat-checkbox color="primary" [(ngModel)]="CTD[0]"></mat-checkbox></td>
            <td><mat-checkbox color="primary" [(ngModel)]="CT[0]"></mat-checkbox></td>
          </tr>
          <tr>
            <td>
              <div class="permission-container">
                <mat-icon>task</mat-icon>
                <span>Revisión de adeudo</span>
              </div>
            </td>
            <td><mat-checkbox color="primary" [(ngModel)]="CIP[1]"></mat-checkbox></td>
            <td><mat-checkbox color="primary" [(ngModel)]="CAM[1]"></mat-checkbox></td>
            <td><mat-checkbox color="primary" [(ngModel)]="CVC[1]"></mat-checkbox></td>
            <td><mat-checkbox color="primary" [(ngModel)]="CTD[1]"></mat-checkbox></td>
            <td><mat-icon>remove</mat-icon></td>
          </tr>
          <tr>
            <td>
              <div class="permission-container">
                <mat-icon>fact_check</mat-icon>
                <span>Revisión de CVC</span>
              </div>
            </td>
            <td> <mat-icon>remove</mat-icon> </td>
            <td> <mat-icon>remove</mat-icon> </td>
            <td> <mat-icon>remove</mat-icon> </td>
            <td> <mat-icon>remove</mat-icon> </td>
            <td><mat-checkbox color="primary" [(ngModel)]="CT[1]"></mat-checkbox></td>
          </tr>
          <tr>
            <td>
              <div class="permission-container">
                <mat-icon>receipt</mat-icon>
                <span>Revisión de CIP/CAM</span>
              </div>
            </td>
            <td> <mat-icon>remove</mat-icon> </td>
            <td> <mat-icon>remove</mat-icon> </td>
            <td> <mat-icon>remove</mat-icon> </td>
            <td> <mat-icon>remove</mat-icon> </td>
            <td><mat-checkbox color="primary" [(ngModel)]="CT[2]"></mat-checkbox></td>
          </tr>
          <tr>
            <td>
              <div class="permission-container">
                <mat-icon>paid</mat-icon>
                <span>Pagos</span>
              </div>
            </td>
            <td><mat-checkbox color="primary" [(ngModel)]="CIP[2]"></mat-checkbox></td>
            <td><mat-checkbox color="primary" [(ngModel)]="CAM[2]"></mat-checkbox></td>
            <td><mat-checkbox color="primary" [(ngModel)]="CVC[2]"></mat-checkbox></td>
            <td><mat-checkbox color="primary" [(ngModel)]="CTD[2]"></mat-checkbox></td>
            <td><mat-checkbox color="primary" [(ngModel)]="CT[3]"></mat-checkbox></td>
          </tr>
          <tr>
            <td>
              <div class="permission-container">
                <mat-icon>check_circle</mat-icon>
                <span>Autorización</span>
              </div>
            </td>
            <td><mat-checkbox color="primary" [(ngModel)]="CIP[3]"></mat-checkbox></td>
            <td><mat-checkbox color="primary" [(ngModel)]="CAM[3]"></mat-checkbox></td>
            <td><mat-checkbox color="primary" [(ngModel)]="CVC[3]"></mat-checkbox></td>
            <td><mat-checkbox color="primary" [(ngModel)]="CTD[3]"></mat-checkbox></td>
            <td><mat-checkbox color="primary" [(ngModel)]="CT[4]"></mat-checkbox></td>
          </tr>
        </tbody>
      </table>
    </div>
  </mat-card-content> 
  <mat-card-actions>
    <button type="button" (click)="updatePermissions()">Guardar cambios</button>
  </mat-card-actions> 
</mat-card>