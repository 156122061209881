export enum ROL {
    ADMIN = 1,
    MPIO,
    SERV
}

export enum Tramites {
    CIP,
    CAM,
    CVC,
    CTD,
    CT
}

export enum PermissionsAll {
    REV_TRAMITES,
    REV_ADEUDOS,
    PAGOS,
    FIRMA
}

export enum PermissionsCT {
    REV_TRAMITES,
    REV_CVC,
    REV_CIP_CAM,
    PAGOS,
    FIRMA
}

export enum JSON_STRINGS {
    CIP = "CIP - No adeudo al impuesto predial",
    CAM = "CAM - Aportación de mejoras",
    CVC = "CVC - Clave y valor catastral",
    CTD = "ISAI - Impuesto sobre adquisición de inmuebles",
    CT = "CT - Certificación triple",
    REV_TRAMITES = "Revisión de trámites",
    REV_ADEUDOS = "Revisión de adeudos",
    PAGOS = "Pagos",
    FIRMA = "Autorización/Firma",
    REV_CVC = "Revisión CVC",
    REV_CIP_CAM = "Revisión CIP/CAM"
}

export enum LocalStorageErrors {
    UNDEFINED_USER
}

export enum ButtonActions {
    REVISAR,
    ADEUDOS,
    ORDEN,
    UPDATE_ORDEN,
    TO_SIGN_TRAMITE,
    PRECERTIFICATE,
    SIGN_TRAMITE,
    CARGAR_DATOS
}

export enum ChangeStatus {
    APPROVED = "approved",
    REJECTED = "rejected",
    CANCELED = "canceled"
}