import { Component, Input, OnInit } from '@angular/core';
import { Chart, ChartType } from 'chart.js';
import { backgroundColors } from 'src/app/mocks/backgroundColorData.mock';

@Component({
  selector: 'app-bar',
  templateUrl: './bar.component.html',
  styleUrls: ['./bar.component.sass']
})
export class BarComponent implements OnInit{
  
  @Input() chartData: any;
  
  ngOnInit(): void {
    this.chart = new Chart("chartBar", {
      type: 'bar' as ChartType,      
      data: {
        labels: this.chartData.applications_per_department_label,
        datasets: [{
          label: 'Certificados',
          data: this.chartData.applications_per_department_value,
          backgroundColor: backgroundColors,
          
        }]
      },
      options: {
        animations: {
          tension: {
            duration: 1000,
            easing: 'linear',
            from: 1,
            to: 0,
            loop: true
          }         
        },
        plugins: {
          legend: {
              display: true,
              labels: {
                  color: 'rgb(0, 0, 0)'
              }
          }         
        },
        layout: {
          padding: {
            left: 0,
            right: 0
          }
          
        }
      }
    });
  }
  public chart!: Chart;
}
