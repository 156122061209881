import { Breakpoints } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Subscription, debounceTime } from 'rxjs';
import { CryptoService } from 'src/app/services/api/crypto.service';
import { MunicipalityService } from 'src/app/services/api/municipality.service';
import { LayoutService } from 'src/app/services/layout.service';
import { LoadingComponent } from '../../dialogs/loading/loading.component';
import { ResponseCatalogGet } from 'src/app/models/catalog/catalog.interface';

const INPUT_CASES = {
  [Breakpoints.XSmall]: 'smartphone',
  [Breakpoints.Small]: 'tablet',
  [Breakpoints.Medium]: 'desktop',
  [Breakpoints.Large]: 'desktop',
  [Breakpoints.XLarge]: 'desktop',
};

export interface PeriodicElement {
  url: string,
  name: string,
  status: string;
  creacion: string;
}

@Component({
  selector: 'app-municipality-table',
  templateUrl: './municipality-table.component.html',
  styleUrls: ['./municipality-table.component.sass']
})

export class MunicipalityTableComponent implements OnInit, OnDestroy {
  options: any = { case: 'desktop' };
  displayedColumns: string[] = ['nombre', 'status', 'creacion', 'acciones'];
  dataSource!: MatTableDataSource<any>;
  selectedButtonIndex: number | null = null;
  originalData: any[] = [];
  
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  public count: number = 5;
  public page: number = 1;
  public cant: number = 5;  
  public search: string = '';
  public sort_api: string = '';
  public direction: string = 'DESC';
  
  searchControl = new FormControl();
  private subscription!: Subscription;

  private loading!: any;

  constructor(
    private layoutService: LayoutService,
    private municipalityService: MunicipalityService,
    private router: Router,
    private cService: CryptoService,
    private dialog: MatDialog,
    private snack: MatSnackBar,    
  ) { }

  ngOnInit(): void {
    this.setLayout();
    this.syncTable();
    this.subscription = this.searchControl.valueChanges
      .pipe(debounceTime(500))
      .subscribe((value: string) => {
        this.applyFilter(value);      
      }); 
  }

  setLayout() {
    this.layoutService.breakPoint.subscribe((breakPoint: string) => {
      this.options.case = INPUT_CASES[breakPoint];      
    });
  }

  ngOnDestroy(): void {
    this.layoutService.destroyed.next();
    this.layoutService.destroyed.complete();
  }

  selectButton(index: number) {
    this.selectedButtonIndex = index;
  }

  syncTable() {
    let req_params = {
      catalog_name: 'cat_municipality',
      search: this.search,
      paginator: {
        page: this.page,
        limit: this.cant
      },
      order: ["name", "DESC"]
    };
    this.loading = this.dialog.open(LoadingComponent, { maxWidth: "600px", disableClose: true });
    this.municipalityService.get_municipality(req_params).subscribe({
      next: (response: ResponseCatalogGet) => {
        this.loading.close();
        if(response.ok){
          let data = response.data.data;
          this.originalData = data;
          this.dataSource = new MatTableDataSource(data);
          this.count = response.data.count;
        }else{
          this.snack.open('Ha ocurrido un error al intentar recuperar la información de la tabla. Por favor, inténtelo de nuevo más tarde.', 'Cerrar', {duration: 3500});
        }        
      },
      error: (err) => {
        this.loading.close();        
      }
    });
  }

  newData() {
    const req = {
      idMun: '',
      new: true
    };
    let data = this.cService.encrypt(JSON.stringify(req));
    this.router.navigate([`/private/administrador/detalle`, data]);
  }

  applyFilter(search: string) {
    this.page = 1;    
    this.search = search;       
    this.syncTable();   
  }

  change_page(evt: PageEvent) {
    this.count = evt.length;
    this.page = evt.pageIndex + 1;
    this.cant = evt.pageSize;
    this.syncTable();
  }

  sendData(id_municipality: number) {
    let req = {
      idMun: id_municipality,
      new: false
    }
    let data = this.cService.encrypt(JSON.stringify(req));
    this.router.navigate([`/private/administrador/detalle`, data]);
  }
}
