<div [ngClass]="options.case" class="dashboard-container">
    <div class="banner-container">
        <span class="banner-title">¡Bienvenid@, {{user.name}}! </span>
        <span class="banner-subtitle">Ventanilla Electrónica Notarial Lite</span>        
    </div>
    <div class="cards-container">
        <ng-container *ngFor="let card of cards">
            <div class="card">
                <div class="card-image">
                    <img [src]="card.imageUrl" alt="imageCard" srcset="">
                </div>
                <span class="card-title">{{ card.title}}</span>
                <div class="card-description"> {{ card.description }}</div>
            </div>
        </ng-container>
    </div>
</div>