import { Breakpoints } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItems, RailMenu, Submenu } from 'src/app/models/services/menu';
import { ProcedureService } from 'src/app/services/api/procedure.service';
import { LayoutService } from 'src/app/services/layout.service';
import { localStorageService } from 'src/app/services/local-storage.service';
import { MenuService } from 'src/app/services/menu.service';
import { dbTramitesList } from 'src/app/shared/statics/menus';

const INPUT_CASES = {
  [Breakpoints.XSmall]: 'smartphone',
  [Breakpoints.Small]: 'tablet',
  [Breakpoints.Medium]: 'desktop',
  [Breakpoints.Large]: 'desktop',
  [Breakpoints.XLarge]: 'desktop',
};

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.sass']
})
export class BaseComponent implements OnInit, OnDestroy {

  public menuOpts: RailMenu[] = this.menuService.menuOpts;
  public options: any = { case: 'desktop' };
  public regularMenu: Submenu[] = [];
  public submenuName: string = "";
  public submenu?: Submenu;
  private _dbTramitesIDs: any = structuredClone(dbTramitesList);

  constructor(
    private layoutService: LayoutService,
    public menuService: MenuService,
    private router: Router,
    private proceduService: ProcedureService,
    public localService: localStorageService
  ) {
    const user = localService.user;
    if(user.cat_rol_id === 3){
      proceduService.get_type().subscribe({
        next: (type: any) => {
          type.data.data.map((item: any) => {
            this._dbTramitesIDs[item.Cat_application_type.id].push(item.id);
          });
        },


        error: (err) => { }
      });
     }
    
  }

  select(item: MenuItems) {
    this.menuService.selectedSubItem = item;
    this.menuService.open = false;
    this.router.navigateByUrl(item.url);    
  }

  changeRail(rail: RailMenu): void {
    this.menuService.selectedRailItem = rail;
    this.regularMenu = rail.submenu;    
  }

  changeSelection(sub: string): void {
    this.menuService.selectedSubmenu = sub;
    this.submenu = this.menuOpts[1].submenu.find((it: Submenu) => it.name === sub);
    if (this.submenu && this.submenu.id) this.menuService.department_applicationType_id = this._dbTramitesIDs[this.submenu.id];
  }

  ngOnInit(): void {    
    if (this.menuService.selectedRailItem) {
      if (this.menuService.selectedSubmenu && this.menuService.selectedRailItem.id === 7) {
        this.submenuName = this.menuService.selectedSubmenu;
        this.submenu = this.menuOpts[1].submenu.find((it: Submenu) => it.name === this.submenuName);
      } else {
        this.regularMenu = this.menuService.selectedRailItem.submenu;
      }
    }else{
      this.changeRail(this.menuOpts[0]);
      this.menuService.selectedSubItem = this.menuOpts[0].submenu[0].items[0];
    }
    this.setLayout();
  }

  ngOnDestroy(): void {
    this.layoutService.destroyed.next();
    this.layoutService.destroyed.complete();
  }

  setLayout() {
    this.layoutService.breakPoint.subscribe((breakPoint: string) => {
      this.options.case = INPUT_CASES[breakPoint];
    });
  }

}
