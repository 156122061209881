<div *ngIf="applicationElement">
    <button mat-stroked-button color="primary" [matMenuTriggerFor]="menu">Acciones</button>

    <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="action(adeudos)" *ngIf="[3].includes(applicationElement.Cat_application_status.id)">
            <mat-icon>receipt_long</mat-icon>
            <span>Adeudos</span>        
        </button>
        <button mat-menu-item (click)="action(cargar_datos)" *ngIf="[4, 5, 8].includes(applicationElement.Cat_application_status.id)">
            <mat-icon>edit_square</mat-icon>
            <span>Cargar datos</span>
        </button>
        <button mat-menu-item (click)="action(orden)" *ngIf="[6].includes(applicationElement.Cat_application_status.id)">
            <mat-icon>upload_file</mat-icon>
            <span>Cargar liquidación</span>
        </button>
        <button mat-menu-item (click)="action(update_orden)" *ngIf="[7].includes(applicationElement.Cat_application_status.id) && applicationElement.expiration_date && checkExpiration(applicationElement.expiration_date)">
            <mat-icon>update</mat-icon>
            <span>Actualizar liquidación</span>
        </button>
        <button mat-menu-item (click)="action(to_sign_tramite)" *ngIf="[8].includes(applicationElement.Cat_application_status.id) && applicationElement.its_paid">
            <mat-icon>check_box</mat-icon>
            <span>Conciliar pago</span>
        </button>
        <button mat-menu-item (click)="action(sign_tramite)" *ngIf="[9].includes(applicationElement.Cat_application_status.id)">
            <mat-icon>draw</mat-icon>
            <span>Firmar</span>
        </button>
        <button mat-menu-item (click)="action(precertificate)" *ngIf="[8, 9].includes(applicationElement.Cat_application_status.id) && applicationElement.its_paid">
            <mat-icon>description</mat-icon>
            <span>Ver precertificado</span>
        </button>
        <button mat-menu-item (click)="action(review)">
            <mat-icon *ngIf="applicationElement.Cat_application_status.id == 1">checklist</mat-icon>
            <mat-icon *ngIf="applicationElement.Cat_application_status.id != 1">inventory_2</mat-icon>
            <span>{{ applicationElement.Cat_application_status.id == 1 ? 'Revisar' : 'Expediente' }}</span>
        </button>
    </mat-menu>
</div>