import { Breakpoints } from '@angular/cdk/layout';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MunicipalityService } from 'src/app/services/api/municipality.service';
import { LayoutService } from 'src/app/services/layout.service';
import { LoadingComponent } from '../../dialogs/loading/loading.component';
import { passwordsMatch } from 'src/app/core/validators/passwordMatch.validator';
import { AuthenticationService } from 'src/app/services/firebase/authentication.service';

const INPUT_CASES = {
  [Breakpoints.XSmall]: 'smartphone',
  [Breakpoints.Small]: 'tablet',
  [Breakpoints.Medium]: 'desktop',
  [Breakpoints.Large]: 'desktop',
  [Breakpoints.XLarge]: 'desktop',
};

@Component({
  selector: 'app-municipality-user',
  templateUrl: './municipality-user.component.html',
  styleUrls: ['./municipality-user.component.sass']
})

export class MunicipalityUserComponent implements OnInit, OnDestroy{
  form_profile!: FormGroup;
  telephones: FormControl[] = [];
  public options: any = { case: 'desktop' }
  id_municipality: any;
  admin_info: any;
  public municipality_id: any;
  public new: any;
  public id_user: any;  
  private loading!: any;
  userData: any;

  @Input() encode: any;
  @Output() setUser: EventEmitter<string> = new EventEmitter();
  
  constructor(
    private formBuilder: FormBuilder,
    private snack: MatSnackBar,
    private layoutService: LayoutService,
    private municipalityService: MunicipalityService,    
    private dialog: MatDialog,    
    private authService: AuthenticationService,
    
  ) { 
    this.form_profile = this.formBuilder.group({
      name: [null, [Validators.required]],
      email: [null, [Validators.required, Validators.email]],
      password: [null, [Validators.required, Validators.minLength(6)]],
      confirm_password: [null, [Validators.required]],
      phone_number: [null, [Validators.required, Validators.pattern(/^\+52\d{10}$/)]],
      notification_email: [null, [Validators.required, Validators.email]],
      notification_phone_number: [null, [Validators.required, Validators.pattern(/^\+52\d{10}$/)]]
    },{validators: passwordsMatch});    
  }
  
  ngOnInit(): void {
    if (this.encode.new == true) {
      this.setLayout();
    } else {
      this.setLayout();
      this.updateForm();
      this.getInfoUser(this.encode.idMun);
    }    
  }

  validatePasswords(){
    if(this.form_profile.hasError('passwordMismatch')){
      this.form_profile?.get('confirm_password')?.setErrors({ 'passwordMismatch': true });
    }else{
      this.form_profile?.get('confirm_password')?.setErrors(null);
    }           
  }  

  ngOnDestroy(): void {
    this.layoutService.destroyed.next();
    this.layoutService.destroyed.complete(); 
    
  }

  updateForm(){
    this.form_profile.removeControl('password');
    this.form_profile.removeControl('confirm_password');    
  }

  setLayout() {
    this.layoutService.breakPoint.subscribe((breakPoint: string) => {
      this.options.case = INPUT_CASES[breakPoint];      
    });
  }

  addPhone() {
    if (this.telephones.length < 3) {
      this.telephones.push(new FormControl('', Validators.required));
    } else {
      this.snack.open('El máximo de números telefónicos es de 3', 'Cerrar', { duration: 1000 });
    }
  }

  saveUser() {
    const add = this.encode.new;   
    delete this.form_profile.value.confirm_password;
    this.loading = this.dialog.open(LoadingComponent, { maxWidth: "600px", disableClose: true });
    if (add == true) {
      this.municipalityService.add_admin(this.form_profile.value).subscribe({
        next: (response: any) => {
          this.loading.close();
          this.snack.open('El servidor público ha sido registrado correctamente.', 'Cerrar', { duration: 1000 });
          let data = {
            option: 'user-permissions',
            idMun: response.data.user_id,
            new: false
          }
          this.setUser.emit(JSON.stringify(data));          
        },
        complete: () => {
          this.loading.close();
        },
        error: () => {
          this.snack.open('Ocurrió un problema al intentar registrar el servidor público. Por favor, inténtelo de nuevo más tarde.', 'Cerrar', { duration: 1000 });
          this.loading.close();
        }
      });
    } else {
      let data = {
        user_id: this.encode.idMun,
        user_data: this.form_profile.value        
      }
      this.municipalityService.update_admin(data).subscribe({
        next: (response: any) => {
          this.loading.close();
          this.snack.open('El servidor público ha sido actualizado correctamente.', 'Cerrar', { duration: 1000 });
          
        },
        complete: () => {
          this.loading.close();
        },
        error: () => {
          this.loading.close();
          this.snack.open('Ocurrió un error al intentar actualizar al servidor público. Por favor, intente de nuevo más tarde.', 'Cerrar', { duration: 3500 });
        }
      });        
    }
  }

   getInfoUser(id_municipality: any) {
    let req_params = {
      user_id: id_municipality
    }
    this.loading = this.dialog.open(LoadingComponent, { maxWidth: "600px", disableClose: true });
    this.municipalityService.get_info_public_server(req_params).subscribe({
      next: (response: any) => {
        this.loading.close();
        if (response.data != null) {
          this.userData = response.data;
          this.form_profile.patchValue(response.data);
        } else {
          this.new = true;
        }
      },
      complete: () => {
        this.loading.close();
      },
      error: () => {
        this.loading.close();
        this.snack.open('', 'Cerrar', { duration: 3500 });
      }
    });
  }

  sendEmail() {
    let payload = {
      email: this.userData.email
    }
    this.loading = this.dialog.open(LoadingComponent, { maxWidth: "600px", disableClose: true });
    this.authService.sendPasswordResetEmail(payload).then((response: any) => {
      this.snack.open('Se ha enviado un correo de recuperación', 'Cerrar', { duration: 1000 });
      this.loading.close();
    }).catch((err: any) => {
      this.snack.open('El correo no está registrado', 'Cerrar', { duration: 1000 });
    });
  }
}
