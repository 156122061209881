import { Breakpoints } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { CryptoService } from 'src/app/services/api/crypto.service';
import { MunicipalityService } from 'src/app/services/api/municipality.service';
import { LayoutService } from 'src/app/services/layout.service';
import { DepartmentsComponent } from '../../dialogs/departments/departments.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoadingComponent } from '../../dialogs/loading/loading.component';
import { Subscription, debounceTime } from 'rxjs';
import { FormControl } from '@angular/forms';

const INPUT_CASES = {
  [Breakpoints.XSmall]: 'smartphone',
  [Breakpoints.Small]: 'tablet',
  [Breakpoints.Medium]: 'desktop',
  [Breakpoints.Large]: 'desktop',
  [Breakpoints.XLarge]: 'desktop',
};

@Component({
  selector: 'app-departments-table',
  templateUrl: './departments-table.component.html',
  styleUrls: ['./departments-table.component.sass']
})
export class DepartmentsTableComponent implements OnInit, OnDestroy{
  public options: any = { case: 'desktop' };
  displayedColumns: string[] = ['nombre', 'creacion', 'acciones'];
  dataSource!: MatTableDataSource<any>;
  selectedButtonIndex: number | null = null;

  public count: number = 5;
  public page: number = 1;
  public cant: number = 5;
  public filter: string = '';
  public search: string = '';
  public sort_api: string = '';
  public direction: string = 'DESC';

  searchControl = new FormControl();
  private subscription!: Subscription;
  private loading!: any;

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  constructor(
    private layoutService: LayoutService,
    private municipalityService: MunicipalityService,
    private dialog: MatDialog,
    private cService: CryptoService,
    private router: Router,
    private snack: MatSnackBar,
  ) { }
  

  ngOnInit(): void {
    this.setLayout();
    this.syncTable();
    this.subscription = this.searchControl.valueChanges
      .pipe(debounceTime(500))
      .subscribe((value: string) => {
        this.applyFilter(value);      
      });  
  }
  
  openDialog(title:string,button:string,statusF:boolean,element:any){
    const loading = this.dialog.open(LoadingComponent);
    const dialogRef = this.dialog.open(DepartmentsComponent, {      
      data: {
        title, 
        button,
        statusF,
        element      
      }, 
        width: '350px',
    });

    dialogRef.afterClosed().subscribe((result) => {
      loading.close();
      if (result) {
        this.syncTable();
        if(statusF){
          this.snack.open('El departamento ha sido registrado correctamente.', 'Cerrar', {duration: 3500});
        }else{
          this.snack.open('El departamento ha sido actualizado correctamente.', 'Cerrar', {duration: 3500});
        }
      } else {
        this.snack.open('Proceso cancelado.', 'Cerrar', { duration: 3500 });
      }
    });
  }

  setLayout(){
    this.layoutService.breakPoint.subscribe((breakPoint: string) => {
      this.options.case = INPUT_CASES[breakPoint];           
    });
  } 
  
  ngOnDestroy(): void {
    this.layoutService.destroyed.next();
    this.layoutService.destroyed.complete();
  }

  selectButton(index: number) {
    this.selectedButtonIndex = index;
  }

  syncTable() {
    let req_params = {
      catalog_name: 'cat_department',
      search: this.search,
      paginator: {
        page: this.page,
        limit: this.cant
    },
    order: ["name", "DESC"]
    };
    this.loading = this.dialog.open(LoadingComponent, { maxWidth: "600px", disableClose: true });
    this.municipalityService.get_cat_department(req_params).subscribe({
      next: (response: any) => {
        this.loading.close();
        let dataTable = response.data.data;        
        this.dataSource = new MatTableDataSource(dataTable);
        this.count = response.data.count;
      },
      complete: () => {
        this.loading.close();
      },
      error: (err) => {
        this.loading.close();
        this.snack.open('Ocurrió un error al intentar obtener la información de la tabla. Por favor, inténtelo de nuevo más tarde.', 'Cerrar', { duration: 3500 });        
      }
    });
  }

  change_page(evt: PageEvent) {
    this.count = evt.length;
    this.page = evt.pageIndex + 1;
    this.cant = evt.pageSize;
    this.syncTable();
  }

  applyFilter(search: string) {
    this.page = 1;    
    this.search = search;       
    this.syncTable();   
  }

  departmentData(idDepartment: any){
    let req = {
      idDep: idDepartment,
      new: false
    }
    let data = this.cService.encrypt(JSON.stringify(req));
    this.router.navigate([`/private/municipio/administrar-solicitudes`, data]);
  }
}
