import { Breakpoints } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { passwordsMatch } from 'src/app/core/validators/passwordMatch.validator';
import { MunicipalityService } from 'src/app/services/api/municipality.service';
import { AuthenticationService } from 'src/app/services/firebase/authentication.service';
import { LayoutService } from 'src/app/services/layout.service';
import { LoadingComponent } from '../../dialogs/loading/loading.component';
import { CryptoService } from 'src/app/services/api/crypto.service';
import { ResponseGetDetails } from 'src/app/models/user/user';

const INPUT_CASES = {
  [Breakpoints.XSmall]: 'smartphone',
  [Breakpoints.Small]: 'tablet',
  [Breakpoints.Medium]: 'desktop',
  [Breakpoints.Large]: 'desktop',
  [Breakpoints.XLarge]: 'desktop',
};

@Component({
  selector: 'app-form-profile',
  templateUrl: './form-profile.component.html',
  styleUrls: ['./form-profile.component.sass']
})
export class FormProfileComponent implements OnInit, OnDestroy {
  form_profile: FormGroup;
  telephones: FormControl[] = [];
  public options: any = { case: 'desktop' }
  id_municipality: any;
  admin_info: any;
  public municipality_id: any;
  public new: any;
  public id_user: any;
  private loading!: any;
  public confirm_pass: any;
  public haveData: any;

  public userData: any = {new: false};

  constructor(
    private formBuilder: FormBuilder,
    private snack: MatSnackBar,
    private layoutService: LayoutService,
    private authService: AuthenticationService,
    private municipalityService: MunicipalityService,
    private router: Router,
    private dialog: MatDialog,
    private cService: CryptoService,
  ) {
    this.form_profile = this.formBuilder.group({
      name: [null, [Validators.required]],
      email: [null, [Validators.required, Validators.email]],      
      phone_number: [null, [Validators.required, Validators.pattern(/^\+52\d{10}$/)]],
    },{validators: passwordsMatch});
  }

  ngOnInit(): void {
    let userDataLocal: any = localStorage.getItem('user') ?? '';
    userDataLocal = JSON.parse(this.cService.decrypt(userDataLocal));
    this.userData.idMun = userDataLocal.id;      
    this.getInfoUser(this.userData.idMun);
    this.setLayout();
  }

  ngOnDestroy(): void {
    this.layoutService.destroyed.next();
    this.layoutService.destroyed.complete();    
  }

  setLayout() {
    this.layoutService.breakPoint.subscribe((breakPoint: string) => {
      this.options.case = INPUT_CASES[breakPoint];
    });
  }

  addPhone() {
    if (this.telephones.length < 3) {
      this.telephones.push(new FormControl('', Validators.required));
    } else {
      this.snack.open('El máximo de números telefónicos es de 3', 'Cerrar', { duration: 1000 });
    }
  }

  saveUser() {
    this.form_profile.value.notification_email = this.form_profile.value.email;
    this.form_profile.value.notification_phone_number = this.form_profile.value.phone_number;
    this.form_profile.value.cat_municipality_id =  this.id_municipality;
    this.confirm_pass = this.form_profile.value.confirm_password;    

    let req_params = {
      user_id: this.id_user,
      user_data: this.form_profile.value
    }

    this.loading = this.dialog.open(LoadingComponent, { maxWidth: "600px", disableClose: true });
    this.municipalityService.update_admin(req_params).subscribe({
      next: (response: any) => {
        this.loading.close();
        this.snack.open('Se ha actualizado correctamente el Administrador de Municipio', 'Cerrar', { duration: 4500 });
        this.getInfoUser(this.userData.idMun);        
      },
      complete: () => {
        this.loading.close();
      },
      error: (err) => {
        this.loading.close();
        this.snack.open(err.error.message, 'Cerrar', { duration: 3000 });
      }
    });     
  }

  clickImage() {
    let click = document.getElementById('user-profile-image') as HTMLInputElement;
    click.click();
  }

  getInfoUser(id: any) {

    let req_params = {
      user_id: id
    }
    
    this.loading = this.dialog.open(LoadingComponent, { maxWidth: "600px", disableClose: true });
    this.municipalityService.get_user_details(req_params).subscribe({
      next: (response: ResponseGetDetails) => {
        this.loading.close();
        if(response.ok){
          this.id_municipality = response?.data?.Cat_municipality?.id;
          if (response?.data != null) {
            this.admin_info = response.data;          
            this.id_user = this.admin_info.id;
            delete this.admin_info.id;
            delete this.admin_info.status;
            this.admin_info.confirm_password = '';
            this.admin_info.password = '';
            this.form_profile.patchValue(this.admin_info);
          }else{
            this.userData.new = true;
          }
        }else{
          this.snack.open('Ocurrió un error inesperado al intentar recuperar la información del usuario. Por favor, inténtelo de nuevo más tarde.', 'Cerrar', { duration: 4500 });
        }
        
      },
      complete: () => {
        this.loading.close();
      },
      error: (err) => {
        this.loading.close();
        this.snack.open(err.error.message, 'Cerrar', { duration: 3000 });
      }
    });
  }

  sendEmail(email: any) {
    let payload = {
      email: email
    }
    this.loading = this.dialog.open(LoadingComponent, { maxWidth: "600px", disableClose: true });
    this.authService.sendPasswordResetEmail(payload).then((response: any) => {
      this.snack.open('Se ha enviado un correo de recuperación', 'Cerrar', { duration: 1000 });
      this.loading.close();
    }).catch((err: any) => {
      this.snack.open('El correo no está registrado', 'Cerrar', { duration: 1000 });
    });
  }

  validatePasswords(){
    if(this.form_profile.hasError('passwordMismatch')){
      this.form_profile?.get('confirm_password')?.setErrors({ 'passwordMismatch': true });
    }else{
      this.form_profile?.get('confirm_password')?.setErrors(null);
    }           
  }  
}
