<div class="banner-container" [ngClass]="options.case">
    <div class="banner-description">
        <p class="banner-title">¡Bienvenido!</p>
        <p class="banner-subtitle">Ventanilla Electrónica Notarial <span>Lite</span></p>
    </div>
    <div class="img-container" style="background-color: green;">
        <img src="../../../../../../assets/backgrounds/profile/image_profile.png">
    </div>
</div>

