import { Injectable } from '@angular/core';
import { JSON_STRINGS, PermissionsAll, PermissionsCT, ROL, Tramites } from '../shared/statics/constants';
import { adminOpts, dbStatusAll, dbStatusAllCT, dbTramites, mpioOpts, permissionsAll, permissionsCT, servOpts, tramtes } from '../shared/statics/menus';
import { localStorageService } from './local-storage.service';
import { RailMenu, Submenu } from '../models/services/menu';

@Injectable({
    providedIn: 'root'
})
export class MenuService extends localStorageService {

    private _tramtes: Tramites[] = tramtes;
    private _permissionsAll: PermissionsAll[] = permissionsAll;
    private _permissionsCT: PermissionsCT[] = permissionsCT;
    private _adminOpts: RailMenu[] = adminOpts;
    private _mpioOpts: RailMenu[] = mpioOpts;
    private _servOpts: RailMenu[] = servOpts;
    private _id: number = 70;

    constructor() {
        super();
    }

    get menuOpts() {
        switch (this.user.Cat_rol.id) {
            case ROL.ADMIN:
                return this._adminOpts;
            case ROL.MPIO:
                return this._mpioOpts;
            case ROL.SERV:
                this._setupServSubmenu();
                return this._servOpts;
            default:
                return [];
        }
    }

    private _setupServSubmenu() {
        let submenu: Submenu[] = [];
        let aux: any;
        this._tramtes.map((t: Tramites) => {
            switch (t) {
                case Tramites.CIP:
                    aux = this._getSubmenu(this._permissionsAll, this._getStringsAll, JSON_STRINGS.CIP, t);
                    break;
                case Tramites.CAM:
                    aux = this._getSubmenu(this._permissionsAll, this._getStringsAll, JSON_STRINGS.CAM, t);
                    break;
                case Tramites.CVC:
                    aux = this._getSubmenu(this._permissionsAll, this._getStringsAll, JSON_STRINGS.CVC, t);
                    break;
                case Tramites.CTD:
                    aux = this._getSubmenu(this._permissionsAll, this._getStringsAll, JSON_STRINGS.CTD, t);
                    break;
                case Tramites.CT:
                    aux = this._getSubmenu(this._permissionsCT, this._getStringsCT, JSON_STRINGS.CT, t);
                    break;
            }
            if (aux.items.length != 0) submenu.push(aux);
        });
        this._servOpts[1].submenu = submenu;
    }

    private _getStringsAll(p: PermissionsAll) {
        if (p == PermissionsAll.REV_TRAMITES) return [JSON_STRINGS.REV_TRAMITES, dbStatusAll[p]];
        if (p == PermissionsAll.REV_ADEUDOS) return [JSON_STRINGS.REV_ADEUDOS, dbStatusAll[p]];
        if (p == PermissionsAll.PAGOS) return [JSON_STRINGS.PAGOS, dbStatusAll[p]];
        if (p == PermissionsAll.FIRMA) return [JSON_STRINGS.FIRMA, dbStatusAll[p]];
        return ["", []];
    }

    private _getStringsCT(p: PermissionsCT) {
        if (p == PermissionsCT.REV_TRAMITES) return [JSON_STRINGS.REV_TRAMITES, dbStatusAllCT[p]];
        if (p == PermissionsCT.REV_CVC) return [JSON_STRINGS.REV_CVC, dbStatusAllCT[p]];
        if (p == PermissionsCT.REV_CIP_CAM) return [JSON_STRINGS.REV_CIP_CAM, dbStatusAllCT[p]];
        if (p == PermissionsCT.PAGOS) return [JSON_STRINGS.PAGOS, dbStatusAllCT[p]];
        if (p == PermissionsCT.FIRMA) return [JSON_STRINGS.FIRMA, dbStatusAllCT[p]];
        return ["", []];
    }

    private _getSubmenu(list: any[], getString: any, name: string, t: Tramites) {
        let items: any[] = [];
        let flatPermissions = this.permissions.flat();
        list.map((p: any) => {
            if (flatPermissions[(t * 4) + p]) {
                items.push({
                    id: this._id,
                    dbStatus: getString(p)[1],
                    name: getString(p)[0],
                    icon: "description",
                    url: "/private/servidor-publico/folios"
                });
                this._id++;
            }
        });
        return {
            id: dbTramites.get(t),
            name: name,
            opened: false,
            items: items
        };
    }

}