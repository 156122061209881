import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Documentos } from '../upload-documents/upload-documents.component';

@Component({
  selector: 'app-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.sass']
})
export class QuestionComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: QuestionData,
    public dialogRef: MatDialogRef<QuestionComponent>
  ) { }
}

export interface QuestionData {
  title: string;
  body: string;
  actions?: QuestionAction[];
  documentos?: Documentos[];
}

export interface QuestionAction {
  title: string;
  style?: string;
  onTap: QuestionOnTap
}

export interface QuestionOnTap {
  (ref: MatDialogRef<QuestionComponent>): void;
}