import { Breakpoints } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { format, subMonths } from 'date-fns';
import { MunicipalityService } from 'src/app/services/api/municipality.service';
import { LayoutService } from 'src/app/services/layout.service';
import { LoadingComponent } from '../../dialogs/loading/loading.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DashboardService } from 'src/app/services/api/dashboard.service';

const INPUT_CASES = {
  [Breakpoints.XSmall]: 'smartphone',
  [Breakpoints.Small]: 'tablet',
  [Breakpoints.Medium]: 'desktop',
  [Breakpoints.Large]: 'desktop',
  [Breakpoints.XLarge]: 'desktop',
};

@Component({
  selector: 'app-municipality-administrator',
  templateUrl: './municipality-administrator.component.html',
  styleUrls: ['./municipality-administrator.component.sass']
})

export class MunicipalityAdministratorComponent implements OnInit, OnDestroy{

  public options: any = { case: 'desktop' };
  public option: any;
  public municipalities: any;
  public rangeForm!: FormGroup;
  public range_date:any = { start:'', end:'' };
  private loading!: any;
  public departments!: any[];

  public chartDataC: any;
  public chartDataD: any;

  cardData: any[] = [
    {
      count: 0,
      title: 'Servidores públicos',
      icon: 'person',
      url: ''
    },
    {
      count: 0,
      title: 'Departamentos',
      icon: 'maps_home_work',
      url: '',
      menu: ''
    },
    {
      count: 0,
      title: 'No. de trámites',
      icon: 'description',
      url: ''
    },
    {
      count: 0,
      title: 'Dias de respuesta promedio',
      icon: 'timer',
      url: '',
      menu: ''
    }
  ];

  constructor(
    private layoutService: LayoutService,
    private municipalityService: MunicipalityService,
    private dashboardService: DashboardService,
    private dialog: MatDialog,
    private snack: MatSnackBar,
  ) { 
    this.rangeForm = new FormGroup({
      start: new FormControl<Date | null>(null, Validators.required),
      end: new FormControl<Date | null>(null, Validators.required),
    });
  }

  ngOnInit(): void {
    this.getRangeDate();
    this.getDeparments();
    this.setLayout();
  }

  getDeparments(){
    let req_params = {
      catalog_name: 'cat_department',
      search: '',
      paginator: {
        page: 1,
        limit: 40
      },
      order: ["name", "DESC"]
    }
    this.loading = this.dialog.open(LoadingComponent, { maxWidth: "600px", disableClose: true });
    this.municipalityService.get_cat_department(req_params).subscribe({
      next: (response: any) => {
        this.loading.close();
        if(response.ok){
          this.departments = response.data.data;
          this.option = this.departments.map((department: any) => department.id);
          this.getMetrics();
        }
      },
      complete: () => {
        this.loading.close();
      },
      error: (err) => {
        this.loading.close();
        this.snack.open('Ocurrió un error al intentar obtener la información de los departamentos. Por favor, inténtelo de nuevo más tarde.', 'Cerrar', { duration: 4500 });        
      }
    });

  }

  getRangeDate(){
    const currentDate = new Date();
    const startDate = subMonths(currentDate, 1);
    
    this.rangeForm.setValue({
      start: startDate,
      end: currentDate,
    });

    this.range_date = {
      start: format(startDate, 'yyyy-MM-dd'),
      end: format(currentDate, 'yyyy-MM-dd'),
    };
  }

  ngOnDestroy(): void {
    this.layoutService.destroyed.next();
    this.layoutService.destroyed.complete();
  }

  setLayout() {
    this.layoutService.breakPoint.subscribe((breakPoint: string) => {
      this.options.case = INPUT_CASES[breakPoint];      
    });
  }

  getMetrics(){
    this.chartDataC = undefined;
    this.chartDataD = undefined;
    let req_params = {
      cat_department_id: this.option,
      range_date: {
        start: format(this.rangeForm.get('start')?.value, 'yyyy-MM-dd'),
        end: format(this.rangeForm.get('end')?.value, 'yyyy-MM-dd')
      }
    }
    this.dashboardService.get_metrics_municipality_dashboard(req_params).subscribe({
      next: (response: any)=> {
        
        if(response.ok){
          this.cardData[0].count = response.data.number_of_users ?? 0;
          this.cardData[1].count = response.data.number_of_departments ?? 0;
          this.cardData[2].count = response.data.total_applications ?? 0;
          this.cardData[3].count = this.getAverageResponseDays(response.data.avg_certificate_issuance_time) ?? 0;
          this.chartDataC = response.data.number_of_applications;
          this.chartDataD = response.data.number_of_applications_per_department;
        }else{          
          this.snack.open('Ocurrió un error al intentar obtener la información del dashboard. Por favor, inténtelo de nuevo más tarde.', 'Cerrar', { duration: 4500 });
        }
      }, 
      error: ()=> {
        
        this.snack.open('Ocurrió un error al intentar obtener la información del dashboard. Por favor, inténtelo de nuevo más tarde.', 'Cerrar', { duration: 4500 });
      }
    });
  }

  getAverageResponseDays( time: any): number{
    let days: number = 0;
    let secondsToDays = time.seconds / 86400;
    let minutesToDays = time.minutes / 1440;
    let hoursToDays = time.hours / 24;
    days = time.days + hoursToDays + minutesToDays + secondsToDays;    
    return Number(days.toFixed(2));
  }
}
