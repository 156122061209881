import { RailMenu } from "src/app/models/services/menu";
import { PermissionsAll, PermissionsCT, Tramites } from "./constants";

export var adminOpts: RailMenu[] = [
    {
        id: 1,
        icon: 'home',
        name: 'Inicio',
        submenu: [
            {
                name: '',
                opened: true,
                items: [
                    {
                        id: 11,
                        icon: "account_balance",
                        name: "Dashboard",
                        url: "/private/administrador/home"
                    }
                ]
            }
        ]
    },
    {
        id: 2,
        icon: 'leaderboard',
        name: 'Reportes y estadísticas',
        submenu: [
            {
                name: 'Usuarios',
                opened: true,
                items: [
                    {
                        id: 21,
                        icon: "account_balance",
                        name: "Municipios",
                        url: "/private/administrador/municipios"
                    }
                ]
            }
        ]
    },
    
];

export var mpioOpts: RailMenu[] = [
    {
        id: 3,
        icon: 'home',
        name: 'Inicio',
        submenu: [
            {
                name: 'Inicio',
                opened: true,
                items: [
                    {
                        id: 31,
                        icon: "dashboard",
                        name: "Dashboard",
                        url: "/private/municipio/home"
                    }
                ]
            }
        ]
    },
    {
        id: 4,
        icon: 'folder',
        name: 'Administracion de Departamentos',
        submenu: [
            {
                name: 'Departamentos',
                opened: true,
                items: [
                    {
                        id: 41,
                        icon: "list",
                        name: "Departamentos",
                        url: "/private/municipio/departamentos"
                    }
                ]
            },
            {
                name: 'Administración de solicitudes',
                opened: true,
                items: [
                    {
                        id: 62,
                        icon: "list",
                        name: "Solicitudes",
                        url: "/private/municipio/administrar-solicitudes"
                    }
                ]
            }
        ]
    },
    {
        id: 5,
        icon: 'person',
        name: 'Usuarios',
        submenu: [
            {
                name: 'Usuarios',
                opened: true,
                items: [
                    {
                        id: 31,
                        icon: "person",
                        name: "Usuarios",
                        url: "/private/municipio/servidores-publicos"
                    }
                ]
            }
        ]
    },
];

export var servOpts: RailMenu[] = [
    {
        id: 6,
        icon: 'home',
        name: 'Inicio',
        submenu: [
            {
                name: 'Dashboard',
                opened: true,
                items: [
                    {
                        id: 61,
                        icon: "dashboard",
                        name: "Dashboard",
                        url: "/private/administrador/municipios"
                    }
                ]
            }
        ]
    },
    {
        id: 7,
        icon: 'account_balance',
        name: 'Ventanilla Notarial',
        submenu: []
    }
];

/*
1	Solicitud creada y en revisión	2023-12-07 04:21:41	2023-12-07 04:21:41
2	Trámite en corrección	2023-12-07 04:21:41	2023-12-07 04:21:41
3	Trámite en revisión de adeudos	2023-12-07 04:21:41	2023-12-07 04:21:41
4	Trámite en revisión de CVC	2023-12-07 04:21:41	2023-12-07 04:21:41
5	Trámite en revisión de CIP - CAM	2023-12-07 04:21:41	2023-12-07 04:21:41
6	Trámite en generación y envío de orden de pago	2023-12-07 04:21:41	2023-12-07 04:21:41
7	Támite a la espera de comprobante de pago	2023-12-07 04:21:41	2023-12-07 04:21:41
8	Pago recibido y a la espera de concilicación	2023-12-07 04:21:41	2023-12-07 04:21:41
9	Trámite en proceso de firma	2023-12-07 04:21:41	2023-12-07 04:21:41
10	Trámite firmado y enviado	2023-12-07 04:21:41	2023-12-07 04:21:41
11	Trámite cancelado	2023-12-07 02:09:12	2023-12-07 02:09:12
*/

export const tramtes: Tramites[] = [
    Tramites.CIP,
    Tramites.CAM,
    Tramites.CVC,
    Tramites.CTD,
    Tramites.CT
];

export const dbTramites: Map<Tramites, number> = new Map([
    [Tramites.CIP, 1],
    [Tramites.CVC, 2],
    [Tramites.CAM, 3],
    [Tramites.CTD, 4],
    [Tramites.CT, 5],
]);

export const dbTramitesList: any = {
    [dbTramites.get(Tramites.CIP)!]: [],
    [dbTramites.get(Tramites.CVC)!]: [],
    [dbTramites.get(Tramites.CAM)!]: [],
    [dbTramites.get(Tramites.CTD)!]: [],
    [dbTramites.get(Tramites.CT)!]: []
};

export const dbStatusAll: any = {
    [PermissionsAll.REV_TRAMITES]: [1, 2, 11],
    [PermissionsAll.REV_ADEUDOS]: [3],
    [PermissionsAll.PAGOS]: [6, 7, 8],
    [PermissionsAll.FIRMA]: [9, 10],
};

export const dbStatusAllCT: any = {
    [PermissionsCT.REV_TRAMITES]: [1, 2, 11],
    [PermissionsCT.REV_CVC]: [4],
    [PermissionsCT.REV_CIP_CAM]: [5],
    [PermissionsCT.PAGOS]: [6, 7, 8],
    [PermissionsCT.FIRMA]: [9, 10]
};

export const permissionsAll: PermissionsAll[] = [
    PermissionsAll.REV_TRAMITES,
    PermissionsAll.REV_ADEUDOS,
    PermissionsAll.PAGOS,
    PermissionsAll.FIRMA
];

export const permissionsCT: PermissionsCT[] = [
    PermissionsCT.REV_TRAMITES,
    PermissionsCT.REV_CVC,
    PermissionsCT.REV_CIP_CAM,
    PermissionsCT.PAGOS,
    PermissionsCT.FIRMA
];