<h1 mat-dialog-title>{{ data.title }}</h1>
<div mat-dialog-content>
    <div [innerHTML]="data.body"></div>
    <form action="#" *ngIf="data.documentos" #fileForm>
        <div *ngIf="menuService.selectedApplicationElement && [6, 7].includes(menuService.selectedApplicationElement.Cat_application_status.id)">
            <div class="file-container">
                <label class="file-label">Línea de captura</label>
                <input class="file-input" [(ngModel)]="file_meta_data.number" [ngModelOptions]="{ standalone: true }" type="text" required>
            </div>
            <div class="file-container">
                <label class="file-label">Fecha de expiración</label>
                <input class="file-input" [(ngModel)]="file_meta_data.expiration_date" [ngModelOptions]="{ standalone: true }" type="datetime-local" required>
            </div>
        </div>
        <div *ngFor="let file of data.documentos" class="file-container">
            <label class="file-label">{{ strings.get(file) }}</label>
            <input class="file-input" id="doc-{{ file }}" name="doc-{{ file }}" type="file" accept=".pdf" required>
        </div>
    </form>
</div>
<div mat-dialog-actions align="end">
    <button mat-button (click)="upload()" color="primary">Continuar</button>
    <button mat-button [mat-dialog-close]="false">Cancelar</button>
</div>