import { Component, Input } from '@angular/core';
import { BaseComponent } from '../../layouts/base/base.component';

@Component({
  selector: 'app-info-card',
  templateUrl: './info-card.component.html',
  styleUrls: ['./info-card.component.sass']
})

export class InfoCardComponent extends BaseComponent{
  @Input() cardData!: any;   
  navigate(data: any){
    this.changeRail(data);    
    this.select(data.submenu[0].items[0]);   
  }  
}
