<div class="card-container">
    <div class="card-content">
        <div class="card-data-container">
            <span class="card-data-count">
                {{ cardData.count }}
            </span>
            <span class="card-data-title">
                {{ cardData.title }}
            </span>
        </div>
    </div>
    <div class="icon-container">
        <mat-icon>{{ cardData.icon }}</mat-icon>
    </div>
    <div class="card-footer">
        <a (click)="navigate(cardData.menu)  " *ngIf="cardData.url">            
            <div>
                <span class="footer-text">Más información</span>
                <mat-icon>arrow_circle_right</mat-icon>
            </div>               
        </a>
    </div>
</div>
