import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { QuestionData } from '../question/question.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MenuService } from 'src/app/services/menu.service';
import { Observable, forkJoin } from 'rxjs';
import { ProcedureService } from 'src/app/services/api/procedure.service';
import { LoadingComponent } from '../loading/loading.component';

@Component({
  selector: 'app-upload-documents',
  templateUrl: './upload-documents.component.html',
  styleUrls: ['./upload-documents.component.sass']
})
export class UploadDocumentsComponent {

  public strings: Map<Documentos, string> = DOC_STRING;
  @ViewChild("fileForm") formRef?: ElementRef<HTMLFormElement>;

  public file_meta_data: FileMetaData = {
    number: undefined,
    payment_data: {
      banco: undefined,
      fecha_pago: undefined,
      folio: undefined
    },
    expiration_date: undefined
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: QuestionData,
    public dialogRef: MatDialogRef<UploadDocumentsComponent>,
    private snack: MatSnackBar,
    public menuService: MenuService,
    private dialog: MatDialog,
    private prodecureService: ProcedureService,
  ) { }

  upload() {
    if (this.formRef && this.menuService.selectedApplicationElement && this.data.documentos) {
      let form = this.formRef.nativeElement;
      if (form.checkValidity()) {
        let loading = this.dialog.open(LoadingComponent, { disableClose: true });
        let loads: Observable<DefaultResponse>[] = [];
        this.data.documentos.map((file: Documentos) => {
          let data: FormData = new FormData();
          let input: HTMLInputElement = document.getElementById(`doc-${file}`) as HTMLInputElement;
          if (file === Documentos.ORDEN_PAGO) data.append('file_meta_data', JSON.stringify(this.file_meta_data));
          if (input && input.files) data.append('file_data', input.files[0]);
          data.append('cat_file_id', `${file}`);
          loads.push(this.prodecureService.upload_document(data));
        });
        forkJoin(loads).subscribe({
          next: (response) => {
            if (response.every((r) => r.ok === true)) {
              this.dialogRef.close(true);
              loading.close();
            }
          },
          error: (err) => {
            this.snack.open("Ocurrió un error al subir los documentos, intenta más tarde.", 'Cerrar', { duration: 1000 });
            loading.close();
          }
        });
      } else {
        this.snack.open("Todos los documentos son necesarios", 'Cerrar', { duration: 1000 });
      }
    }
  }

}

export enum Documentos {
  IDENTIFICACION = 1,
  POS_INMUEBLE,
  PAGO_IP,
  CER_CNA,
  CER_CIP,
  CER_CAM,
  CER_CVC,
  ESCRITURA,
  DICTAMEN,
  CTD_ANT,
  SOL_TRAMITE,
  ORDEN_PAGO,
  CONSTANCIA_FIR,
  COMP_PAGO,
  PRE_CER,
  MOTIVO_CANCELACION
}

const DOC_STRING = new Map<Documentos, string>([
  [Documentos.IDENTIFICACION, "Identificación o RFC"],
  [Documentos.POS_INMUEBLE, "Documento que acredite la posesión del inmueble"],
  [Documentos.PAGO_IP, "Pago del impuesto predial"],
  [Documentos.CER_CNA, "Certificado de no adeudo de agua"],
  [Documentos.CER_CIP, "Certificado de no adeudo del impuesto predial"],
  [Documentos.CER_CAM, "Certificado de aportación y mejoras"],
  [Documentos.CER_CVC, "Certificado de clave y valor catastral"],
  [Documentos.ESCRITURA, "Escritura"],
  [Documentos.DICTAMEN, "Dictamen"],
  [Documentos.CTD_ANT, "Traslado de dominio anterior"],
  [Documentos.SOL_TRAMITE, "Solicitud de trámite"],
  [Documentos.ORDEN_PAGO, "Orden de pago"],
  [Documentos.CONSTANCIA_FIR, "Certificado firmado"],
  [Documentos.COMP_PAGO, "Comprobante de pago"],
  [Documentos.PRE_CER, "Pre-certificado"],
  [Documentos.MOTIVO_CANCELACION, "Motivo de cancelación o corrección"],
]);

export interface DefaultResponse {
  ok: boolean;
  status: number;
  message: string;
}

export interface FileMetaData {
  number?: string;
  payment_data?: PaymentData;
  expiration_date?: Date;
}

export interface PaymentData {
  banco?: string;
  fecha_pago?: Date;
  folio?: string;
}