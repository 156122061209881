import { Injectable } from "@angular/core";
import { MatPaginatorIntl } from "@angular/material/paginator";

export  @Injectable() class MatPaginatorIntlSpanish extends MatPaginatorIntl {
    override itemsPerPageLabel = 'Total por página';
    override nextPageLabel = 'Siguiente página';
    override previousPageLabel = 'Página anterior';
    override firstPageLabel = 'Primera página';
    override lastPageLabel = 'Última página';
    override getRangeLabel = (page: number, pageSize: number, length: number) => {
      if (length === 0 || pageSize === 0) {
        return `0 de ${length}`;
      }
      const startIndex = page * pageSize + 1;
      const endIndex = startIndex + pageSize - 1;
      return `${startIndex} - ${endIndex} de ${length}`;
    };
}