<div class="public-servants-details-container" [ngClass]="options.case">
    <mat-card class="menu-container" [ngClass]="options.case">
        <div class="menu-title">Secciones</div>
        <mat-divider></mat-divider>
        <ul>
            <li [ngClass]="{'active-option': activeOption === 'public-server'}" (click)="setActiveOption('public-server')">
                <a>
                    <div class="menu-option-container">
                        <div class="icon-container">
                            <mat-icon>info</mat-icon>
                        </div>
                        <div class="menu-option">
                            Servidor público
                        </div>
                    </div>
                </a>
            </li>
            <li *ngIf="!userData.new" [ngClass]="{'active-option': activeOption === 'user-permissions'}" (click)="setActiveOption('user-permissions')">
                <a>
                    <div class="menu-option-container">
                        <div class="icon-container">
                            <mat-icon>settings</mat-icon>
                        </div>
                        <div class="menu-option">
                            Permisos
                        </div>
                    </div>
                </a>
            </li>
        </ul>
    </mat-card>
    <app-municipality-user *ngIf="activeOption == 'public-server'" (setUser)="updateData($event)" [encode]="userData"></app-municipality-user>
    <app-public-servants-permissions *ngIf="activeOption == 'user-permissions'" [userData]="userData"></app-public-servants-permissions>    
</div>