import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class HeadersInterceptor implements HttpInterceptor {

  constructor() {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const token_web = localStorage.getItem('token_web');
    // token caducado para preubas administrador de plataforma
    const otroToken = "eyJhbGciOiJSUzI1NiIsImtpZCI6IjdjZjdmODcyNzA5MWU0Yzc3YWE5OTVkYjYwNzQzYjdkZDJiYjcwYjUiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vdmUtbm90YXJpYWwtbGl0ZSIsImF1ZCI6InZlLW5vdGFyaWFsLWxpdGUiLCJhdXRoX3RpbWUiOjE3MDU0MzAxNzgsInVzZXJfaWQiOiJZOFhTdFYzOGJjVG43Mm1zWDJhMlBBZ2pBOG8xIiwic3ViIjoiWThYU3RWMzhiY1RuNzJtc1gyYTJQQWdqQThvMSIsImlhdCI6MTcwNTQzMDE3OCwiZXhwIjoxNzA1NDMzNzc4LCJlbWFpbCI6Inplcmdpb2hyQGdtYWlsLmNvbSIsImVtYWlsX3ZlcmlmaWVkIjpmYWxzZSwiZmlyZWJhc2UiOnsiaWRlbnRpdGllcyI6eyJlbWFpbCI6WyJ6ZXJnaW9ockBnbWFpbC5jb20iXX0sInNpZ25faW5fcHJvdmlkZXIiOiJwYXNzd29yZCJ9fQ.K0NZa-Xno24H28O3zV6pJP-UZ8FI7Kp1TKEAhOjVAwWw5yZuh2fR2zLmshhv_HjgjhFdFCA2OzutnYkQgM5GGYbhG-su0tyy7zK4pA9GpBtwwVCKeJfXh_RSTO61UoWNVy3YkjQ-9kJOZMWNKI2GzOm-8juN6qRwQ4WlXPHfvSyfaHT7npuS-yALQWN4ofoi8RWZRUyGs1yxhXQYqFqCCMaUO1LkKL5pHR_h0otJ2Ab09sAb3ofurhKKfRRUearCuVzI7a0BrTR3ieAWkBdwvgLqnN9krpRH3dJHqD-qyeUqKPcUPCshkdecm46wI6IiDivySsKbq_pRZtkt-gCg";
    const api_key: string = environment.X_API_KEY;
    const headers = new HttpHeaders({
      'X-API-KEY': api_key,
      'X-TOKEN': `${token_web}`,
      // 'X-TOKEN': `${otroToken}`,
      'ERROR_API_INTERCEPTOR': 'true'
    });

    const reqClone = request.clone({
      headers
    });
    return next.handle(reqClone);
  }
}
