import { Injectable, inject } from "@angular/core";
import { CryptoService } from "./api/crypto.service";
import { User } from "../models/user/user";
import { MatDrawerMode } from "@angular/material/sidenav";
import { MenuItems, RailMenu } from "../models/services/menu";
import { Settings } from "../models/services/settings";
import { LocalStorageErrors } from "../shared/statics/constants";
import { ServFilters } from "../models/services/serv-filters";
import { BehaviorSubject, Observable, debounceTime } from "rxjs";
import { ApplicationElement } from "../models/application/element";
import { FilesDatum } from "../models/application/details";

@Injectable({
    providedIn: 'root'
})
export class localStorageService {

    private _user?: User;
    private _rawSettings: string | null;
    private _rawUser: string | null;
    private _cService: CryptoService;
    private readonly _debounceMS = 500;

    private _servFilters: ServFilters = {
        filters: [
            { cat_application_status_id: [] },
            { department_applicationType_id: [] },
            { search: "" },
        ],
        paginator: {
            page: 1,
            limit: 10,
            size: 0
        },
        order: ["createdAt", "DESC"]
    };

    private _settings: Settings = {
        open: false,
        mode: "side",
        permissions: [],
        selectedRailItem: undefined,
        selectedSubItem: undefined,
        selectedSubmenu: "",
        servFilters: this._servFilters,
        selectedApplicationElement: undefined,
        selectedFile: undefined
    };

    private _subject: BehaviorSubject<ServFilters>;
    private _subjectFile: BehaviorSubject<FilesDatum>;

    constructor() {
        this._cService = inject(CryptoService);
        this._rawUser = localStorage.getItem('user');
        this._rawSettings = localStorage.getItem('settings');
        this._setupUser();
        this._setupSettings();
        this._subject = new BehaviorSubject<ServFilters>(this._settings.servFilters);
        this._subjectFile = new BehaviorSubject<FilesDatum>(this._settings.selectedFile!);
    }

    /* Getters */
    get user(): User {
        return this._user!;
    }

    get open(): boolean {
        return this._settings.open;
    }

    get mode(): MatDrawerMode {
        return this._settings.mode;
    }

    get permissions(): any[] {
        return this._settings.permissions;
    }

    get selectedRailItem(): RailMenu | undefined {
        return this._settings.selectedRailItem;
    }

    get selectedSubItem(): MenuItems | undefined {
        return this._settings.selectedSubItem;
    }

    get selectedSubmenu(): string {
        return this._settings.selectedSubmenu;
    }

    get servFiltersObserv(): Observable<ServFilters> {
        return this._subject.asObservable().pipe(debounceTime(this._debounceMS));
    }

    get fileObserv(): Observable<FilesDatum> {
        return this._subjectFile.asObservable().pipe(debounceTime(this._debounceMS));
    }

    get servFilters(): ServFilters {
        return this._settings.servFilters;
    }

    get endFilters() {
        return this._subject.unsubscribe;
    }

    get cat_application_status_id(): number[] | undefined {
        return this._settings.servFilters.filters[0].cat_application_status_id;
    }

    get department_applicationType_id(): number[] | undefined {
        return this._settings.servFilters.filters[1].department_applicationType_id;
    }

    get search(): string | undefined {
        return this._settings.servFilters.filters[2].search;
    }

    get page(): number {
        return this._settings.servFilters.paginator.page;
    }

    get limit(): number {
        return this._settings.servFilters.paginator.limit;
    }

    get order(): string {
        return this._settings.servFilters.order[0];
    }

    get direction(): string {
        return this._settings.servFilters.order[1];
    }

    get size(): number {
        return this._settings.servFilters.paginator.size;
    }

    get selectedApplicationElement(): ApplicationElement | undefined {
        return this._settings.selectedApplicationElement;
    }

    get selectedFile(): FilesDatum | undefined {
        return this._settings.selectedFile;
    }

    get annotations(): string {
        return this._settings.selectedFile ? this._settings.selectedFile.annotations : "[]";
    }
    
    /* Setters */
    set open(value: boolean) {
        this._settings.open = value;
        this._save();
    }

    set mode(value: MatDrawerMode) {
        this._settings.mode = value;
        this._save();
    }

    set permissions(value: any[]) {
        this._settings.permissions = value;
        this._save();
    }

    set selectedRailItem(value: RailMenu) {
        this._settings.selectedRailItem = value;
        this._save();
    }

    set selectedSubItem(value: MenuItems) {
        this._settings.selectedSubItem = value;
        if (value.dbStatus) this.cat_application_status_id = value.dbStatus;
        else this._save();
    }

    set selectedSubmenu(value: string) {
        this._settings.selectedSubmenu = value;
        this._save();
    }

    set cat_application_status_id(value: number[]) {
        this._settings.servFilters.filters[0].cat_application_status_id = value;
        this._subject.next(this._settings.servFilters);
        this._save();
    }

    set department_applicationType_id(value: number[]) {
        this._settings.servFilters.filters[1].department_applicationType_id = value;
        this._save();
    }

    set search(value: string) {
        this._settings.servFilters.filters[2].search = value;
        this._subject.next(this._settings.servFilters);
        this._save();
    }

    set page(value: number) {
        this._settings.servFilters.paginator.page = value;
        this._subject.next(this._settings.servFilters);
        this._save();
    }

    set limit(value: number) {
        this._settings.servFilters.paginator.limit = value;
        this._subject.next(this._settings.servFilters);
        this._save();
    }

    set order(value: string) {
        this._settings.servFilters.order[0] = value;
        this._subject.next(this._settings.servFilters);
        this._save();
    }

    set direction(value: string) {
        this._settings.servFilters.order[1] = value;
        this._subject.next(this._settings.servFilters);
        this._save();
    }

    set size(value: number) {
        this._settings.servFilters.paginator.size = value;
        this._save();
    }

    set selectedApplicationElement(value: ApplicationElement) {
        this._settings.selectedApplicationElement = value;
        this._save();
    }

    set selectedFile(value: FilesDatum) {
        this._settings.selectedFile = value;
        this._subjectFile.next(this._settings.selectedFile);
        this._save();
    }

    set annotations(value: string) {
        if (this._settings.selectedFile) this._settings.selectedFile.annotations = value;
        this._save();
    }

    /* Methods */
    public endFile() {
        this._settings.selectedFile = undefined;
        this._save();
    }

    private _setupUser(): void {
        if (this._rawUser) {
            try {
                this._user = JSON.parse(this._cService.decrypt(this._rawUser));
            } catch {
                throw LocalStorageErrors.UNDEFINED_USER;
            }
        }
    }

    private _setupSettings(): void {
        if (this._rawSettings) {
            try {
                this._settings = JSON.parse(this._cService.decrypt(this._rawSettings));
            } catch {
                this._setPermissions();
            }
        } else {
            this._setPermissions();
        }
    }

    private _setPermissions() {
        if (this._user!.permissions) {
            this.permissions = this._user!.permissions.split('').map((p: string) => {
                let aux = this._hex2bin(p).split('').reverse();
                return aux.map((pt: string) => pt === '1' ? true : false);
            }).reverse();
        } else {
            this.permissions = "000000".split('').map((p: string) => {
                let aux = this._hex2bin(p).split('').reverse();
                return aux.map((pt: string) => pt === '1' ? true : false);
            }).reverse();
        }
    }

    private _hex2bin(hex: string): string {
        return (parseInt(hex, 16).toString(2)).padStart(4, '0');
    }

    private _save(): void {
        localStorage.setItem('settings', this._cService.encrypt(JSON.stringify(this._settings)));
    }
}