<div class="container">
    <h1 class="title" [style.width]="options.inputWidth">¡Bienvenido!</h1>
    <div class="subtitle" [style.width]="options.inputWidth">Ingresa tus datos para acceder</div>
    <form [formGroup]="form_login" class="form" [style.width]="options.inputWidth">
        <p [style.width]="options.inputWidth">
            <mat-form-field appearance="fill" [style.width]="options.inputWidth">
                <mat-label>Correo</mat-label>
                <input matInput type="email" formControlName="email">
                <mat-icon matSuffix>alternate_email</mat-icon>
            </mat-form-field>
        </p>
        <p [style.width]="options.inputWidth">
            <mat-form-field appearance="fill" [style.width]="options.inputWidth">
                <mat-label>Contraseña</mat-label>
                <input matInput type="password" formControlName="password">
                <mat-icon matSuffix>password</mat-icon>
            </mat-form-field>
        </p>
    </form>
    <div class="footer-buttons" [style.width]="options.inputWidth">
        <a mat-button (click)="forgotPass()">¿Olvidaste tu contraseña?</a>
        <a mat-button href="#" color="primary">Registrate</a>
    </div>
    <div class="footer" [style.width]="options.inputWidth">
        <a mat-flat-button color="accent" (click)="login()">Ingresar</a>
    </div>
</div>