<div class="custom-aside">
  <div>
    <div class="img-container">
      <img src="../../../../../../assets/app-aside/colegio_notarios.png">
    </div>
  </div>
  <mat-list>
    <mat-list-item *ngFor="let element of menuobj" (click)="select(element)">
      <div class="icon-container" [matTooltip]="element.name"
        [ngClass]="{'icon-container-active': selected ? element.id === selected.id : false }">
        <mat-icon matListItemIcon>{{ element.icon }}</mat-icon>
      </div>
    </mat-list-item>
  </mat-list>
  <mat-list>
    <mat-list-item style="margin-bottom: 15px;">
      <button type="button" mat-button [matMenuTriggerFor]="menu" class="user-button">
        <img [src]="photoURL" alt="">
      </button>
      <mat-menu #menu="matMenu" class="custom-aside-menu">
        <button mat-menu-item (click)="profile()">Perfil</button>
        <button mat-menu-item (click)="logout()">Cerrar sesión</button>
      </mat-menu>
    </mat-list-item>
  </mat-list>
</div>