<mat-card [ngClass]="options.case">
  <mat-card-header>
    <mat-card-title-group>
      <div class="user-image-container">
        <label for="imageUpload">
          <input type='file' id="user-profile-image" accept=".png, .jpg, .jpeg" />
        </label>
        <img src="../../../../../assets/backgrounds/profile/profile_user.png" alt="">        
      </div>
      <div class="user-description">
        <mat-card-title *ngIf="this.admin_info">
          {{this.admin_info.name}}
        </mat-card-title>
        <mat-card-subtitle>Administrador</mat-card-subtitle>
      </div>
    </mat-card-title-group>
  </mat-card-header>
  <mat-card-content>
    <form [formGroup]="form_profile">
      <mat-divider></mat-divider>
      <div class="half-column">
        <div class="text-label">Nombre</div>
        <div>
          <mat-form-field appearance="outline">
            <input matInput #input formControlName="name">
            <mat-error *ngIf="form_profile.get('name')?.hasError('required')">
              El nombre es requerido
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="half-column">
        <div class="text-label">Correo</div>
        <div>
          <mat-form-field appearance="outline">
            <input matInput #input formControlName="email">
            <mat-error *ngIf="form_profile.get('email')?.hasError('required')">
              El correo es requerido
            </mat-error>
            <mat-error *ngIf="form_profile.get('email')?.hasError('email')">
              Introduzca un correo válido
            </mat-error>
          </mat-form-field>
        </div>
      </div>      

      <div class="half-column">
        <div class="text-label">Cambiar contraseña</div>
        <div class="button-content">
          <button (click)="sendEmail(this.admin_info.email)" *ngIf="this.userData.new == false"
            class="btn-restartPassword"
            matTooltip="Da clic para enviar un correo que permita restablecer tu contraseña">
            Enviar correo
          </button> 
        </div>
      </div>
      
      <div class="half-column">
        <div class="text-label">Teléfono</div>
        <div>
          <mat-form-field appearance="outline">
            <input matInput formControlName="phone_number" placeholder="+525511223344">
            <mat-error *ngIf="form_profile.get('phone_number')?.hasError('required')">
              El teléfono es requerido
            </mat-error>
            <mat-error *ngIf="form_profile.get('phone_number')?.hasError('pattern')">
              Ej. +525555555555.
            </mat-error> 
          </mat-form-field>
        </div>
      </div>
      <div class="button-content" style="margin-top: 20px;">
        <button (click)="saveUser()" [disabled]="form_profile.invalid">Guardar cambios</button>        
      </div>
    </form>
  </mat-card-content>
</mat-card>