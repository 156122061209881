<mat-card class="menu-container" [ngClass]="options.case">
    <div class="menu-title">Secciones</div>
    <mat-divider></mat-divider>
    <ul>
        <li routerLinkActive="active-option">
            <a routerLink="/private/municipio/detalle/servidor-publico">
                <div class="menu-option-container">
                    <div class="icon-container">
                        <mat-icon>info</mat-icon>
                    </div>
                    <div class="menu-option">
                        Servidor público
                    </div>
                </div>
            </a>
        </li>
        <li routerLinkActive="active-option">
            <a routerLink="/private/municipio/detalle/permisos">
                <div class="menu-option-container">
                    <div class="icon-container">
                        <mat-icon>settings</mat-icon>
                    </div>
                    <div class="menu-option">
                        Permisos
                    </div>
                </div>
            </a>
        </li>
    </ul>
</mat-card>