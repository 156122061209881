import { Breakpoints } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CryptoService } from 'src/app/services/api/crypto.service';
import { LayoutService } from 'src/app/services/layout.service';

const INPUT_CASES = {
  [Breakpoints.XSmall]: 'smartphone',
  [Breakpoints.Small]: 'tablet',
  [Breakpoints.Medium]: 'desktop',
  [Breakpoints.Large]: 'desktop',
  [Breakpoints.XLarge]: 'desktop',
};

@Component({
  selector: 'app-public-servants-details',
  templateUrl: './public-servants-details.component.html',
  styleUrls: ['./public-servants-details.component.sass']
})

export class PublicServantsDetailsComponent implements OnInit, OnDestroy{
  public options: any = { case: 'desktop' }
  public encode: any;
  public userData: any;
  public userId!: number;
  public activeOption: string = 'public-server';

  constructor( 
    private layoutService: LayoutService,
    private route: ActivatedRoute,
    private cService: CryptoService,    
  ){
    this.route.params.subscribe(params => {
      this.encode = params['data'];
      this.userData = JSON.parse(this.cService.decrypt(this.encode));      
    });
  }  

  ngOnInit(): void {
    this.setLayout();    
  }

  ngOnDestroy(): void {
    this.layoutService.destroyed.next();
    this.layoutService.destroyed.complete();
  }

  setLayout(){
    this.layoutService.breakPoint.subscribe((breakPoint: string) => {
      this.options.case = INPUT_CASES[breakPoint];         
    });
  }  

  setActiveOption(option: string){
    this.activeOption = option;  
  }

  updateData(data: any){
    let newData = JSON.parse(data);       
    this.userData.new = newData.new;
    this.userData.idMun = newData.idMun;
    this.activeOption = newData.option;
  }  
}
