<div class="departments-container">
    <mat-card>
        <mat-card-header>
            <mat-card-title>
                <span>Hoja membretada</span>
            </mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <img [src]="data.url_img" alt="hoja_membretada">
        </mat-card-content>        
    </mat-card>    
</div>
