import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { QuestionComponent, QuestionData, QuestionOnTap } from "../core/components/dialogs/question/question.component";
import { Documentos, UploadDocumentsComponent } from "../core/components/dialogs/upload-documents/upload-documents.component";
import { UpdateDataComponent } from "../core/components/dialogs/update-data/update-data.component";

@Injectable({
    providedIn: 'root'
})
export class DialogService {

    public primaryButton: string = "background: var(--mat-badge-background-color); color: white;";
    public secondaryButton: string = "background: #cecece";

    public closeAction: QuestionOnTap = (ref) => { ref.close(); };

    constructor(
        private dialog: MatDialog,
        private snack: MatSnackBar
    ) { }

    openDialog(data: QuestionData, width?: number) {
        return this.dialog.open(QuestionComponent, {
            data: data,
            width: `${width ?? 400}px`,
            disableClose: true
        });
    }

    openDocumentDialog(documentos: Documentos[]) {
        let documentQuestionData: QuestionData = {
            title: "Documentos",
            body: "<p>Adjunta los documentos listados a continuación</p>",
            documentos: documentos
        };
        return this.dialog.open(UploadDocumentsComponent, {
            data: documentQuestionData,
            width: '400px',
            disableClose: true
        });
    }

    openDataDialog() {
        let dataQuestionData: QuestionData = {
            title: "Cargar datos",
            body: "<p>A continuación llena la información solicitada, esta será plasmada en el certificado firmado.</p>"
        };
        return this.dialog.open(UpdateDataComponent, {
            data: dataQuestionData,
            width: '500px',
            disableClose: true
        });
    }

    openConfirmationDialog(callbackAction: QuestionOnTap) {
        let confirmationQuestionData: QuestionData = {
            title: "Confirmación",
            body: "<p>¿Estas seguro de realizar está acción?</p>",
            actions: [
                {
                    title: "Continuar",
                    style: this.primaryButton,
                    onTap: callbackAction
                },
                {
                    title: "Cancelar",
                    style: this.secondaryButton,
                    onTap: this.closeAction
                },
            ]
        };
        this.openDialog(confirmationQuestionData);
    }

    error() {
        this.snack.open("Ocurrió un error al actualizar el trámite, intenta más tarde.", 'Cerrar', { duration: 1000 });
    }
}