<div class="public-servants-table-container" [ngClass]="options.case">
  <mat-card>
    <div class="public-servants-table-search-container">
      <label class="search-title">Usuarios</label>
      <div class="action-container">
        <mat-form-field appearance="outline">
          <input matInput [formControl]="searchControl" placeholder="Búsqueda" > 
          <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>
        <button (click)="newData()" matTooltip="Registra un nuevo servidor público.">Nuevo Servidor Público</button>
      </div>
    </div>
    <div class="body-table-container">
      <table mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="nombre">
          <th mat-header-cell *matHeaderCellDef>Nombre</th>
          <td mat-cell *matCellDef="let element">
            <div class="public-servants-container">
              <div class="img-container">
                <img [src]="element.Cat_municipality.logo" alt="servidor público">
              </div>
              <span> {{ element.name | titlecase }} </span>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef>Estatus</th>
          <td mat-cell *matCellDef="let element" style="text-align: center;">
            <div *ngIf="element.status == 'alta'" class="status-icon">
              <div class="status status-red"></div>
              <div class="text-icon">Inactivo</div>
            </div>
            <div *ngIf="element.status" class="status-icon">
              <div class="status status-green"></div>
              <div class="text-icon">Activo</div>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="rol_name">
          <th mat-header-cell *matHeaderCellDef>Puesto</th>
          <td mat-cell *matCellDef="let element">
            <span> {{ element.Cat_rol.name }} </span>
          </td>
        </ng-container>
        <ng-container matColumnDef="creacion">
          <th mat-header-cell *matHeaderCellDef>Creación</th>
          <td mat-cell *matCellDef="let element">{{element.createdAt | date: "dd/MM/yyyy"}}</td>
        </ng-container>
        <ng-container matColumnDef="acciones">
          <th mat-header-cell *matHeaderCellDef>Consultar</th>
          <td mat-cell *matCellDef="let element">
            <a class="button-check" color="neutral-50" 
            (click)="sendData(element.id)" matTooltip="Consulta y actualiza la información.">Perfil</a>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>        
    </div> 
    <div class="mat-paginator-container">
      <mat-paginator (page)="change_page($event)" [pageSize]="cant"  
        [length]="count" [pageSizeOptions]="[5, 10, 15, 20, 50]"  
        [showFirstLastButtons]="true">
      </mat-paginator>
    </div>     
  </mat-card>
</div>