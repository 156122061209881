<mat-card [ngClass]="options.case">
    <mat-card-header>
      <mat-card-title-group>
        <div class="user-image-container">
          <label for="imageUpload">
            <input type='file' id="user-profile-image" accept=".png, .jpg, .jpeg" />
          </label>
          <img src="../../../../../assets/backgrounds/profile/profile_user.png" alt="">
          <!-- <div class="user-image-icon" matTooltip="Seleccionar imagen de perfil" (click)="clickImage()">
            <mat-icon class="icon-pencil">edit</mat-icon>
          </div> -->   
        </div>
        <div class="user-description">
          <mat-card-title *ngIf="this.admin_info">
            {{this.admin_info.name}}
          </mat-card-title>
          <mat-card-subtitle>Servidor Público</mat-card-subtitle>
        </div>
      </mat-card-title-group>     
    </mat-card-header>  
    <mat-card-content>    
      <form [formGroup]="form_profile">
        <mat-divider></mat-divider>
        <div class="half-column">
          <div class="text-label">Nombre</div>
          <div>
            <mat-form-field appearance="outline">
              <input matInput #input formControlName="name">
              <mat-error *ngIf="form_profile.get('name')?.hasError('required')">
                El campo Nombre es requerido
              </mat-error>
            </mat-form-field>
          </div>
        </div>      
        <div class="half-column">
          <div class="text-label">Correo</div>
          <div>
            <mat-form-field appearance="outline">
              <input matInput #input formControlName="email">
              <mat-error *ngIf="form_profile.get('email')?.hasError('required')">
                El campo Correo es requerido
              </mat-error>
              <mat-error *ngIf="form_profile.get('email')?.hasError('email')">
                Introduzca un correo válido
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="half-column" *ngIf="this.encode.new == true">
          <div class="text-label">Contraseña</div>
          <div>
            <mat-form-field appearance="outline">
              <input matInput #input formControlName="password" type="password" (keyup)="validatePasswords()">
              <mat-error *ngIf="form_profile.get('password')?.hasError('required')">
                El campo Contraseña es requerido
              </mat-error>
              <mat-error *ngIf="form_profile.get('password')?.hasError('minlength')">
                La extensión minima son 6 caracteres
              </mat-error>  
            </mat-form-field>
          </div>
        </div>
        <div class="half-column" *ngIf="this.encode.new == true">
          <div class="text-label">Confirmar contraseña</div>
          <div class="input-container">
            <mat-form-field appearance="outline">
              <input matInput #input formControlName="confirm_password" type="password" (keyup)="validatePasswords()">
             
              <mat-error *ngIf=" form_profile.get('confirm_password')?.hasError('passwordMismatch')?.valueOf != null">
                Las contraseñas no coinciden.
              </mat-error>
              <mat-error *ngIf="form_profile.get('confirm_password')?.hasError('required')">
                El campo es requerido
              </mat-error>       
            </mat-form-field>          
          </div>
        </div>
        
        <div class="half-column">          
          <div class="text-label">         
            Teléfono
          </div>
          <div>
            <mat-form-field appearance="outline">
              <input matInput formControlName="phone_number" placeholder="+525555555555">
              <mat-error *ngIf="form_profile.get('phone_number')?.hasError('required')">
                El campo Teléfono es requerido
              </mat-error> 
              <mat-error *ngIf="form_profile.get('phone_number')?.hasError('pattern')">
                Ej. +525555555555.
              </mat-error> 
            </mat-form-field>
          </div>
        </div>   
        <div class="half-column column-space" *ngIf="this.encode.new == false"></div>  
        <div class="half-column" *ngIf="this.encode.new == false">
          <div class="text-label">Cambiar contraseña</div>
          <div class="button-content">
            <button (click)="sendEmail()" *ngIf="this.encode.new == false" 
                    class="btn-restartPassword"
                    matTooltip="Da clic para enviar un correo que permita restablecer tu contraseña">
                    Enviar correo
            </button>
          </div>
        </div>       
        <mat-divider></mat-divider>
        <h2>Notificaciones</h2>
        <p>Agrega un número de teléfono para recibir notificaciones</p>
        <div class="half-column">          
          <div class="text-label">Teléfono</div>
          <div>
            <mat-form-field appearance="outline">
              <input matInput formControlName="notification_phone_number" placeholder="+525555555555">
              <mat-error *ngIf="form_profile.get('notification_phone_number')?.hasError('required')">
                El campo Teléfono es requerido
              </mat-error> 
              <mat-error *ngIf="form_profile.get('notification_phone_number')?.hasError('pattern')">
                Ej. +525555555555.
              </mat-error> 
            </mat-form-field>
          </div>
        </div>       
        <div class="half-column">
          <div class="text-label"> Correo </div>
          <div>
            <mat-form-field appearance="outline">
              <input matInput formControlName="notification_email">
              <mat-error *ngIf="form_profile.get('notification_email')?.hasError('required')">
                El campo Correo es requerido
              </mat-error>
              <mat-error *ngIf="form_profile.get('notification_email')?.hasError('email')">
                Introduzca un correo válido
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="button-content">
          <button mat-flat-button (click)="saveUser()" [disabled]="form_profile.invalid">{{ encode.new ? 'Guardar' : 'Actualizar'}}</button>
        </div>
      </form>
    </mat-card-content>
  </mat-card>