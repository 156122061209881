export const environment = {
    production: false,
    //urlApi: 'http://localhost:3000/api/v2/',
    urlApi: 'https://notcedi-api.zurco.com.mx/api/v2/',
    // Secrets
    X_API_KEY: 'API_KEY_VENTANILLA_ELECTRONICA_NOTARIAL_VE8aAWsWThCiQBb3QiucJcwk0mm57WUVz8hXDH9RTfxmLEaEs',
    adobeKey: '864d97ba20034be3b47ef62e5b4e8bf8',
    firebaseConfig: {
        apiKey: "AIzaSyATKZb1QWDb8RW3DITKJLeLir3qo_pfFp8",
        authDomain: "ve-notarial-lite.firebaseapp.com",
        projectId: "ve-notarial-lite",
        storageBucket: "ve-notarial-lite.appspot.com",
        messagingSenderId: "1054752334901",
        appId: "1:1054752334901:web:5dd32e724c5f4df8c97d7c",
        measurementId: "G-QWCGQHBH2E"
    }
};
