import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResponseCatalogGet } from 'src/app/models/catalog/catalog.interface';
import { ResponseGetMunicipality } from 'src/app/models/municipality/get_municipality_response.interface';
import { ResponseGetDetails } from 'src/app/models/user/user';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MunicipalityService {

  private url: string = environment.urlApi;


  constructor(
    private http: HttpClient
  ) { }


  get_municipality(req_params: any){
    const scope = `${this.url}catalog/get`;
    return this.http.post<ResponseCatalogGet>(`${scope}`, req_params)
  }

  get_info_admin(req_params: any){
    const scope = `${this.url}catalog/get_municipality`;
    return this.http.post<ResponseGetMunicipality>(`${scope}`, req_params)
  }

  get_user_details(req_params: any){
    const scope = `${this.url}/user/get_details`;
    return this.http.post<ResponseGetDetails>(`${scope}`, req_params)
  }

  get_info_public_server(req_params: any){
    const scope = `${this.url}user/get_details`;
    return this.http.post(`${scope}`, req_params)
  }

  get_cat_department(req_params: any){
    const scope = `${this.url}catalog/get`;
    return this.http.post(`${scope}`, req_params)
  }

  create_department(req_params: any){
    const scope = `${this.url}catalog/create_department`;
    return this.http.post(`${scope}`, req_params)
  }

  update_department(req_params: any){
    const scope = `${this.url}catalog/update_department`;
    return this.http.put(`${scope}`, req_params)
  }
  
  
  add_municipality(req_params: any){
    const scope = `${this.url}catalog/create_municipality`;
    return this.http.post(`${scope}`, req_params)
  }

  add_admin(req_params: any){
    const scope = `${this.url}user/create`;
    return this.http.post(`${scope}`, req_params)
  }

  update_municipality(req_params: any){
    const scope = `${this.url}catalog/update_municipality`;
    return this.http.put(`${scope}`, req_params)
  }

  update_admin(req_params: any){
    const scope = `${this.url}user/update`;
    return this.http.post(`${scope}`, req_params)
  }

  get_cat_departmentAppType(req_params: any){
    const scope = `${this.url}catalog/get`;
    return this.http.post(`${scope}`, req_params)
  }

  get_cat_app(req_params: any){
    const scope = `${this.url}catalog/get`;
    return this.http.post(`${scope}`, req_params)
  }

  add_application(payload: any){
    const scope = `${this.url}catalog/create_department_application_type`;
    return this.http.post(`${scope}`, payload)
  }

  update_application(payload: any){
    const scope = `${this.url}catalog/update_department_application_type`;
    return this.http.put(`${scope}`, payload)
  }

  get_info_application(req_params: any){
    const scope = `${this.url}catalog/get_department_application_type`;
    return this.http.post(`${scope}`, req_params)
  }
}
