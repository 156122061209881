import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { RailMenu } from 'src/app/models/services/menu';
import { CryptoService } from 'src/app/services/api/crypto.service';
import { AuthenticationService } from 'src/app/services/firebase/authentication.service';
import { MenuService } from 'src/app/services/menu.service';

@Component({
  selector: 'app-aside',
  templateUrl: './aside.component.html',
  styleUrls: ['./aside.component.sass']
})
export class AsideComponent implements OnInit{
  @Input() menuobj: RailMenu[] = [];
  @Input() urlprofile: string = '';
  @Input() mode: string = '';
  @Input() open?: boolean;
  @Input() selected?: RailMenu;
  @Output() homeClicked: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() selectChange: EventEmitter<RailMenu> = new EventEmitter<RailMenu>();
  type: any;
  public photoURL: string | null = '';
  constructor(
    private router: Router,
    private authService: AuthenticationService,
    private cService: CryptoService,
    private MenuService: MenuService
  ) { }

  ngOnInit(): void {
    this.photoURL = localStorage.getItem('photoURL') === null ? localStorage.getItem('photoURL') : '../../../../../../assets/app-aside/catUser.png';    
  }



  select(target: RailMenu): void {
    if (this.selected) this.MenuService.open = target.id === this.selected!.id ? !this.MenuService.open : true;
    else this.MenuService.open = true;
    this.selectChange.emit(target);
  }

  openSubmenu() {
    this.homeClicked.emit(!this.open);
  }

  logout() {
    this.authService.signOut();
  }

  profile() {
    const user = JSON.parse(this.cService.decrypt(localStorage.getItem('user')!));    
    switch (user.Cat_rol.id.toString()) {
      case '1':
        this.router.navigateByUrl('/private/administrador/perfil');
        this.type = 'administrador';
        break;
      case '2':
        this.router.navigateByUrl('/private/municipio/perfil');
        this.type = 'municipio';
        break;
      case '3':
        this.router.navigateByUrl('/private/servidor-publico/perfil');
        this.type = 'servidor-publico';
        break;
      default:
        break;
    }
    return false;
  }
}
