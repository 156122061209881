import { Breakpoints } from '@angular/cdk/layout';
import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { MunicipalityService } from 'src/app/services/api/municipality.service';
import { LayoutService } from 'src/app/services/layout.service';
import { LoadingComponent } from '../../../dialogs/loading/loading.component';
import { LetterheadComponent } from '../../../dialogs/letterhead/letterhead.component';

const INPUT_CASES = {
  [Breakpoints.XSmall]: 'smartphone',
  [Breakpoints.Small]: 'tablet',
  [Breakpoints.Medium]: 'desktop',
  [Breakpoints.Large]: 'desktop',
  [Breakpoints.XLarge]: 'desktop',
};

interface Files {
  url: string;
}

@Component({
  selector: 'app-municipality-profile',
  templateUrl: './municipality-profile.component.html',
  styleUrls: ['./municipality-profile.component.sass']
})

export class MunicipalityProfileComponent implements OnInit, OnDestroy {
  form_municipality: FormGroup;
  public options: any = { case: 'desktop' }
  
  id_municipality: any;
  municipality_info: any;
  logo: any;
  public new!: boolean;
  public municipality_id: any;
  private loading!: any;
  
  public logo_file: any;
  public sheet_file: any;

  selectedFile: File | null = null;  

  @ViewChild('messageElement') messageElement: ElementRef | undefined;
  @ViewChild('inputFile') inputFile!: ElementRef;

  
  @Input() municipalityData: any;

  @Output() setUser: EventEmitter<string> = new EventEmitter();

  constructor(
    private formBuilder: FormBuilder,
    private layoutService: LayoutService,
    private municipalityService: MunicipalityService,
    private router: Router,
    private dialog: MatDialog,
    private snack: MatSnackBar,    
    private renderer: Renderer2,
    private el: ElementRef    
  ) {
    this.form_municipality = this.formBuilder.group({
      name: [null, [Validators.required]],
      prefix: [null, [Validators.required]],
      api_key: [null, [Validators.required]],
      status: [true, [Validators.required]],
    });
  }

  ngOnInit(): void {    
    this.setLayout();
    if (this.municipalityData != undefined) {
      this.form_municipality.patchValue(this.municipalityData);
    }else{
      this.form_municipality.patchValue({ api_key: this.generateUUID() });      
      // this.getInfoUser(this.municipalityData.idMun);
    }
  }

  ngOnDestroy(): void {
    this.layoutService.destroyed.next();
    this.layoutService.destroyed.complete();
  }

  setLayout() {
    this.layoutService.breakPoint.subscribe((breakPoint: string) => {
      this.options.case = INPUT_CASES[breakPoint];
    });
  }

  //Logo
  changeInput(evt: Event): void {    
    let input: HTMLInputElement = (evt.target as HTMLInputElement);
    if (input.files && input.files.length > 0) {      
      this.logo_file = input.files[0];
      if (this.logo_file.type.startsWith('image/')) {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          this.logo = (e.target.result as string);
          if(this.municipalityData){
            this.municipalityData.logo = (e.target.result as string);
          }
        };
        reader.readAsDataURL(this.logo_file);
        const imageIcon = this.el.nativeElement.querySelector('#image-icon');
      
        if (imageIcon) {
          this.renderer.setStyle(imageIcon, 'box-shadow', '0 0 8px 0 #71A6C2');
          this.renderer.setStyle(imageIcon, 'border', '1px solid #71A6C2');
        }
      }     
    }
  }

  //Hoja membretada
  fileChange(evt: Event): void {    
    let input: HTMLInputElement = (evt.target as HTMLInputElement);    
    if (input.files && input.files.length > 0) {      
      this.sheet_file = input.files[0];
      // Actualizar el contenido del elemento messageElement con el nombre del archivo
      if (this.messageElement) {
        this.messageElement.nativeElement.innerText = `Archivo seleccionado: ${this.sheet_file.name}`;
      } 
    }
  }  

  chooseFile(form_name: string){
    let input = document.getElementById(form_name) ?? document.createElement('input');
    input.click();
  }

  clickImage() {
    let click = document.getElementById('municipality-profile-image') as HTMLInputElement;
    click.click();
  }  

  generateUUID() {
    var d = new Date().getTime();
    if (window.performance && typeof window.performance.now === "function") {
      d += performance.now();
    }
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
    return uuid;
  }

  save(){
    let formData = new FormData();
    this.loading = this.dialog.open(LoadingComponent, { maxWidth: "600px", disableClose: true });
    if(this.logo_file){ formData.append('logo', this.logo_file); }
    if(this.sheet_file){ formData.append('letterhead', this.sheet_file); }      
    formData.append('data', JSON.stringify(this.form_municipality.value));
    if (!this.municipalityData) {      
      this.municipalityService.add_municipality(formData).subscribe({
        next: (response: any) => {
          this.loading.close();
          this.snack.open('Se ha agregado correctamente el Municipio', 'Cerrar', { duration: 1000 });
          let data = { option: 'admin', idMun: response.data.municipality_id, new: false }
          this.setUser.emit(JSON.stringify(data));
        },        
        error: (err: any) => {
          this.loading.close();
          this.snack.open(err.error.message, 'Cerrar', { duration: 3500 });
        }
      });
    }else{
      formData.append('municipality_id', this.municipalityData.id);
      this.municipalityService.update_municipality(formData).subscribe({
        next: (response: any) => {
          this.loading.close();
          this.snack.open('Se ha actualizado correctamente el Municipio', 'Cerrar', { duration: 1000 });
          this.router.navigateByUrl(`/private/administrador/municipios`);
        },        
        error: (err) => {
          this.loading.close();
          this.snack.open(err.error.message, 'Cerrar', { duration: 3500 });
        }
      });
    }   
  }  
  
}
