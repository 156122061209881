<div class="municipality-admin-container" [ngClass]="options.case">
  <div class="title-container">
      <span class="text-title">Dashboard</span>
      <div class="filters-container">
          <mat-form-field>
              <mat-label>Departamentos</mat-label>
              <mat-select [(ngModel)]="option" name="food" multiple>
                <mat-option *ngFor="let department of departments" [value]="department.id" class="municipality-opcion">
                  {{department.name}}
                </mat-option>
              </mat-select>                
          </mat-form-field>
          <mat-form-field id="calendar">
              <mat-label>Introduce un rango de fechas</mat-label>
              <mat-date-range-input [formGroup]="rangeForm" [rangePicker]="picker"  class="calendar-range">
                <input matStartDate formControlName="start" placeholder="Inicio">
                <input matEndDate formControlName="end" placeholder="Fin">
              </mat-date-range-input>                 
              <mat-datepicker-toggle touchUi matIconSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-date-range-picker #picker></mat-date-range-picker>
          </mat-form-field>
          <button type="button" (click)="getMetrics()" [disabled]="rangeForm.invalid">Buscar</button>
      </div>         
  </div>
  <div class="data-container">      
    <span class="card-title">Datos generales</span>
    <div class="cards-container">    
      <ng-container *ngFor="let card of cardData, let index">
        <app-info-card [cardData]="card"></app-info-card>
      </ng-container>       
    </div>              
  </div>
  <div class="charts-container">
    <div class="chart-container">
      <span class="card-title">Certificados</span> 
      <ng-container *ngIf="chartDataC != undefined && (chartDataC.application_per_type_label.length > 0); else noDataPie">
        <app-pie [chartData]="chartDataC" ></app-pie>
      </ng-container>
      <ng-template #noDataPie>
        <div class="nodata-container">
          <span>No se encontró información</span>
        </div> 
      </ng-template>
    </div> 
    <div class="chart-container">
      <span class="card-title">Certificados por departamento</span>            
      <ng-container *ngIf="chartDataD != undefined && (chartDataD.applications_per_department_label.length > 0); else noDataBar">
        <app-bar [chartData]="chartDataD"></app-bar>
      </ng-container>      
      <ng-template #noDataBar>
        <div class="nodata-container">
          <span>No se encontró información</span>
        </div> 
      </ng-template>
    </div> 
  </div>    
</div>
