import { Breakpoints } from '@angular/cdk/layout';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { LayoutService } from 'src/app/services/layout.service';

const INPUT_CASES = {
  [Breakpoints.XSmall]: 'smartphone',
  [Breakpoints.Small]: 'tablet',
  [Breakpoints.Medium]: 'desktop',
  [Breakpoints.Large]: 'desktop',
  [Breakpoints.XLarge]: 'desktop',
};

export interface PeriodicElement {
  catastro: string;
  notario: number;
  certificado: string;
  status: string;
  creacion: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  {catastro:'HUIX-17082023-95-CVC/42', notario: 95, certificado: 'ARTURO GONZALEZ JIMENEZ', status: 'baja', creacion: '17/08/2023 16:46:39'},
  {catastro:'HUIX-17082023-95-CVC/42', notario: 95, certificado: 'ARTURO GONZALEZ JIMENEZ', status: 'alta', creacion: '17/08/2023 16:46:39'},
  {catastro:'HUIX-17082023-95-CVC/42', notario: 95, certificado: 'ARTURO GONZALEZ JIMENEZ', status: 'media', creacion: '17/08/2023 16:46:39'},
  {catastro:'HUIX-17082023-95-CVC/42', notario: 95, certificado: 'ARTURO GONZALEZ JIMENEZ', status: 'media', creacion: '17/08/2023 16:46:39'},
  {catastro:'HUIX-17082023-95-CVC/42', notario: 95, certificado: 'ARTURO GONZALEZ JIMENEZ', status: 'baja', creacion: '17/08/2023 16:46:39'},
  {catastro:'HUIX-17082023-95-CVC/42', notario: 95, certificado: 'ARTURO GONZALEZ JIMENEZ', status: 'baja', creacion: '17/08/2023 16:46:39'},
  {catastro:'HUIX-17082023-95-CVC/42', notario: 95, certificado: 'ARTURO GONZALEZ JIMENEZ', status: 'alta', creacion: '17/08/2023 16:46:39'},
  {catastro:'HUIX-17082023-95-CVC/42', notario: 95, certificado: 'ARTURO GONZALEZ JIMENEZ', status: 'alta', creacion: '17/08/2023 16:46:39'},
  {catastro:'HUIX-17082023-95-CVC/42', notario: 95, certificado: 'ARTURO GONZALEZ JIMENEZ', status: 'baja', creacion: '17/08/2023 16:46:39'},
  {catastro: 'HUIX-17082023-95-CVC/42', notario: 95, certificado: 'ARTURO GONZALEZ JIMENEZ', status: 'media', creacion: '17/08/2023 16:46:39'},
  {catastro:'HUIX-17082023-95-CVC/42', notario: 95, certificado: 'ARTURO GONZALEZ JIMENEZ', status: 'baja', creacion: '17/08/2023 16:46:39'},
  {catastro:'HUIX-17082023-95-CVC/42', notario: 95, certificado: 'ARTURO GONZALEZ JIMENEZ', status: 'baja', creacion: '17/08/2023 16:46:39'},
  {catastro:'HUIX-17082023-95-CVC/42', notario: 95, certificado: 'ARTURO GONZALEZ JIMENEZ', status: 'alta', creacion: '17/08/2023 16:46:39'},
  {catastro:'HUIX-17082023-95-CVC/42', notario: 95, certificado: 'ARTURO GONZALEZ JIMENEZ', status: 'alta', creacion: '17/08/2023 16:46:39'},
  {catastro:'HUIX-17082023-95-CVC/42', notario: 95, certificado: 'ARTURO GONZALEZ JIMENEZ', status: 'baja', creacion: '17/08/2023 16:46:39'},
  {catastro: 'HUIX-17082023-95-CVC/42', notario: 95, certificado: 'ARTURO GONZALEZ JIMENEZ', status: 'media', creacion: '17/08/2023 16:46:39'},
  {catastro:'HUIX-17082023-95-CVC/42', notario: 95, certificado: 'ARTURO GONZALEZ JIMENEZ', status: 'baja', creacion: '17/08/2023 16:46:39'},
  {catastro:'HUIX-17082023-95-CVC/42', notario: 95, certificado: 'ARTURO GONZALEZ JIMENEZ', status: 'baja', creacion: '17/08/2023 16:46:39'},
  {catastro:'HUIX-17082023-95-CVC/42', notario: 95, certificado: 'ARTURO GONZALEZ JIMENEZ', status: 'alta', creacion: '17/08/2023 16:46:39'},
  {catastro:'HUIX-17082023-95-CVC/42', notario: 95, certificado: 'ARTURO GONZALEZ JIMENEZ', status: 'alta', creacion: '17/08/2023 16:46:39'},
  {catastro:'HUIX-17082023-95-CVC/42', notario: 95, certificado: 'ARTURO GONZALEZ JIMENEZ', status: 'baja', creacion: '17/08/2023 16:46:39'},
  {catastro: 'HUIX-17082023-95-CVC/42', notario: 95, certificado: 'ARTURO GONZALEZ JIMENEZ', status: 'media', creacion: '17/08/2023 16:46:39'},
];

@Component({
  selector: 'app-historical-records-table',
  templateUrl: './historical-records-table.component.html',
  styleUrls: ['./historical-records-table.component.sass']
})
export class HistoricalRecordsTableComponent implements OnInit, AfterViewInit, OnDestroy{
  public options: any = { case: 'desktop' };
  displayedColumns: string[] = ['catastro', 'notario', 'certificado', 'status', 'creacion', 'acciones'];
  dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);
  selectedButtonIndex: number | null = null;

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  constructor(
    private layoutService: LayoutService,
  ) { }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  filterButtons = [
    { label: 'Todo', total: 35, status: '' },
    { label: 'Prioridad alta', total: 35, status: 'red'},
    { label: 'Prioridad media', total: 35, status: 'yellow' },
    { label: 'Prioridad baja', total: 35, status: 'green' }
  ];

  ngOnInit(): void {
    this.setLayout();
  }

  setLayout(){
    this.layoutService.breakPoint.subscribe((breakPoint: string) => {
      this.options.case = INPUT_CASES[breakPoint];          
    });
  } 
  
  ngOnDestroy(): void {
    this.layoutService.destroyed.next();
    this.layoutService.destroyed.complete();
  }

  selectButton(index: number) {
    this.selectedButtonIndex = index;
  }
}
