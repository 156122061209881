<mat-card [ngClass]="options.case">
  <mat-card-header>
    <mat-card-title-group>
      <div class="user-description">
        <mat-card-title>Tipo de Solicitud</mat-card-title>
      </div>
    </mat-card-title-group>
  </mat-card-header>

  <mat-card-content>
    <form [formGroup]="form_request">
      <mat-divider></mat-divider>
      <div class="half-column">
        <div class="text-label">Departamento</div>
        <div>
          <mat-form-field appearance="outline">
            <mat-label></mat-label>
            <mat-select formControlName="cat_department_id">
              <mat-option *ngFor="let department of cat_dep" [value]="department.id">{{department.name}}</mat-option>
            </mat-select>
            <mat-error *ngIf="form_request.get('cat_department_id')?.hasError('required')">
              El departamento es obligatorio
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="half-column">
        <div class="text-label">Tipo de solicitud</div>
        <div>
          <mat-form-field appearance="outline">
            <mat-label></mat-label>            
            <mat-select formControlName="cat_application_type_id" 
              (ngModelChange)="toggleSeccion()">
              <mat-option *ngFor="let app of cat_app" [value]="app.id">{{app.name}}</mat-option>
            </mat-select>
           
            <mat-error *ngIf="form_request.get('cat_application_type_id')?.hasError('required')">
              El tipo de solicitud es obligatorio
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="half-column">
        <div class="text-label">Nombre firma</div>
        <div>
          <mat-form-field appearance="outline">
            <input matInput #input formControlName="name" type="text">
            <mat-error *ngIf="form_request.get('name')?.hasError('required')">
              El nombre es obligatorio
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="half-column">
        <div class="text-label">CUTS firma</div>
        <div>
          <mat-form-field appearance="outline">
            <input matInput #input formControlName="cuts" type="text">
            <mat-error *ngIf="form_request.get('cuts')?.hasError('required')">
              CUTS obligatorio
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="half-column">
        <div class="text-label">Departamento firma</div>
        <div>
          <mat-form-field appearance="outline">
            <input matInput #input formControlName="department" type="text">
            <mat-error *ngIf="form_request.get('department')?.hasError('required')">
              Departamento oblgatorio
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </form>
    <form [formGroup]="form_request_cvc">
      <mat-divider *ngIf="mostrarSeccion"></mat-divider>
      <div class="half-column" *ngIf="mostrarSeccion">
        <div class="text-label">Nombre firma CVC</div>
        <div>
          <mat-form-field appearance="outline">
            <input matInput #input formControlName="name_cvc" type="text">
            <mat-error *ngIf="form_request_cvc.get('name_cvc')?.hasError('required')">
              Nombre CVC es obligatorio
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="half-column" *ngIf="mostrarSeccion">
        <div class="text-label">CUTS firma CVC</div>
        <div>
          <mat-form-field appearance="outline">
            <input matInput #input formControlName="cuts_cvc" type="text">
            <mat-error *ngIf="form_request_cvc.get('cuts_cvc')?.hasError('required')">
              CUTS CVC es obligatorio
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="half-column" *ngIf="mostrarSeccion">
        <div class="text-label">Departamento firma CVC</div>
        <div>
          <mat-form-field appearance="outline">
            <input matInput #input formControlName="department_cvc" type="text">
            <mat-error *ngIf="form_request_cvc.get('department_cvc')?.hasError('required')">
              Departamento CVC es obligatorio
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </form>
    <form [formGroup]="form_request_cipcam">
      <mat-divider *ngIf="mostrarSeccion"></mat-divider>
      <div class="half-column" *ngIf="mostrarSeccion">
        <div class="text-label">Nombre firma CIP - CAM</div>
        <div>
          <mat-form-field appearance="outline">
            <input matInput #input formControlName="name_cipcam" type="text">
            <mat-error *ngIf="form_request_cipcam.get('name_cipcam')?.hasError('required')">
              Nombre CIP - CAM es obligatorio
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="half-column" *ngIf="mostrarSeccion">
        <div class="text-label">CUTS firma CIP - CAM</div>
        <div>
          <mat-form-field appearance="outline">
            <input matInput #input formControlName="cuts_cipcam" type="text">
            <mat-error *ngIf="form_request_cipcam.get('cuts_cipcam')?.hasError('required')">
              CUTS CIP - CAM es obligatorio
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="half-column" *ngIf="mostrarSeccion">
        <div class="text-label">Departamento firma CIP - CAM</div>
        <div>
          <mat-form-field appearance="outline">
            <input matInput #input formControlName="department_cipcam" type="text">
            <mat-error *ngIf="form_request_cipcam.get('department_cipcam')?.hasError('required')">
              Departamento CIP - CAM es obligatorio
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </form>
    

    <div class="button-content" *ngIf="this.new == false && mostrarSeccion">
      <button type="button" (click)="updateApp()"
        [disabled]="!form_request || !form_request_cvc || !form_request_cipcam || form_request.invalid || form_request_cvc.invalid || form_request_cipcam.invalid">Guardar
        Cambios</button>
    </div>
    <div class="button-content" *ngIf="this.new == false && !mostrarSeccion">
      <button type="button" (click)="updateApp()" [disabled]="form_request.invalid">Guardar Cambios</button>
    </div>
    <div class="button-content" *ngIf="this.new == true && mostrarSeccion">
      <button type="button" (click)="saveApp()"
        [disabled]="!form_request || !form_request_cvc || !form_request_cipcam || form_request.invalid || form_request_cvc.invalid || form_request_cipcam.invalid">Guardar</button>
    </div>
    <div class="button-content" *ngIf="this.new == true && !mostrarSeccion">
      <button type="button" (click)="saveApp()" [disabled]="form_request.invalid">Guardar</button>
    </div>
    
  </mat-card-content>

</mat-card>