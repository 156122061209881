import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private url: string = environment.urlApi;
  constructor(
    private http: HttpClient,
    private router: Router
  ) { }

  login(){
    const scope = `${this.url}authentication/login`;
    return this.http.get(`${scope}`)
  }
}
