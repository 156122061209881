import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FilesDatum } from 'src/app/models/application/details';
import { MenuService } from 'src/app/services/menu.service';
import { ProcedureService } from 'src/app/services/api/procedure.service';
import { MatDialog } from '@angular/material/dialog';
import { LoadingComponent } from '../../../dialogs/loading/loading.component';
import { ChangeStatus } from 'src/app/shared/statics/constants';
import { DialogService } from 'src/app/services/dialog.service';
import { LayoutService } from 'src/app/services/layout.service';
import { Breakpoints } from '@angular/cdk/layout';

const INPUT_CASES = {
  [Breakpoints.XSmall]: 'smartphone',
  [Breakpoints.Small]: 'tablet',
  [Breakpoints.Medium]: 'desktop',
  [Breakpoints.Large]: 'desktop',
  [Breakpoints.XLarge]: 'desktop',
};

@Component({
  selector: 'app-file-list',
  templateUrl: './file-list.component.html',
  styleUrls: ['./file-list.component.sass']
})
export class FileListComponent implements OnInit, OnDestroy {

  @Input() files: FilesDatum[] = [];
  private loading: any;
  public options: any = { case: 'desktop' }

  constructor(
    public menuService: MenuService,
    private procedureService: ProcedureService,
    private dialog: MatDialog,
    private dialogService: DialogService,
    private layoutService: LayoutService,
  ) { }

  ngOnInit(): void {
     this.setLayout();
  }

  ngOnDestroy(): void {
    this.layoutService.destroyed.next();
    this.layoutService.destroyed.complete();
  }

  setLayout() {
    this.layoutService.breakPoint.subscribe((breakPoint: string) => {
      this.options.case = INPUT_CASES[breakPoint];
    });
  }
  selectFile(file: FilesDatum) {
    this.menuService.selectedFile = file;
  }

  saveAnnotations() {
    this.loading = this.dialog.open(LoadingComponent, { maxWidth: "600px", disableClose: true });
    this.procedureService.save_annotations().subscribe({
      next: (res) => {
        if (this.menuService.selectedFile) {
          let file = this.getFile(this.menuService.selectedFile.uid);
          file!.annotations = this.menuService.annotations;
        }
        this.loading.close();
      },
      error: (err) => { this.loading.close(); },
    });
  }

  getFile(uid: string): FilesDatum | undefined {
    return this.files.find((f: FilesDatum) => f.uid === uid);
  }

  isAllFilesValidated() {
    return this.files.every((file: FilesDatum) => file.validated !== null);
  }

  isAnyFilesError() {
    return this.files.some((file: FilesDatum) => file.validated !== true);
  }

  isAllFilesSuccess() {
    return this.files.every((file: FilesDatum) => file.validated === true);
  }

  isInReview() {
    return this.menuService.selectedApplicationElement ? this.menuService.selectedApplicationElement.Cat_application_status.id === 1 : false;
  }

  validate(flag: boolean) {
    this.loading = this.dialog.open(LoadingComponent, { maxWidth: "600px", disableClose: true });
    this.procedureService.validate_file(flag).subscribe({
      next: (res) => {
        if (this.menuService.selectedFile) {
          let file = this.getFile(this.menuService.selectedFile.uid);
          file!.validated = flag;
        }
        this.loading.close();
      },
      error: (err) => { this.loading.close(); },
    });
  }

  fix() {
    this.dialogService.openConfirmationDialog((dialogRef) => {
      dialogRef.close();
      this.dialogService.openDocumentDialog([16]).afterClosed().subscribe((res: boolean) => {
        if (res) this.changeStatus(ChangeStatus.REJECTED);
      });
    });
  }

  cancel() {
    this.dialogService.openConfirmationDialog((dialogRef) => {
      dialogRef.close();
      this.dialogService.openDocumentDialog([16]).afterClosed().subscribe((res: boolean) => {
        if (res) this.changeStatus(ChangeStatus.CANCELED);
      });
    });
  }

  approve() {
    this.dialogService.openConfirmationDialog((dialogRef) => {
      dialogRef.close();
      this.changeStatus(ChangeStatus.APPROVED);
    });
  }

  changeStatus(status: ChangeStatus) {
    this.loading = this.dialog.open(LoadingComponent, { maxWidth: "600px", disableClose: true });
    this.procedureService.change_status(status).subscribe({
      next: (res) => {
        this.loading.close();
        window.history.back();
      },
      error: (err) => {
        this.loading.close();
        this.dialogService.error();
      },
    });
  }
}
